/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Typography,
  CardActionArea,
  CardContent,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useObserver } from "mobx-react-lite";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";

import moment from "moment";
import { AppInsightMethods } from "../../AppInsightMethods";

import { apiService } from "../../services/ApiService";
import { ICardData, IAppointmentList } from "../../state/GlobalStateType";
import { ConstantVariables } from "../../Constants";
import { authApiService } from "../../services/AuthenticationApiService";

import SummaryTable from "./SummaryTable";
import DateTimeFilter from "./DateTimeFilter";
import configData from "../../config.json";
import { useGlobalStore } from "../../state/GlobalStore";

const useStyle = makeStyles(() => ({
  backdrop: {
    color: "#fff",
    zIndex: 999,
  },
}));

export default function Summary() {
  const pageName = "Summary";
  const appInsights = useAppInsightsContext();

  const trackComponent = useTrackMetric(appInsights, pageName);
  const globalStore = useGlobalStore();

  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
    endDate: new Date(),
  });
  const backdropClass = useStyle();

  const [cardData, setCardData] = useState<ICardData[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [loginToken, setLoginToken] = useState("");
  const [connection, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    if (loginToken) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${configData.API_URL}/meetingHub`, {
          accessTokenFactory: () => loginToken,
          withCredentials: false,
        })
        .configureLogging("info")
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
    }
  }, [loginToken]);

  useEffect(() => {
    trackComponent();

    authApiService
      .getAccessToken()
      .then((token: any) => {
        setLoginToken(token);
      })
      .catch((error: any) => {
        AppInsightMethods.TrackAppInsightsException(
          `Error at getAccessToken API call, useEffect, Component: ${pageName}, Error: ${error}`
        );
      });
  }, []);

  /* Method for adding zero in front of single digit day and month in a date */
  const formatDateSummary = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    async function startConnection() {
      try {
        if (connection && connection.state !== HubConnectionState.Connected) {
          await connection.start();
          connection.on("broadcastMessage", (name: string, message: string) => {
            const messageObj: IAppointmentList = JSON.parse(message);
            broadcastMessageHandler(messageObj);
          });
        }
      } catch (error) {
        AppInsightMethods.TrackAppInsightsException(
          `Error at startConnection, Component: ${pageName}, Error: ${error}`
        );
        setTimeout(() => startConnection(), 5000);
      }
    }
    /* SignalR handler */
    const broadcastMessageHandler = (messageObj: IAppointmentList) => {
      try {
        const startDateStr = formatDateSummary(new Date());
        const scheduleDateStr = formatDateSummary(
          new Date(messageObj.scheduleDateTimeTZD)
        );
        if (messageObj.isMeetingDeleted) {
          globalStore.deleteAppointmentList(messageObj.televisitId);
        } else if (
          startDateStr === scheduleDateStr &&
          globalStore.globalAppointmentsList.some(
            (searchElement: IAppointmentList) =>
              searchElement.televisitId === messageObj.televisitId
          ) &&
          globalStore.appointmentsList.some(
            (value: IAppointmentList) =>
              value.televisitId === messageObj.televisitId
          )
        ) {
          globalStore.updateAppointmentList(messageObj.televisitId, messageObj);
        } else if (startDateStr === scheduleDateStr) {
          globalStore.insertAppointmentList(messageObj);
        } else if (
          globalStore.appointmentsList.some(
            (value: IAppointmentList) =>
              value.televisitId === messageObj.televisitId
          )
        ) {
          globalStore.deleteAppointmentList(messageObj.televisitId);
        }
      } catch (error) {
        AppInsightMethods.TrackAppInsightsException(
          `Error at broadcastMessageHandler, Component: ${pageName}, Error: ${error}`
        );
      }
    };

    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on("broadcastMessage", (name: string, message: string) => {
            const messageObj: IAppointmentList = JSON.parse(message);
            broadcastMessageHandler(messageObj);
          });
        })
        .catch((error) => {
          AppInsightMethods.TrackAppInsightsException(
            `Error at connecting to Azure SignalR, Component: ${pageName}, Error: ${error}`
          );
          setTimeout(() => startConnection(), 5000);
        });

      connection.onclose((error) => {
        startConnection();
        connection.off("broadcastMessage");
        AppInsightMethods.TrackAppInsightsException(
          `Error at SignalR Connection, Component: ${pageName}, Error: ${error}`
        );
      });
    }
  }, [connection]);

  const getStartDate = (date: Date) => {
    return moment(new Date(date.setHours(0, 0, 0, 0))).format();
  };

  const getEndDate = (date: Date) => {
    return moment(new Date(date.setHours(23, 59, 59, 0))).format();
  };

  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    setShowSpinner(true);
    apiService
      .getAppointments(
        getStartDate(selectedDate.startDate),
        getEndDate(selectedDate.endDate),
        configData.PROVIDER_GROUP_ID,
        globalStore.loginUserAadId
      )
      .then((res) => {
        if (res.length !== 0) {
          const myResponse = res;
          globalStore.setAppointmentsList(myResponse);
          globalStore.setGlobalAppointmentsList(myResponse);
          globalStore.setOpenRecord(true);

          const statusArr: any = [];
          statusArr.push({ count: res.length, status: "All" });
          const status = [
            ConstantVariables.Status.scheduled,
            ConstantVariables.Status.waiting,
            ConstantVariables.Status.delayed,
            ConstantVariables.Status.inProgress,
            ConstantVariables.Status.completed,
          ];
          status.forEach((element) => {
            statusArr.push({
              count: res.filter((val) => val.status === element).length,
              status: element,
            });
          });
          globalStore.setCardData(statusArr);
          setShowSpinner(false);
        } else if (res.length === 0) {
          globalStore.setAppointmentsList([]);
          globalStore.setGlobalAppointmentsList([]);
          globalStore.setOpenRecord(false);
          globalStore.setCardData([]);
          setShowSpinner(false);
        }
      })
      .catch((error: any) => {
        setShowSpinner(false);
      });
  }, []);

  const cardOnclick = (item) => {
    globalStore.setCurrentCard(item.status);
  };

  return useObserver(() => {
    return (
      <>
        <Grid className="summary">
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className="totalApt">Total Appointment</Typography>
            <DateTimeFilter spinnerFunc={(val) => setShowSpinner(val)} />
          </Grid>
          {!showSpinner && globalStore.cardData.length !== 0 && (
            <>
              <Grid className="cardBase">
                {globalStore.cardData?.map((item) => (
                  <Card
                    className={
                      item.status === globalStore.currentCard
                        ? "cards"
                        : "cardDeselected"
                    }
                    key={item.status}
                  >
                    <CardActionArea
                      onClick={() => cardOnclick(item)}
                      className="cardActionArea"
                      sx={{ width: 219, height: 126 }}
                    >
                      <CardContent className="cardContent">
                        <Typography className="statusCount">
                          {item.count}
                        </Typography>
                        <Typography className="statusType">
                          {item.status}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Grid>
            </>
          )}
        </Grid>
        {!showSpinner && <SummaryTable />}
        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}
      </>
    );
  });
}
