/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import DashboardTabs from "../Dashboard/DashboardTabs";
import { AppInsightsConstants } from "../../Constants";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/GlobalStore";
import TestAppointmentList from "./TestAppointmentList";
import TestUserList from "../TestUser/TestUserList";

export const NoResult = () => {
  return (
    <Grid className={"noResultStyle"}>
      <InfoOutlinedIcon />
      <span>No Result</span>
    </Grid>
  );
};

export default function TestAccounts() {
  const pageName = "Test Accounts";
  const appInsights = useAppInsightsContext();
  const [value, setValue] = useState("Test Users");
  const globalStore = useGlobalStore();
  const [searchText, setSearchText] = useState("");
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    return (
      <Grid>
        <DashboardTabs
          parentTabName={pageName}
          selectedSubTab={value}
          handleTabChange={(e: any, newValue: string) => {
            setValue(newValue);
            AppInsightMethods.trackAppInsightsEvent(
              pageName,
              AppInsightsConstants.Information,
              `The "${newValue}" tab has been clicked on the ${pageName} section.`,
              globalStore.loginUserEmail,
              window.location.href,
              ""
            );
          }}
          searchText={searchText}
          setSearchText={(val: string) => setSearchText(val)}
        />
        {value === "Test Users" && <TestUserList />}
        {value === "Test Appointments" && <TestAppointmentList />}
      </Grid>
    );
  });
}
