/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Alert,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useObserver } from "mobx-react-lite";
import { apiService } from "../../services/ApiService";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  IAudioVideoSettings,
  IUpdateAudioVideoSettings,
} from "../../state/StoreTypes";
import { AntSwitch } from "../Materials/MaterialList";
import { LightTooltip } from "../Relationship/RelationshipList";
import { sessionStorage } from "../../state/SessionStorage";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
  root: {
    borderBottom: "none",
  },
});

export default function AudioVideoSettings() {
  const pageName = "AudioVideoSettings";
  const alignLeftText = "left";
  const globalStore = useGlobalStore();
  const classes = useStyles();
  const [isFetch, setIsFetch] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);
  const [audioVideoSettings, setAudioVideoSettings] = useState<
    IAudioVideoSettings[]
  >([]);

  const getAudioVideoSettings = () => {
    try {
      setIsFetch(true);
      apiService
        .getAudioVideoSettings()
        .then((value: IAudioVideoSettings[]) => {
          setIsFetch(false);
          if (value.length > 0) {
            setAudioVideoSettings(value);
            sessionStorage.setWithExpiry("audioVideoSettings", value, 600);
          }
        })
        .catch((error: any) => {
          setIsFetch(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get All SystemSettings API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getAllSystemSettings, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    const pageLoadApiCall = async () => {
      const audioVideoSet = await sessionStorage.getWithExpiry(
        "audioVideoSettings"
      );
      if (audioVideoSet) {
        setAudioVideoSettings(audioVideoSet);
      } else {
        getAudioVideoSettings();
      }
    };
    pageLoadApiCall();
  }, []);

  const isSettingChangeEvent = (settings: IAudioVideoSettings) => {
    try {
      const toggleValue: IUpdateAudioVideoSettings = {
        id: settings.id,
        settingName: settings.settingName,
        settingOption: !settings.settingOption,
      };
      setShowSpinner(true);
      const audioVideoSettingsNew: IAudioVideoSettings[] = JSON.parse(
        JSON.stringify(audioVideoSettings)
      );
      audioVideoSettingsNew.filter(
        (value: IAudioVideoSettings) => value.id === settings.id
      )[0].settingOption = !settings.settingOption;
      setAudioVideoSettings(audioVideoSettingsNew);
      sessionStorage.setWithExpiry(
        "audioVideoSettings",
        audioVideoSettingsNew,
        600
      );
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `The ${settings.settingName}  has been ${
          !settings.settingOption ? "Activated" : "Deactivated"
        }.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      apiService
        .updateAudioVideoSettings(toggleValue)
        .then((res: any) => {
          if (res === "success") {
            setShowSpinner(false);
          }
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at Edit Material API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at isSettingChangeEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  return useObserver(() => {
    return (
      <Grid className={"audioVideoSettings"}>
        <Alert severity="info" className={"infoAlert"}>
          {`Currently the "Audio/ Video settings" are not configurable`}
        </Alert>
        {isFetch && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}

        {!isFetch && audioVideoSettings && audioVideoSettings.length > 0 && (
          <TableContainer className={"audioVideoSettings_table_container"}>
            <Table
              className={`${classes.table} audioVideoSettings_table`}
              aria-label="simple table"
            >
              <TableHead className={"audioVideoSettingsHeader"}>
                <TableRow>
                  <TableCell
                    align={alignLeftText}
                    style={{ minWidth: 200 }}
                    className={`headerCell`}
                  >
                    {"Setting Type"}
                  </TableCell>
                  <TableCell align={alignLeftText} className={`headerCell`}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} className={`headerCell`}>
                    {""}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={"audioVideoSettingsBody"}>
                {audioVideoSettings.map((row: IAudioVideoSettings) => (
                  <TableRow
                    key={`${row.id}`}
                    id={row.id}
                    className={classes.root}
                  >
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell"
                      title={row.settingName}
                    >
                      {row.settingName}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell"
                      style={{ minWidth: 150 }}
                    >
                      <LightTooltip
                        title={ConstantVariables.RoleBasedAccessErrorMessage}
                        disableHoverListener={
                          globalStore.actionMenuSelectedUserRole ===
                          globalStore.fullControlGroup
                        }
                      >
                        <span>
                          <AntSwitch
                            disabled={
                              globalStore.actionMenuSelectedUserRole !==
                              globalStore.fullControlGroup
                            }
                            checked={row.settingOption}
                            name="checked"
                            onChange={() => isSettingChangeEvent(row)}
                          />
                        </span>
                      </LightTooltip>
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell"
                      style={{ minWidth: 250 }}
                    >
                      {""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Grid>
    );
  });
}
