/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useObserver } from "mobx-react-lite";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import OltivaLogo from "../../assets/oltiva_logo.svg";
import ProfileIcon from "../../assets/profileIcon.svg";
import Settings from "../../assets/Setting.png";
import Logout from "../../assets/logout.svg";
import Profile from "../../assets/profile_pic.png";
import { Constants } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";
import ErrorPopup from "./ErrorPopup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      paddingTop: 8,
      paddingBottom: 8,
      // width: "138px",
      // textAlign: "center",
    },
    // popover: {
    //   width: 138,
    //   marginLeft: 20,
    // },
  })
);

export default function Header() {
  const globalStore = useGlobalStore();
  const pageName = "Configuration App Header";
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    globalStore.setCustomScreenFlag(true);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return useObserver(() => {
    return (
      <Grid className={"dashboard_header"}>
        <Grid className={"dashboard_header_left"}>
          <Grid className={"dashboard_header_left_logo"}>
            <img src={OltivaLogo} alt={"Oltiva Logo"} />
          </Grid>
          <Grid className={"dashboard_header_left_title"}>
            {Constants.oltivaHealthCareText}
          </Grid>
        </Grid>
        <Grid className={"dashboard_header_right"}>
          {!globalStore.customScreenFlag && <ErrorPopup />}
          <Grid className={"dashboard_header_right_profile"}>
            <img src={Profile} alt={"Oltiva Logo"} />
            <span>Admin</span>
            <IconButton aria-label="arrowDown" onClick={handleClick}>
              <KeyboardArrowDownOutlinedIcon />
            </IconButton>
          </Grid>
          <Popover
            className="popOver"
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography
              style={{ color: "#ADADAD" }}
              className={classes.typography}
            >
              <img className="popoverImage" src={ProfileIcon} alt="Profile" />
              Profile
            </Typography>
            <Typography
              style={{ cursor: "pointer", color: "#498308" }}
              className={classes.typography}
              onClick={handleSettingsClick}
            >
              <img className="popoverImage" src={Settings} alt="Settings" />
              Settings
            </Typography>
            <Typography
              style={{ color: "#ADADAD" }}
              className={classes.typography}
            >
              <img className="popoverImage" src={Logout} alt="Logout" />
              Logout
            </Typography>
          </Popover>
        </Grid>
      </Grid>
    );
  });
}
