/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import {
  Divider,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGlobalStore } from "../../state/GlobalStore";

import { AppInsightMethods } from "../../AppInsightMethods";

const fontFamily = [
  {
    fontFamilyName: "Tahoma",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Geneva",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Sans-serif",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Roboto",
    fontFamilyType: [""],
  },
];

export default function Dropdown() {
  const globalStore = useGlobalStore();
  const pageName = "FontFamilyDropdown";
  const refClassText = "fontFamilyRefClass";

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<any>();

  /* On Change handler when font family dropdown is clicked */
  const onTabChangeHandler = (
    event: unknown,
    valueFontFamily: string,
    valueFontType: string
  ) => {
    try {
      globalStore.setFontFamilyTab(valueFontFamily, valueFontType);

      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        "Information",
        `Font family Change event, selected Font family is ${valueFontFamily}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onTabChangeHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Grid className={"fontFamilyDropdown"} onClick={() => setIsOpen(!isOpen)}>
        {" "}
        <Grid className={"fontFamilyHeading"}>
          {globalStore.fontFamilyTab.fontFamilyName}{" "}
          {globalStore.fontFamilyTab.fontFamilyType}
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Grid>
        {isOpen && (
          <Grid className={"fontFamilyMenuitems"} ref={wrapperRef}>
            {fontFamily.map((item) => (
              <>
                {item.fontFamilyType.map((subItem) => (
                  <Grid
                    className={`fontFamilyitem ${refClassText} ${
                      globalStore.fontFamilyTab.fontFamilyName +
                        globalStore.fontFamilyTab.fontFamilyType ===
                      item.fontFamilyName + subItem
                        ? "selected"
                        : ""
                    }`}
                    onClick={(e) =>
                      onTabChangeHandler(e, item.fontFamilyName, subItem)
                    }
                  >
                    {item.fontFamilyName}
                    <span className="fontFamilySubitem">{subItem}</span>
                  </Grid>
                ))}
              </>
            ))}
          </Grid>
        )}
      </Grid>
    );
  });
}
