/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useObserver } from "mobx-react-lite";
import { ILobbyGetMessage } from "../../state/GlobalStateType";
import { apiService } from "../../services/ApiService";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import { useSortableData } from "../Dashboard/useSortableData";
import { ReactComponent as DownArrowDisabled } from "../../assets/down-arrow-disabled-icon.svg";
import { ReactComponent as DownArrow } from "../../assets/down-arrow-icon.svg";
import { ReactComponent as UpArrow } from "../../assets/up-arrow-icon.svg";
import Pagination from "../Pagination/Pagination";
import AddLobbyMessage from "./AddLobbyMessage";
import EditLobbyMessage from "./EditLobbyMessage";
import { NoResult } from "../Resources/Resources";
import DeleteLobbyMessage from "./DeleteLobbyMessage";
import { LightTooltip } from "../Relationship/RelationshipList";
import { sessionStorage } from "../../state/SessionStorage";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

interface IMessageListProps {
  searchText: string;
}

export default function LobbyMessagelList(props: IMessageListProps) {
  const pageName = "Message List";
  const appInsights = useAppInsightsContext();
  const classes = useStyles();
  const alignLeftText = "left";
  const itemsPerPageCount = 7;
  const [messageList, setMessageList] = useState<ILobbyGetMessage[]>([]);
  const [globalMessageList, setGlobalMessageList] = useState<
    ILobbyGetMessage[]
  >([]);

  const globalStore = useGlobalStore();
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPageCount);
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);
  const [popupT, setPopup] = useState(false);
  const [popupDel, setPopupDel] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const { items, requestSort, sortConfig } = useSortableData(messageList, {
    key: "messageType",
    direction: "ascending",
  });

  const getConfigurationMsgList = () => {
    try {
      setShowSpinner(true);
      apiService
        .getLobbyMessage()
        .then((data: ILobbyGetMessage[]) => {
          setShowSpinner(false);
          setMessageList(data);
          setGlobalMessageList(data);
          sessionStorage.setWithExpiry("lobbyMessages", data, 600);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get configuration msg API call, getConfigurationMsgList, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getConfigurationMsgList, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const closeCallbackHandler = () => {
    try {
      setPopup(false);
      setPopupDel(false);
      setPopupEdit(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    trackComponent();
    const pageLoadApiCall = async () => {
      const lobbyMessages = await sessionStorage.getWithExpiry("lobbyMessages");
      if (lobbyMessages) {
        setMessageList(lobbyMessages);
        setGlobalMessageList(lobbyMessages);
      } else {
        getConfigurationMsgList();
      }
    };
    pageLoadApiCall();
  }, []);

  useEffect(() => {
    if (props.searchText) {
      const newItem: ILobbyGetMessage[] = [...globalMessageList];
      const newArr = newItem.filter(
        (value: ILobbyGetMessage) =>
          value.messageType
            .toLowerCase()
            .includes(props.searchText.toLowerCase()) ||
          value.message.toLowerCase().includes(props.searchText.toLowerCase())
      );
      setMessageList(newArr);
    } else {
      setMessageList(globalMessageList);
    }
  }, [props.searchText]);

  const getClassNamesFor = (name: string) => {
    try {
      if (!sortConfig) {
        return <DownArrowDisabled />;
      }
      if (sortConfig.key === name && sortConfig.direction === "ascending") {
        return <UpArrow />;
      }
      if (sortConfig.key === name && sortConfig.direction === "descending") {
        return <DownArrow />;
      }
      return <DownArrowDisabled />;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getClassNamesFor, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  return useObserver(() => {
    return (
      <Grid className="msg-container">
        <Grid className="message">
          <Grid className={"message-left"}>{`Total Lobby Messages ${
            messageList.length > 0 ? `(${messageList.length})` : ""
          }`}</Grid>
          <LightTooltip
            title={ConstantVariables.RoleBasedAccessErrorMessage}
            disableHoverListener={
              globalStore.actionMenuSelectedUserRole ===
              globalStore.fullControlGroup
            }
          >
            <span>
              <Button
                variant="contained"
                disabled={
                  globalStore.actionMenuSelectedUserRole !==
                  globalStore.fullControlGroup
                }
                style={{
                  opacity:
                    globalStore.actionMenuSelectedUserRole !==
                    globalStore.fullControlGroup
                      ? 0.5
                      : 1,
                }}
                className={"newMaterialBtn"}
                startIcon={<AddIcon />}
                onClick={() => {
                  setPopup(true);
                  globalStore.setOpenedModalMsg("create");
                  AppInsightMethods.trackAppInsightsEvent(
                    pageName,
                    AppInsightsConstants.Information,
                    `The "Lobby Message" button has been clicked.`,
                    globalStore.loginUserEmail,
                    window.location.href,
                    ""
                  );
                }}
              >
                Lobby Message
              </Button>
            </span>
          </LightTooltip>
        </Grid>
        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}

        {!showSpinner && items && items.length > 0 && (
          <TableContainer className={"messageList_table_container"}>
            <Table
              className={`${classes.table} messageList_table`}
              aria-label="simple table"
            >
              <TableHead className={"messageListHeader"}>
                <TableRow>
                  <TableCell
                    align={alignLeftText}
                    style={{ width: 316 }}
                    className={`headerCell `}
                    tabIndex={0}
                    onClick={() => requestSort("messageType")}
                  >
                    {"Messages Type"}
                    {getClassNamesFor("messageType")}
                  </TableCell>
                  <TableCell
                    align={alignLeftText}
                    className={"headerCell"}
                    onClick={() => requestSort("message")}
                  >
                    {"Message Description"}
                    {getClassNamesFor("message")}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={"messageListBody"}>
                {items
                  ?.slice(startIndex, endIndex)
                  .map((row: ILobbyGetMessage) => (
                    <TableRow key={`${row.id}`} id={row.id}>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell titleCell overflowHidden"
                        title={row.messageType}
                      >
                        <span className="bold">{row.messageType}</span>
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell overflowHidden messageDescription"
                        title={row.message}
                        style={{ minWidth: 663 }}
                      >
                        {row.message}
                      </TableCell>
                      <TableCell align={alignLeftText} tabIndex={0}>
                        {""}
                      </TableCell>
                      <TableCell align={alignLeftText} tabIndex={0}>
                        {""}
                      </TableCell>
                      <TableCell>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              onClick={() => {
                                globalStore.setActionMenuSelectedMessage(row);
                                globalStore.setOpenedModalMsg("edit");
                                setPopupEdit(true);
                              }}
                              id={`${row.id}`}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Edit"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>

                      <TableCell>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              id={`${row.id}`}
                              onClick={() => {
                                globalStore.setActionMenuSelectedMessage(row);
                                globalStore.setOpenedModalMsg("delete");
                                setPopupDel(true);
                              }}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Delete"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!showSpinner && messageList.length === 0 && <NoResult />}
        {items && items.length > 7 && (
          <Pagination
            itemCount={items.length}
            itemsPerPage={itemsPerPageCount}
            paginationCallback={(startInd: number, endInd: number) => {
              setStartIndex(startInd);
              setEndIndex(endInd);
            }}
          />
        )}

        <AddLobbyMessage
          callBackGetMsgList={getConfigurationMsgList}
          popup={popupT}
          closeCallback={() => closeCallbackHandler()}
        />
        <EditLobbyMessage
          callBackGetMsgList={getConfigurationMsgList}
          popup={popupEdit}
          closeCallback={() => closeCallbackHandler()}
        />
        <DeleteLobbyMessage
          callBackGetMsgList={getConfigurationMsgList}
          popup={popupDel}
          closeCallback={() => closeCallbackHandler()}
        />
      </Grid>
    );
  });
}
