import React, { useEffect, useState } from "react";

import {
  Divider,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { observer, useObserver } from "mobx-react-lite";
import { useGlobalStore } from "../../state/GlobalStore";

const fontSize = [
  "8",
  "9",
  "10",
  "11",
  "12",
  "14",
  "16",
  "18",
  "20",
  "22",
  "24",
  "26",
  "28",
  "36",
  "48",
  "72",
];

interface IFontSizeDropdownProps {
  fontSize: string;
  headingName: string;
}

export default function FonstSizeDropdown(props: IFontSizeDropdownProps) {
  const globalStore = useGlobalStore();

  return useObserver(() => {
    return (
      <FormControl
        size="small"
        style={{
          backgroundColor: "white",
          border: "1px solid #ECEBED",
          borderRadius: "6px",
          width: "124px",
        }}
      >
        <Select
          variant="outlined"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          value={props.fontSize.substring(0, props.fontSize.length - 2)}
          inputProps={{ "aria-label": "Without label" }}
          className="dropdown"
        >
          {fontSize.map((item) => (
            <MenuItem
              key={item}
              value={item}
              onClick={() => {
                globalStore.setSelectedFontSize(`${item}px`, props.headingName);
              }}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  });
}
