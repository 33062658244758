/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { Grid, Tooltip, withStyles } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import {
  ConstantVariables,
  AppInsightsConstants as Constants,
} from "../../Constants";

import DateLeftIcon from "../../assets/date_left_icon.svg";
import DateRightIcon from "../../assets/date_right_icon.svg";
import LeftArrowDisabledIcon from "../../assets/left_arrow_disabled_icon.svg";
import RightArrowDisabledIcon from "../../assets/right_arrow_disabled_icon.svg";
import { AppInsightMethods } from "../../AppInsightMethods";
import { dashboardUtils } from "./SummaryTableUtils";
import HorizontalStepper from "./HorizontalStepper";
import { useGlobalStore } from "../../state/GlobalStore";
import VerticalStepper from "./VerticalStepper";
import { apiService } from "../../services/ApiService";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#FFF",
    color: "#3D3D3D",
    boxShadow: "0px 2px 7px #00000021",
    fontSize: 16,
  },
  arrow: {
    color: "#FFF",
  },
}))(Tooltip);

export default function SummaryDetailMain() {
  const globalStore = useGlobalStore();
  const appointment = globalStore.meetingDetailedView;
  const pageName = "SummaryDetailMain";
  const headerClassName = "header";
  const appInsights = useAppInsightsContext();
  const [showSpinner, setShowSpinner] = useState(false);

  const trackComponent = useTrackMetric(appInsights, pageName);

  const setMeetingOnKeydown = (e) => {
    globalStore.setSummaryDetailsPage(false);
  };

  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    setShowSpinner(true);
    const val = {
      meetingId: appointment?.televisitId,
      timespan: "PT48H", // to show last 2 days records
      // meetingId: "6e7de27b-33ef-471a-b110-ce526298f034",
      // timespan: "P14D",
    };
    apiService
      .getMeetingLogs(val)
      .then((res: any) => {
        setShowSpinner(false);
        globalStore.setMeetingLogsDetails(res);
      })
      .catch((error: any) => {
        setShowSpinner(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at getMeetingLogs API call, Component: ${pageName}, Error: ${error}`
        );
      });
  }, []);

  /* Method to go to next meeting in the list while in meeting detailed view */
  const goToNextMeeting = () => {
    try {
      globalStore.setMeetingDetailedView(
        globalStore.appointmentsList,
        globalStore.currentMeetingDetailedViewIndex !== null
          ? globalStore.currentMeetingDetailedViewIndex + 1
          : null,
        globalStore.currentMeetingListLength
      );
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The "Previous" button has been clicked to view the previous appointment detail.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at goToNextMeeting, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method to go to previous meeting in the list while in meeting detailed view */
  const goToPreviousMeeting = () => {
    try {
      globalStore.setMeetingDetailedView(
        // props.appointments,
        globalStore.appointmentsList,
        globalStore.currentMeetingDetailedViewIndex !== null
          ? globalStore.currentMeetingDetailedViewIndex - 1
          : null,
        globalStore.currentMeetingListLength
      );
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The "Previous" button has been clicked to view the previous appointment detail.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at goToPreviousMeeting, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <>
        <Grid className="summaryDetailMain">
          <Grid
            container
            className="navigation-buttons"
            justify="space-between"
            alignItems="center"
          >
            <Grid
              item
              onClick={() => {
                globalStore.setSummaryDetailsPage(false);
                globalStore.setMeetingLogsDetails({
                  emailStatus: null,
                  meetingLink: null,
                  loginDetails: null,
                  deviceSettings: null,
                  lobbyPage: null,
                  callStatus: null,
                  exitStatus: null,
                });
              }}
              onKeyDown={(e) => {
                setMeetingOnKeydown(e);
              }}
              className="pointer"
            >
              <Grid
                container
                className="button back noWrap"
                alignItems="center"
              >
                <LightTooltip title="Back to Home Page" arrow>
                  <img
                    className="back-icon"
                    src={DateLeftIcon}
                    alt="Back Arrow Icon"
                  />
                </LightTooltip>
                <span className="back text">{ConstantVariables.Back_Text}</span>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                className="button nextAndPrevious noWrap"
                alignItems="center"
              >
                {globalStore.currentMeetingDetailedViewIndex === 0 ? (
                  <Grid className="disabled">
                    <img
                      className="previous-icon"
                      src={LeftArrowDisabledIcon}
                      alt="Previous Meeting Disabled Arrow Icon"
                    />
                    <span className={`previous text`}>
                      {ConstantVariables.Previous_Text}
                    </span>{" "}
                  </Grid>
                ) : (
                  <Grid
                    className="pointer"
                    onClick={() => goToPreviousMeeting()}
                  >
                    <LightTooltip title="Previous patient" arrow>
                      <img
                        className="previous-icon"
                        src={DateLeftIcon}
                        alt="Previous Meeting Arrow Icon"
                      />
                    </LightTooltip>
                    <span className={`previous text`}>
                      {ConstantVariables.Previous_Text}
                    </span>
                  </Grid>
                )}
                {globalStore.currentMeetingDetailedViewIndex ===
                globalStore.currentMeetingListLength - 1 ? (
                  <Grid className="disabled">
                    <span className={`next text`}>
                      {ConstantVariables.Next_Text}
                    </span>
                    <img
                      className="next-icon"
                      src={RightArrowDisabledIcon}
                      alt="Next Meeting Disabled Arrow Icon"
                    />{" "}
                  </Grid>
                ) : (
                  <Grid className="pointer" onClick={() => goToNextMeeting()}>
                    <span className={`next text`}>
                      {ConstantVariables.Next_Text}
                    </span>
                    <LightTooltip title="Next patient" arrow>
                      <img
                        className="next-icon"
                        src={DateRightIcon}
                        alt="Next Meeting Arrow Icon"
                      />
                    </LightTooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="appointment" justify="space-between">
            <Grid item>
              <Grid container>
                <Grid item className="details">
                  <Grid className={"details-container"}>
                    <Grid className="patient">
                      {`
            ${appointment?.patientLastName}, ${appointment?.patientFirstName}
          `}
                    </Grid>
                    <Grid
                      className={` ${"status"} ${dashboardUtils.getBackGroundColor(
                        appointment?.status ? appointment?.status : ""
                      )}`}
                    >
                      {appointment?.status}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="patient-id">
                  <Grid className={headerClassName}>
                    {ConstantVariables.Meeting_List_Header_Column_Two_Text}
                  </Grid>
                  <Grid className="text">{appointment?.patientId}</Grid>
                </Grid>
                <Grid className="schedule">
                  <Grid className={headerClassName}>
                    {ConstantVariables.Appointment_Date_Time_Text}
                  </Grid>
                  <Grid className="text">{`${moment(
                    new Date(
                      appointment?.scheduleDateTimeTZD
                        ? appointment?.scheduleDateTimeTZD
                        : ""
                    )
                  ).format("DD/MM/YYYY")} ${moment(
                    new Date(
                      appointment?.scheduleDateTimeTZD
                        ? appointment?.scheduleDateTimeTZD
                        : ""
                    )
                  ).format("LT")}`}</Grid>
                </Grid>
                <Grid className="provider">
                  <Grid className={headerClassName}>
                    {ConstantVariables.Provider_Text}
                  </Grid>
                  <Grid className="text">
                    {`${appointment?.providerRequest[0].title}. ${appointment?.providerRequest[0].firstName} ${appointment?.providerRequest[0].lastName}`}
                  </Grid>
                </Grid>
                {appointment?.status === ConstantVariables.Status.completed && (
                  <Grid className="duration">
                    <Grid
                      className={headerClassName}
                    >{`${ConstantVariables.Duration_Text} ${ConstantVariables.Duration_Format_Text}`}</Grid>
                    <Grid className="text">00:34:10</Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <HorizontalStepper />
          <VerticalStepper />
        </Grid>
        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}
      </>
    );
  });
}
