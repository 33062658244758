/* eslint-disable @typescript-eslint/no-explicit-any */
export const sessionStorage = {
  setWithExpiry: async (key: string, value: any, ttl: number) => {
    const now = new Date();
    const addTime: number = ttl * 1000;
    const item = {
      value,
      expiry: now.getTime() + addTime,
    };

    window.sessionStorage.setItem(key, JSON.stringify(item));
  },

  getWithExpiry: async (key: string) => {
    const itemStr = window.sessionStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      window.sessionStorage.removeItem(key);
      return null;
    }
    return item.value;
  },
};
