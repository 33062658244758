/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField, Backdrop, CircularProgress } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useGlobalStore } from "../../state/GlobalStore";
import Previews from "../UploadFile/Preview";
import { AppInsightMethods } from "../../AppInsightMethods";

import { apiService } from "../../services/ApiService";

export default function LogoMain() {
  const globalStore = useGlobalStore();
  const pageName = "Logo";
  const appInsights = useAppInsightsContext();
  const [searchText, setApplicationName] = useState("");
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [logoFile, setLogoFile] = useState<any>([]);
  const [faviconFile, setFaviconFile] = useState<any>([]);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    trackComponent();
  }, []);

  const handleSave = () => {
    setShowSpinner(true);

    const formData = new FormData();
    formData.append("uploadLogo", logoFile);
    formData.append("uploadFavicon", faviconFile);

    formData.append("applicationName", searchText);
    apiService
      .addCustomizationLogo(formData)
      .then((res: any) => {
        if (res === "Success") {
          setShowSpinner(false);
          globalStore.setShowLogoSavePop(true);
          setTimeout(() => {
            globalStore.setShowLogoSavePop(false);
          }, 5000);
        }
      })
      .catch((error: any) => {
        setShowSpinner(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at adding customization logo API call, Component: ${pageName} Error : ${error}`
        );
      });
  };

  const handleLogoChange = (val, type) => {
    if (type === "favicon") {
      setFaviconFile(val);
    } else if (type === "logo") {
      setLogoFile(val);
    }
  };

  return useObserver(() => {
    return (
      <Grid className="LogoMain">
        <div className="headerLogo">Logo</div>
        <div className="title">Upload Logo</div>
        <div className="desc">
          (Upload or select a logo image to add to your global navigation bar)
        </div>
        <div className="uploadView">
          <Previews type="logo" handleLogoChange={handleLogoChange} />{" "}
        </div>
        <div className="title">Upload Favicon</div>
        <div className="desc">
          (Upload or select a favicon image to add to your global navigation
          bar)
        </div>
        <div className="uploadView">
          <Previews type="favicon" handleLogoChange={handleLogoChange} />{" "}
        </div>
        <div className="applicationTitle">Application Name</div>
        <TextField
          value={searchText}
          fullWidth
          multiline
          variant="outlined"
          placeholder="Write application name"
          required
          onChange={(e) => {
            setApplicationName(e.target.value);
          }}
          className="applicationTextBox"
        />
        <div className="buttonsLogo">
          <Button className="cancelButton">Cancel</Button>
          <Button className="saveButton" onClick={handleSave}>
            Save
          </Button>
        </div>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Grid>
    );
  });
}
