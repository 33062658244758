/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import LeftNav from "./LeftNav";
import DashboardContainerContent from "./DashboardContainerContent";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";

export default function DashboardContainer() {
  const appInsights = useAppInsightsContext();
  const pageName = "DashboardContainer";
  const globalStore = useGlobalStore();
  const [tabName, setTabName] = useState("Resources");
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightsConstants.Information,
      `${pageName} Page Load`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  }, []);

  return useObserver(() => {
    return (
      <Grid className={"dashboard_Container"}>
        {/* <LeftNav ontabChangeEvent={globalStore.setTabName} /> */}
        <DashboardContainerContent tabName={globalStore.tabName} />
      </Grid>
    );
  });
}
