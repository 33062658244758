/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Backdrop,
  Tooltip,
  IconButton,
  styled,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import { makeStyles } from "@mui/styles";
import { useObserver } from "mobx-react-lite";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";
import { helperMethods } from "../../hooks/helper";
import add from "../../assets/add.png";
import cancel from "../../assets/cancel.png";
import upload from "../../assets/upload.png";
import { apiService } from "../../services/ApiService";
import MultipleFileUploadField from "../UploadFile/DragnDrop";
import { ReactComponent as Thumbnail } from "../../assets/ImageThumbnail.svg";
import { useGlobalStore } from "../../state/GlobalStore";

const useStyles = makeStyles({
  paperRoot: {
    top: "72%",
    width: "200px",
    height: "119px",
    position: "absolute",
    marginLeft: "29.5rem",
  },
  dialog: {
    width: "720px",
    height: "778px",
    maxWidth: "720px",
  },
  dropZone: {
    width: "95%",
    border: "dashed",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    marginLeft: "13px",
    marginTop: "16px",
    marginBottom: "13px",
    boxSizing: "border-box",
    minHeight: "184px",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
});

interface IMaterialButton {
  callBackGetMaterialList: () => void;
  popup: boolean;
  closeCallback: () => void;
}
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#ADADAD",
    fontStyle: "italic",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const AddMaterial = (props: IMaterialButton) => {
  const pageName = "MaterialButton";
  const classes = useStyles();
  const helperAdd = helperMethods();
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [file, setFile] = useState("");
  const globalStore = useGlobalStore();
  /* eslint-enable no-param-reassign */
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadFiles, setUploadFile] = useState<File>();
  const [material, setMaterial] = useState({
    title: "",
    description: "",
    url: "",
    fileName: "",
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const [fileName, setFileName] = useState("No image uploaded");
  const [showTitleErrorMgs, setShowtitleErrorMgs] = useState("");
  const [setDescriptionErrorMgs, setShowDescriptionErrorMgs] = useState("");
  const [showFileErrorMgs, setShowFileErrorMgs] = useState("");
  const [showTitleErrorMessage, setShowTitleErrormsg] = useState(false);
  const [fileSizeexceed, setFileSizeExceed] = useState("");
  const [fileUploadSizeExceed, setFileUploadSizeExceed] = useState("");
  const [thumbnailFileExtensionError, setThumbnailFileExtensionError] =
    useState("");

  useEffect(() => {
    trackComponent();
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightsConstants.Information,
      `${pageName} Page Load`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
    // eslint-disable-next-line
  }, []);
  /* Handle Thumbnail File */
  const handleChange = (e) => {
    try {
      let url = URL.createObjectURL(e.target.files[0]);
      setFile(url);
      url = "";
      setSelectedFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Reset Field */
  const updatePhotoField = () => {
    try {
      setFile("");
      setFileName("No image uploaded");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at updatePhotoField, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const handleInputs = (e) => {
    try {
      const newdata = { ...material };
      newdata[e.target.id] = e.target.value;
      setMaterial(newdata);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleInputs, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const updateField = () => {
    try {
      setMaterial({ ...material, title: " ", description: " ", url: " " });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at updateField, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* File Upload calling API */
  const onFileUpload = async () => {
    try {
      let materialData = false;
      materialData = validate();
      if (materialData) {
        setShowTitleErrormsg(true);
        return;
      }
      let fileSizeValidation = false;
      fileSizeValidation = fileSizeValidate();
      if (fileSizeValidation) {
        return;
      }
      let fileUploadSizeValidation = false;
      fileUploadSizeValidation = fileUploadSizeValidate();
      if (fileUploadSizeValidation) {
        return;
      }

      if (uploadFiles) {
        const formData = new FormData();
        formData.append("title", material.title);
        formData.append("description", material.description);
        formData.append(
          "uploadedBy",
          "publictester3@acp200021a.onmicrosoft.com"
        );
        if (selectedFile) {
          formData.append("thumbnailFile", selectedFile, selectedFile.name);
        }
        formData.append("uploadFile", uploadFiles, uploadFiles.name);
        setShowSpinner(true);

        if (
          material.title !== "" ||
          material.description !== "" ||
          uploadFiles.name !== ""
        ) {
          apiService
            .newMaterial(formData)
            .then((res: any) => {
              if (res === "success") {
                helperAdd.setSuccessMessageEvent(
                  "Material added successfully!"
                );
              }
              props.closeCallback();
              setShowSpinner(false);
              updateField();
              updatePhotoField();
              globalStore.setOpenedModal("none");
              helperAdd.setSuccessMessageEvent("Material added successfully!");
              props.callBackGetMaterialList();
            })
            .catch((error: any) => {
              setShowSpinner(false);
              AppInsightMethods.TrackAppInsightsException(
                `Error at addingNew Material API call, Component: ${pageName} Error : ${error}`
              );
            });
        }
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at AddMaterial API, Component: ${pageName} Error : ${error}`
      );
    }
  };

  const validate = () => {
    try {
      let validationFailed = false;

      if (material.title === "") {
        validationFailed = true;
        setShowtitleErrorMgs("Please enter title");
      } else {
        setShowtitleErrorMgs("");
      }
      if (material.description === "") {
        validationFailed = true;
        setShowDescriptionErrorMgs("Please add Description");
      } else {
        setShowDescriptionErrorMgs("");
      }
      if (typeof uploadFiles?.name === "undefined" || uploadFiles.name === "") {
        validationFailed = true;
        setShowFileErrorMgs("Please upload file");
      } else {
        setShowFileErrorMgs("");
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at AddMaterial API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  /* onBlur handler for title field */
  const onBlurTitle = () => {
    try {
      if (material.title === "") {
        setShowtitleErrorMgs("Please enter Title");
      } else {
        setShowtitleErrorMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurTitle, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* onBlur handler for description field */
  const onBlurDescription = () => {
    try {
      if (material.description === "") {
        setShowDescriptionErrorMgs("Please add Description");
      } else {
        setShowDescriptionErrorMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurDescription, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Cancel button handler */
  const onCancelClickEventHandler = () => {
    try {
      setMaterial({
        title: "",
        description: "",
        url: "",
        fileName: "",
      });
      setFile("");
      setFileName("No image uploaded");
      setShowtitleErrorMgs("");
      setShowDescriptionErrorMgs("");
      setShowFileErrorMgs("");
      setShowTitleErrormsg(false);
      setFileSizeExceed("");
      setFileUploadSizeExceed("");

      globalStore.setOpenedModal("none");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Thumbnail File Validation */
  const fileSizeValidate = () => {
    try {
      let filesizevalidationFailed = false;
      const maxSize = 100;
      if (selectedFile) {
        const fsize = selectedFile.size / 1024;
        if (fsize > maxSize) {
          filesizevalidationFailed = true;
          setFileSizeExceed("Image size exceed 100kB");
        } else {
          setFileSizeExceed("");
        }
        if (
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "jpg" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "jpeg" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "png" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "gif" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "JPG" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "JPEG" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "PNG" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "GIF"
        ) {
          filesizevalidationFailed = true;
          setThumbnailFileExtensionError("File Format not supported");
        } else {
          setThumbnailFileExtensionError("");
        }
      }
      return filesizevalidationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at fileSizeValidate, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };
  /* Upload File Validation */
  const fileUploadSizeValidate = () => {
    try {
      let filesizevalidationFailed = false;
      const maxSize = 2;
      if (uploadFiles) {
        const fsize = uploadFiles.size / (1024 * 1024);
        if (fsize > maxSize) {
          filesizevalidationFailed = true;
          setFileUploadSizeExceed("File size should not be more than 2MB");
        } else {
          setFileUploadSizeExceed("");
        }
      }
      return filesizevalidationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at fileUploadSizeValidate, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  return useObserver(() => {
    return (
      <Dialog
        open={props.popup}
        onClose={() => globalStore.setOpenedModal("none")}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle style={{ paddingBottom: 0 }}>
          <b style={{ marginRight: "28rem" }}>Add New Material</b>
          <IconButton
            onClick={() => {
              onCancelClickEventHandler();
              props.closeCallback();
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid className={"material-text"}>
            Title
            <span className={"material-title"}>*</span>
          </Grid>
          <Grid className={"materialText-box"}>
            <TextField
              fullWidth
              variant="outlined"
              value={material.title}
              onChange={handleInputs}
              placeholder="Material Title"
              required
              id="title"
              error={!!showTitleErrorMgs}
              onBlur={() => onBlurTitle()}
            />
          </Grid>
          <div className={"material-error"}>{showTitleErrorMgs || ""}</div>
          <Grid className={"material-text"}>
            Description<span className={"material-title"}>*</span>
          </Grid>
          <Grid className={"materialText-box"}>
            <TextField
              fullWidth
              id="description"
              required
              variant="outlined"
              onChange={handleInputs}
              value={material.description}
              placeholder="Type Material Description"
              multiline
              rows={4}
              error={!!setDescriptionErrorMgs}
              onBlur={() => onBlurDescription()}
            />
          </Grid>
          <div className={"material-error"}>{setDescriptionErrorMgs || ""}</div>
          <Grid className={"material-text"}>
            Upload material file<span className={"material-title"}>*</span>
          </Grid>

          <Grid
            className={
              showTitleErrorMessage ? "materialRedBox" : "materialText-box"
            }
          >
            <MultipleFileUploadField
              handleFileChange={(fileUp: File) => {
                setUploadFile(fileUp);
                if (fileUp && fileUp.name !== "") {
                  setShowFileErrorMgs("");
                  setShowTitleErrormsg(false);
                }
              }}
              type="material"
            />
          </Grid>
          <div className={"material-error"}>{showFileErrorMgs || ""}</div>
          <div className={"material-error"}>{fileUploadSizeExceed || ""}</div>
          <Grid className={"material-text"}>
            Upload thumbnail image&nbsp;
            <LightTooltip title="If the reference image is not uploaded default image will appear in lobby for this material.">
              <IconButton>
                <InfoOutlinedIcon style={{ width: "15px", height: "15px" }} />
              </IconButton>
            </LightTooltip>
          </Grid>
          <em style={{ fontSize: "12px", color: "#ADADAD" }}>
            (Upload reference image for the material)
          </em>
          <div>
            <label htmlFor="files" className="uploadbtn">
              <span style={{ marginLeft: "12px" }}>
                <img
                  src={upload}
                  alt="upload"
                  className="uploadArrow"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                />
                Image
              </span>
              <span className={selectedFile ? "fileName" : "defaultfileName"}>
                {fileName}
              </span>
              <input
                id="files"
                hidden
                name="upload-photo"
                type="file"
                accept="image/*"
                onChange={handleChange}
              />
            </label>
          </div>

          <Grid className="backgroundBox">
            <Thumbnail />
            <div className="fileInfo">200px X 120px</div>
          </Grid>
          <div className="uploadContentMgs">
            Supports : PNG, JPEG | Max Size : 100 KB&emsp;
            {file.length > 0 && (
              <span className={"material-errorFileSize"}>
                {fileSizeexceed || ""}
                <div className={"material-error"}>
                  {thumbnailFileExtensionError || ""}
                </div>
              </span>
            )}
          </div>
          {file.length > 0 && (
            <Card className={classes.paperRoot}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="img"
                  height="140"
                  image={file}
                  title="Contemplative Reptile"
                />
              </CardActionArea>
            </Card>
          )}
        </DialogContent>

        <DialogActions style={{ paddingBottom: 16 }}>
          <Button
            onClick={() => {
              onCancelClickEventHandler();
              props.closeCallback();
            }}
          >
            <img
              src={cancel}
              alt="cancel"
              className="logo pointer cursor"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              tabIndex={0}
            />
          </Button>
          <Button onClick={onFileUpload}>
            <img
              src={add}
              alt="add"
              className="logo pointer cursor"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              tabIndex={0}
            />
          </Button>
        </DialogActions>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Dialog>
    );
  });
};

export default AddMaterial;
