import React from "react";
import { useObserver } from "mobx-react-lite";
import { Grid, Alert } from "@mui/material";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import DashboardContainer from "./components/Dashboard/DashboardContainer";
import Header from "./components/Dashboard/Header";
import { Authentication } from "./components/Authentication";
import { reactPlugin } from "./AppInsights";
import { ConstantVariables } from "./Constants";
import CustomContainer from "./components/Customization/CustomContainer";
import { useGlobalStore } from "./state/GlobalStore";
import LeftNav from "./components/Dashboard/LeftNav";
import CustomLeftNav from "./components/Customization/CustomLeftNav";

function App() {
  const globalStore = useGlobalStore();
  return useObserver(() => {
    return (
      <Authentication>
        <AppInsightsErrorBoundary
          onError={() => <h1>{ConstantVariables.ErrorBoundaryTextMessage}</h1>}
          appInsights={reactPlugin}
        >
          <AppInsightsContext.Provider value={reactPlugin}>
            <Grid className="App">
              {globalStore.customScreenFlag ? (
                <CustomLeftNav
                  ontabChangeEvent={globalStore.setCustomLeftNav}
                />
              ) : (
                <LeftNav ontabChangeEvent={globalStore.setTabName} />
              )}
              <Header />
              {globalStore.successMessage.trim().length > 0 && (
                <Alert
                  severity="success"
                  className={"dashboard_Alert"}
                  onClose={() => {
                    globalStore.setSuccessMessage("");
                  }}
                >
                  {globalStore.successMessage}
                </Alert>
              )}
              {globalStore.customScreenFlag ? (
                <CustomContainer />
              ) : (
                <DashboardContainer />
              )}
            </Grid>
          </AppInsightsContext.Provider>
        </AppInsightsErrorBoundary>
      </Authentication>
    );
  });
}

export default App;
