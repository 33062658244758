/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-param-reassign: "error" */
import React, { useState, useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useObserver } from "mobx-react-lite";
import { apiService } from "../../services/ApiService";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import Pagination from "../Pagination/Pagination";
import { NoUsers } from "../Resources/Resources";
import { IFetchTestUser } from "../../state/StoreTypes";
import { sessionStorage } from "../../state/SessionStorage";
import { ReactComponent as DeleteIcon } from "../../assets/DeleteIcon.svg";
import AddtestUser from "./AddTestUser";
import { LightTooltip } from "../Relationship/RelationshipList";
import DeleteTestUser from "./DeleteTestUser";
import AddTestAppointmentTab from "./CreateTestAppointmentTab";
import DeleteMulTestUser from "./DeleteMultipleUsers";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

export default function TestUserList() {
  const pageName = "Test User List";
  const appInsights = useAppInsightsContext();
  const classes = useStyles();
  const alignLeftText = "left";
  const itemsPerPageCount = 7;
  const [testUserList, setTestUserList] = useState<IFetchTestUser[]>([]);
  const globalStore = useGlobalStore();
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPageCount);
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);
  const [popupT, setPopup] = useState(false);
  const [popupDel, setPopupDel] = useState(false);
  const [popupMulDel, setPopupMulDel] = useState(false);
  const [popupCreate, setPopupCreate] = useState(false);

  const fetchTestUserList = () => {
    try {
      setShowSpinner(true);
      apiService
        .fetchTestUser()
        .then((data: IFetchTestUser[]) => {
          setShowSpinner(false);
          setTestUserList(data);
          globalStore.setActionMenuSelectedTestUserObj(data);
          sessionStorage.setWithExpiry("testUser", data, 600);
          globalStore.loginUserEmail;
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at fetch Test User List, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at fetchTestUserList, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const closeCallbackHandler = () => {
    try {
      setPopup(false);
      setPopupDel(false);
      setPopupCreate(false);
      setPopupMulDel(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    trackComponent();
    const pageLoadApiCall = async () => {
      const testUsersObj = await sessionStorage.getWithExpiry("testUser");
      if (testUsersObj) {
        setTestUserList(testUsersObj);
      } else {
        fetchTestUserList();
      }
    };
    pageLoadApiCall();
  }, []);

  const deleteCustomerByIds = () => {
    const arrayids: string[] = [];
    testUserList?.forEach((d) => {
      if (d.isChecked) {
        arrayids.push(d.userId);
      }
    });

    if (arrayids.length > 0) {
      setPopupMulDel(true);
    }
  };

  return useObserver(() => {
    return (
      <Grid className="testUser-container">
        <Grid className="testUser">
          <Grid className={"testUser-left"}>{`Total Test Users ${
            testUserList.length > 0 ? `(${testUserList.length})` : ""
          }`}</Grid>
          <Grid className="deleteIconTest">
            {testUserList.length > 0 ? (
              <DeleteIcon
                onClick={() => {
                  deleteCustomerByIds();
                  globalStore.setActionMenuSelectedTestUserObj(testUserList);
                }}
              />
            ) : (
              ""
            )}
          </Grid>
          <LightTooltip
            title={ConstantVariables.RoleBasedAccessErrorMessage}
            disableHoverListener={
              globalStore.actionMenuSelectedUserRole ===
              globalStore.fullControlGroup
            }
          >
            <span>
              <Button
                variant="contained"
                disabled={
                  globalStore.actionMenuSelectedUserRole !==
                  globalStore.fullControlGroup
                }
                style={{
                  opacity:
                    globalStore.actionMenuSelectedUserRole !==
                    globalStore.fullControlGroup
                      ? 0.5
                      : 1,
                }}
                className={"newMaterialBtn"}
                startIcon={<AddIcon />}
                onClick={() => {
                  setPopup(true);
                  AppInsightMethods.trackAppInsightsEvent(
                    pageName,
                    AppInsightsConstants.Information,
                    `The "Test User" button has been clicked.`,
                    "",
                    window.location.href,
                    globalStore.loginUserEmail
                  );
                }}
              >
                Test User
              </Button>
            </span>
          </LightTooltip>
        </Grid>
        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}

        {!showSpinner && testUserList && testUserList.length > 0 && (
          <TableContainer className={"testUserList_table_container"}>
            <Table
              className={`${classes.table} testUserList_table`}
              aria-label="simple table"
            >
              <TableHead className={"testUserListHeader"}>
                <TableRow>
                  <TableCell style={{ width: 40 }}>
                    <input
                      type="checkbox"
                      disabled={
                        globalStore.actionMenuSelectedUserRole !==
                        globalStore.fullControlGroup
                      }
                      style={{
                        opacity:
                          globalStore.actionMenuSelectedUserRole !==
                          globalStore.fullControlGroup
                            ? 0.5
                            : 1,
                      }}
                      checked={
                        !testUserList.some((user) => user?.isChecked !== true)
                      }
                      onChange={(e) => {
                        const value = e.target.checked;
                        setTestUserList(
                          testUserList.map((d) => {
                            d.isChecked = value;
                            return d;
                          })
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align={alignLeftText}
                    className={`headerCell `}
                    tabIndex={0}
                    style={{ minWidth: 100 }}
                  >
                    {"First Name"}
                  </TableCell>

                  <TableCell
                    align={alignLeftText}
                    tabIndex={0}
                    className={`headerCell `}
                    style={{ minWidth: 110 }}
                  >
                    {"Last Name"}
                  </TableCell>
                  <TableCell
                    align={alignLeftText}
                    className={`headerCell `}
                    tabIndex={0}
                    style={{ minWidth: 222 }}
                  >
                    {"Email ID"}
                  </TableCell>

                  <TableCell
                    align={alignLeftText}
                    className={`headerCell `}
                    tabIndex={0}
                  >
                    {"Phone Number"}
                  </TableCell>

                  <TableCell
                    align={alignLeftText}
                    className={`headerCell `}
                    tabIndex={0}
                  >
                    {""}
                  </TableCell>
                  <TableCell
                    align={alignLeftText}
                    className={`headerCell `}
                    tabIndex={0}
                  >
                    {""}
                  </TableCell>
                  <TableCell
                    align={alignLeftText}
                    className={`headerCell `}
                    tabIndex={0}
                  >
                    {""}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={"testUserListBody"}>
                {testUserList
                  ?.slice(startIndex, endIndex)
                  .map((row: IFetchTestUser) => (
                    <TableRow key={`${row.id}`} id={row.id}>
                      <TableCell style={{ width: 40 }}>
                        <input
                          type="checkbox"
                          disabled={
                            globalStore.actionMenuSelectedUserRole !==
                            globalStore.fullControlGroup
                          }
                          style={{
                            opacity:
                              globalStore.actionMenuSelectedUserRole !==
                              globalStore.fullControlGroup
                                ? 0.5
                                : 1,
                          }}
                          checked={row.isChecked}
                          onChange={(e) => {
                            const value = e.target.checked;
                            setTestUserList(
                              testUserList.map((sd) => {
                                if (sd.id === row.id) {
                                  sd.isChecked = value;
                                }
                                return sd;
                              })
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell titleCell overflowHidden"
                        title={row.firstName}
                      >
                        <span className="bold">{row.firstName}</span>
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell  overflowHidden"
                        title={row.lastName}
                      >
                        <span className="bold">{row.lastName}</span>
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell  overflowHidden"
                        title={row.email}
                      >
                        <span className="bold">{row.email}</span>
                      </TableCell>

                      <TableCell
                        align={alignLeftText}
                        className="bodyCell  overflowHidden"
                        title={row.phone}
                        style={{ width: 125 }}
                      >
                        <span className="bold">{row.phone}</span>
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className={`headerCell `}
                        tabIndex={0}
                      >
                        {""}
                      </TableCell>
                      <TableCell style={{ width: 195 }}>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              className="editnDel"
                              onClick={() => {
                                globalStore.setActionMenuSelectedTestUser(row);

                                setPopupCreate(true);
                                AppInsightMethods.trackAppInsightsEvent(
                                  pageName,
                                  AppInsightsConstants.Information,
                                  `Create Test Appointment button has been clicked. Test User : ${row.firstName}, id: ${row.id}`,
                                  globalStore.loginUserEmail,
                                  window.location.href,
                                  ""
                                );
                              }}
                              id={`${row.id}`}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Create Test Appointment"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>

                      <TableCell>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              id={`${row.id}`}
                              onClick={() => {
                                globalStore.setActionMenuSelectedTestUser(row);

                                setPopupDel(true);
                                AppInsightMethods.trackAppInsightsEvent(
                                  pageName,
                                  AppInsightsConstants.Information,
                                  `Delete Test User button has been clicked. Test User : ${row.firstName}, id: ${row.id}`,
                                  globalStore.loginUserEmail,
                                  window.location.href,
                                  ""
                                );
                              }}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Delete"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!showSpinner && testUserList.length === 0 && <NoUsers />}
        {testUserList && testUserList.length > 7 && (
          <Pagination
            itemCount={testUserList.length}
            itemsPerPage={itemsPerPageCount}
            paginationCallback={(startInd: number, endInd: number) => {
              setStartIndex(startInd);
              setEndIndex(endInd);
            }}
          />
        )}
        <AddtestUser
          callBackFetchTestUserList={fetchTestUserList}
          popup={popupT}
          closeCallback={() => closeCallbackHandler()}
        />
        <DeleteTestUser
          callBackFetchTestUserList={fetchTestUserList}
          popup={popupDel}
          closeCallback={() => closeCallbackHandler()}
        />
        <AddTestAppointmentTab
          callBackFetchTestAppointmentList={fetchTestUserList}
          popup={popupCreate}
          closeCallback={() => closeCallbackHandler()}
        />
        <DeleteMulTestUser
          callBackFetchTestUserList={fetchTestUserList}
          popup={popupMulDel}
          closeCallback={() => closeCallbackHandler()}
        />
      </Grid>
    );
  });
}
