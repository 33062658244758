/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Backdrop, CircularProgress, Grid, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useObserver } from "mobx-react-lite";
import {
  useTrackMetric,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import cancel from "../../assets/cancel.png";
import ok from "../../assets/ok.png";
import { useGlobalStore } from "../../state/GlobalStore";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import { AppInsightMethods } from "../../AppInsightMethods";
import { IDelAppointmentMsg } from "../../state/StoreTypes";

interface IAppointmentMessageButton {
  callBackGetMsgList: () => void;
  popup: boolean;
  closeCallback: () => void;
}

export default function DeleteAppointmentMessage(
  props: IAppointmentMessageButton
) {
  const pageName = "Delete Appointment Message";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const helper = helperMethods();
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [alertMeassage, setalertMeassage] = React.useState(false);
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);
  const handleClose = () => {
    try {
      if (!props.popup) {
        globalStore.setOpenedModalMsg("none");
        globalStore.setActionMenuSelectedAppointmentMsg(null);
        setalertMeassage(false);
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          AppInsightsConstants.Information,
          `Delete Appointment Message close/cancel button click event.`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleClose, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const onOkbtnClick = () => {
    try {
      const delAppointment: IDelAppointmentMsg = {
        id: globalStore.actionMenuSelectedAppointmentMsg?.id,
        messageType: globalStore.actionMenuSelectedAppointmentMsg?.messageType,
      };
      setShowSpinner(true);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Delete Appointment Message ok button click event.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      apiService
        .delAppointmentMsg(delAppointment)
        .then((res: any) => {
          setShowSpinner(false);
          setalertMeassage(false);
          if (res === "success") {
            helper.setSuccessMessageEvent(
              "Appointment Message deleted successfully!"
            );
          }
          AppInsightMethods.trackAppInsightsEvent(
            pageName,
            AppInsightsConstants.Information,
            `Delete API call success.`,
            globalStore.loginUserEmail,
            window.location.href,
            ""
          );
          handleClose();
          props.closeCallback();
          props.callBackGetMsgList();
        })
        .catch((error: any) => {
          AppInsightMethods.TrackAppInsightsException(
            `Error at Delete Appointment Message, Component: ${pageName}, Error: ${error}`
          );
          setShowSpinner(false);
          setalertMeassage(true);
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onOkbtnClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <>
        <Dialog open={props.popup} onClose={handleClose}>
          <Grid className="AppointmentdeleteDialog">
            <DialogTitle className="AppointmenttitleDelete">
              <b>Delete</b>
              <IconButton
                onClick={() => {
                  handleClose();
                  props.closeCallback();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <DialogContentText className="AppointmentcontentText">
                Are you sure you want to delete{" "}
                <span className={"AppointmenttextBold"}>
                  {globalStore.actionMenuSelectedAppointmentMsg?.messageType}
                </span>
                &nbsp;message ?
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialogbtnStyle">
              <Button
                onClick={() => {
                  handleClose();
                  props.closeCallback();
                }}
                className="AppointmentbtnStyle"
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button
                onClick={() => onOkbtnClick()}
                style={{ paddingRight: "2rem" }}
              >
                <img
                  src={ok}
                  alt="ok"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
            {alertMeassage && (
              <span className={"errorMessage deleteAppointmentDialog"}>
                {ConstantVariables.Common_Error_Text}
              </span>
            )}
          </Grid>
        </Dialog>
        {showSpinner && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
}
