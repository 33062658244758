/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import {
  useTrackMetric,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import Resources from "../Resources/Resources";
import Summary from "../Summary/Summary";
import SummaryDetailMain from "../Summary/SummaryDetailMain";
import Appointments from "../Appointments/Appointments";
import RelationshipList from "../Relationship/RelationshipList";
import General from "../General/General";
import AudioVideoSettings from "../AudioVideoSettings/AudioVideoSettings";
import NotificationsTab from "../Notifications/NotificationsTab";
import LanguageList from "../Languages/LanguageList";
import infoIcon from "../../assets/Info_Icon_yellow.svg";
import { ConstantVariables } from "../../Constants";

import TestAccounts from "../TestAppointment/TestUserTab";
import { useGlobalStore } from "../../state/GlobalStore";

interface IHeadingProps {
  title: string;
}

interface IDashboardContainerContentProps {
  tabName: string;
}

const Heading = (props: IHeadingProps) => {
  return (
    <Grid className={"dashboardContainerContent-heading"}>{props.title}</Grid>
  );
};

export default function DashboardContainerContent(
  props: IDashboardContainerContentProps
) {
  const pageName = "DashboardContainerContent";
  const globalStore = useGlobalStore();
  const appointment = globalStore.meetingDetailedView;

  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    return (
      <Grid className={"dashboardContainerContent"}>
        {props.tabName === "Summary" && globalStore.isSummaryDetailsPage ? (
          <Heading
            title={`Follow-up with Dr.  ${appointment?.providerRequest[0].firstName} ${appointment?.providerRequest[0].lastName}`}
          />
        ) : (
          <Heading title={props.tabName} />
        )}

        {globalStore.actionMenuSelectedUserRole !==
          globalStore.fullControlGroup &&
          globalStore.isDataAvailable && (
            <Grid container className="infoContainer">
              <img src={infoIcon} alt="Info Icon" className="infoIcon" />
              <span>{ConstantVariables.Access_Info}</span>
            </Grid>
          )}

        {props.tabName === "Resources" && <Resources />}
        {props.tabName === "Summary" &&
          (globalStore.isSummaryDetailsPage ? (
            <SummaryDetailMain />
          ) : (
            <Summary />
          ))}
        {props.tabName === "Appointments" && <Appointments />}
        {props.tabName === "Relationship" && <RelationshipList />}
        {props.tabName === "General" && <General />}
        {props.tabName === "Audio/ Video" && <AudioVideoSettings />}
        {props.tabName === "Notifications" && <NotificationsTab />}
        {props.tabName === "Language" && <LanguageList />}
        {props.tabName === "Test Accounts" && <TestAccounts />}
      </Grid>
    );
  });
}
