/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useObserver } from "mobx-react-lite";
import { apiService } from "../../services/ApiService";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import { useSortableData } from "../Dashboard/useSortableData";
import { ReactComponent as DownArrowDisabled } from "../../assets/down-arrow-disabled-icon.svg";
import { ReactComponent as DownArrow } from "../../assets/down-arrow-icon.svg";
import { ReactComponent as UpArrow } from "../../assets/up-arrow-icon.svg";
import Pagination from "../Pagination/Pagination";
import { NoResult } from "../Resources/Resources";
import { LightTooltip } from "../Relationship/RelationshipList";
import { IGetReminderList } from "../../state/StoreTypes";
import AddReminder from "./AddReminder";
import EditReminder from "./EditReminder";
import DeleteReminder from "./DeleteReminder";
import { sessionStorage } from "../../state/SessionStorage";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

interface IReminderListProps {
  searchText: string;
}

export default function ReminderList(props: IReminderListProps) {
  const pageName = "Reminder List";
  const appInsights = useAppInsightsContext();
  const classes = useStyles();
  const alignLeftText = "left";
  const itemsPerPageCount = 7;
  const [reminderList, setReminderList] = useState<IGetReminderList[]>([]);
  const [globalReminderList, setGlobalReminderList] = useState<
    IGetReminderList[]
  >([]);
  const globalStore = useGlobalStore();
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPageCount);
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);
  const [popupT, setPopup] = useState(false);
  const [popupDel, setPopupDel] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const { items, requestSort, sortConfig } = useSortableData(reminderList, {
    key: "minutesBefore",
    direction: "ascending",
  });

  const getReminderlist = () => {
    try {
      setShowSpinner(true);
      apiService
        .getReminder()
        .then((data: IGetReminderList[]) => {
          setShowSpinner(false);
          setReminderList(data);
          setGlobalReminderList(data);
          sessionStorage.setWithExpiry("reminders", data, 600);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get Reminder API call, getReminderlist, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getReminderlist, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const closeCallbackHandler = () => {
    try {
      setPopup(false);
      setPopupDel(false);
      setPopupEdit(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    trackComponent();
    const pageLoadApiCall = async () => {
      const reminders = await sessionStorage.getWithExpiry("reminders");
      if (reminders) {
        setReminderList(reminders);
        setGlobalReminderList(reminders);
      } else {
        getReminderlist();
      }
    };
    pageLoadApiCall();
  }, []);

  useEffect(() => {
    if (props.searchText) {
      const newItem: IGetReminderList[] = [...globalReminderList];
      const newArr = newItem.filter(
        (value: IGetReminderList) =>
          value.minutesBefore
            .toLowerCase()
            .includes(props.searchText.toLowerCase()) ||
          value.minutesBefore
            .toLowerCase()
            .includes(props.searchText.toLowerCase())
      );
      setReminderList(newArr);
    } else {
      setReminderList(globalReminderList);
    }
  }, [props.searchText]);

  const getClassNamesFor = (name: string) => {
    try {
      if (!sortConfig) {
        return <DownArrowDisabled />;
      }
      if (sortConfig.key === name && sortConfig.direction === "ascending") {
        return <UpArrow />;
      }
      if (sortConfig.key === name && sortConfig.direction === "descending") {
        return <DownArrow />;
      }
      return <DownArrowDisabled />;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getClassNamesFor, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  return useObserver(() => {
    return (
      <Grid className="reminder-container">
        <Grid className="reminder">
          <Grid className={"reminder-left"}>{`Reminders`}</Grid>
          <LightTooltip
            title={ConstantVariables.RoleBasedAccessErrorMessage}
            disableHoverListener={
              globalStore.actionMenuSelectedUserRole ===
              globalStore.fullControlGroup
            }
          >
            <span>
              <Button
                variant="contained"
                disabled={
                  globalStore.actionMenuSelectedUserRole !==
                  globalStore.fullControlGroup
                }
                style={{
                  opacity:
                    globalStore.actionMenuSelectedUserRole !==
                    globalStore.fullControlGroup
                      ? 0.5
                      : 1,
                }}
                className={"newMaterialBtn"}
                startIcon={<AddIcon />}
                onClick={() => {
                  setPopup(true);
                  AppInsightMethods.trackAppInsightsEvent(
                    pageName,
                    AppInsightsConstants.Information,
                    `The "Add Reminder" button has been clicked.`,
                    globalStore.loginUserEmail,
                    window.location.href,
                    ""
                  );
                }}
              >
                Reminder
              </Button>
            </span>
          </LightTooltip>
        </Grid>
        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}
        {!showSpinner && items && items.length > 0 && (
          <TableContainer className={"reminderList_table_container"}>
            <Table
              className={`${classes.table} reminderList_table`}
              aria-label="simple table"
            >
              <TableHead className={"reminderListHeader"}>
                <TableRow>
                  <TableCell
                    align={alignLeftText}
                    style={{ width: 316 }}
                    className={`headerCell `}
                    tabIndex={0}
                    onClick={() => requestSort("minutesBefore")}
                  >
                    {"Scheduled Reminder"}
                    {getClassNamesFor("minutesBefore")}
                  </TableCell>

                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={"reminderListBody"}>
                {items
                  ?.slice(startIndex, endIndex)
                  .map((row: IGetReminderList) => (
                    <TableRow key={`${row.id}`} id={row.id}>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell titleCell overflowHidden"
                        title={row.minutesBefore}
                      >
                        <span className="bold">{row.minutesBefore}</span>
                      </TableCell>
                      <TableCell align={alignLeftText} className="bodyCell">
                        <></>
                      </TableCell>
                      <TableCell align={alignLeftText} tabIndex={0}>
                        {""}
                      </TableCell>
                      <TableCell style={{ paddingLeft: 866 }}>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              onClick={() => {
                                globalStore.setActionMenuSelectedReminder(row);
                                globalStore.setOpenedModalReminder("edit");
                                setPopupEdit(true);
                                AppInsightMethods.trackAppInsightsEvent(
                                  pageName,
                                  AppInsightsConstants.Information,
                                  `Edit Reminder button has been clicked. Remainder : ${row.minutesBefore}, id: ${row.id}`,
                                  globalStore.loginUserEmail,
                                  window.location.href,
                                  ""
                                );
                              }}
                              id={`${row.id}`}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Edit"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>

                      <TableCell>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              id={`${row.id}`}
                              onClick={() => {
                                globalStore.setActionMenuSelectedReminder(row);
                                globalStore.setOpenedModalReminder("delete");
                                setPopupDel(true);
                                AppInsightMethods.trackAppInsightsEvent(
                                  pageName,
                                  AppInsightsConstants.Information,
                                  `Delete Reminder button has been clicked. Remainder : ${row.minutesBefore}, id: ${row.id}`,
                                  globalStore.loginUserEmail,
                                  window.location.href,
                                  ""
                                );
                              }}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Delete"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!showSpinner && reminderList.length === 0 && <NoResult />}
        {items && items.length > 7 && (
          <Pagination
            itemCount={items.length}
            itemsPerPage={itemsPerPageCount}
            paginationCallback={(startInd: number, endInd: number) => {
              setStartIndex(startInd);
              setEndIndex(endInd);
            }}
          />
        )}

        <AddReminder
          callBackGetreminderList={getReminderlist}
          popup={popupT}
          closeCallback={() => closeCallbackHandler()}
        />
        <EditReminder
          callBackGetreminderList={getReminderlist}
          popup={popupEdit}
          closeCallback={() => closeCallbackHandler()}
        />
        <DeleteReminder
          callBackGetreminderList={getReminderlist}
          popup={popupDel}
          closeCallback={() => closeCallbackHandler()}
        />
      </Grid>
    );
  });
}
