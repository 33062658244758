/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Backdrop, CircularProgress, Grid, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useObserver } from "mobx-react-lite";
import {
  useTrackMetric,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import cancel from "../../assets/cancel.png";
import ok from "../../assets/ok.png";
import { useGlobalStore } from "../../state/GlobalStore";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import { AppInsightMethods } from "../../AppInsightMethods";
import { IDelTestAppointment } from "../../state/StoreTypes";

interface ITestAppointment {
  callBackFetchTestAppointmentList: () => void;
  popup: boolean;
  closeCallback: () => void;
}

export default function DelTestAppointment(props: ITestAppointment) {
  const pageName = "Delete test Appointment";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const helper = helperMethods();
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [alertMeassage, setalertMeassage] = React.useState(false);
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);
  const handleClose = () => {
    try {
      if (!props.popup) {
        globalStore.setOpenedModalTestAppointment("none");
        globalStore.setActionMenuSelectedTestAppointment(null);
        setalertMeassage(false);

        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          AppInsightsConstants.Information,
          `Delete Test Appointment close/cancel button click event.`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleClose, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const onOkbtnClick = () => {
    try {
      if (globalStore.actionMenuSelectedTestAppointment?.televisitId) {
        const delTestAppointObj: IDelTestAppointment = {
          televisitId: [
            globalStore.actionMenuSelectedTestAppointment?.televisitId,
          ],
        };

        setShowSpinner(true);
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          AppInsightsConstants.Information,
          `Delete Test User ok button click event. Test User : ${globalStore.actionMenuSelectedTestUser?.firstName}, Id: ${globalStore.actionMenuSelectedTestUser?.id}`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
        apiService
          .delTestAppoint(delTestAppointObj)
          .then((res: any) => {
            setShowSpinner(false);
            setalertMeassage(false);
            if (res === "success") {
              helper.setSuccessMessageEvent(
                "Test Appointment deleted successfully!"
              );
            }
            AppInsightMethods.trackAppInsightsEvent(
              pageName,
              AppInsightsConstants.Information,
              `Delete API call success.`,
              globalStore.loginUserEmail,
              window.location.href,
              ""
            );
            handleClose();
            props.closeCallback();
            props.callBackFetchTestAppointmentList();
          })
          .catch((error: any) => {
            AppInsightMethods.TrackAppInsightsException(
              `Error at Delete Test Appointment, Component: ${pageName}, Error: ${error}`
            );
            setShowSpinner(false);
            setalertMeassage(true);
          });
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onOkbtnClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <>
        <Dialog open={props.popup} onClose={handleClose}>
          <Grid className="deleteDialogtestUser">
            <DialogTitle className="titleDeletetestUser">
              <b style={{ marginRight: 379 }}>Delete</b>
              <IconButton
                onClick={() => {
                  handleClose();
                  props.closeCallback();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <DialogContentText className="contentTexttestUser">
                Are you sure you want to delete{" "}
                <span className={"textBoldtestUser"}>
                  {`${globalStore.actionMenuSelectedTestAppointment?.patientFirstName} ${globalStore.actionMenuSelectedTestAppointment?.patientLastName}`}
                </span>
                &nbsp;Test user Appointment ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose;
                  props.closeCallback();
                }}
                className="btnStyle"
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button
                onClick={() => onOkbtnClick()}
                style={{ paddingRight: "2rem" }}
              >
                <img
                  src={ok}
                  alt="ok"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
            {alertMeassage && (
              <span className={"errorMessage deleteMaterialDialogtestUser"}>
                {ConstantVariables.Common_Error_Text}
              </span>
            )}
          </Grid>
        </Dialog>
        {showSpinner && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
}
