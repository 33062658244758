/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useObserver } from "mobx-react-lite";
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AppInsightMethods } from "../../AppInsightMethods";
import { apiService } from "../../services/ApiService";
import { useGlobalStore } from "../../state/GlobalStore";
import { IGetAppointmentMsg } from "../../state/StoreTypes";
import { useSortableData } from "../Dashboard/useSortableData";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import Pagination from "../Pagination/Pagination";
import { LightTooltip } from "../Relationship/RelationshipList";
import { NoResult } from "../Resources/Resources";
import { ReactComponent as DownArrowDisabled } from "../../assets/down-arrow-disabled-icon.svg";
import { ReactComponent as DownArrow } from "../../assets/down-arrow-icon.svg";
import { ReactComponent as UpArrow } from "../../assets/up-arrow-icon.svg";
import AddAppointmentMessage from "./AddAppointmentMsg";
import { sessionStorage } from "../../state/SessionStorage";
import DeleteAppointmentMessage from "./DelAppointmentMsg";
import EditAppointmentMessage from "./EditAppointmentMsg";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

interface IAppointmentMsgListProps {
  searchText: string;
}

export default function AppointmentMessageList(
  props: IAppointmentMsgListProps
) {
  const pageName = "Appointment Message";
  const appInsights = useAppInsightsContext();
  const classes = useStyles();
  const alignLeftText = "left";
  const itemsPerPageCount = 7;
  const [appointmentMsg, setAppointmentMsg] = useState<IGetAppointmentMsg[]>(
    []
  );
  const [globalAppointmentMsg, setGlobalAppointmentMsg] = useState<
    IGetAppointmentMsg[]
  >([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const globalStore = useGlobalStore();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [startIndex, setStartIndex] = useState(0);
  const [popupT, setPopup] = useState(false);
  const [popupDel, setPopupDel] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const [endIndex, setEndIndex] = useState(itemsPerPageCount);
  const { items, requestSort, sortConfig } = useSortableData(appointmentMsg, {
    key: "messageType",
    direction: "ascending",
  });

  const getAppointmentMsgList = () => {
    try {
      setShowSpinner(true);
      apiService
        .getAppointmentMsg()
        .then((data: IGetAppointmentMsg[]) => {
          setShowSpinner(false);
          setAppointmentMsg(data);
          setGlobalAppointmentMsg(data);
          sessionStorage.setWithExpiry("appointmentMsg", data, 600);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get appointment msg API call, getAppointmentMsgList, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getAppointmentMsgList, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    trackComponent();
    const pageLoadApiCall = async () => {
      const appointment = await sessionStorage.getWithExpiry("appointmentMsg");
      if (appointment) {
        setAppointmentMsg(appointment);
        setGlobalAppointmentMsg(appointment);
      } else {
        getAppointmentMsgList();
      }
    };
    pageLoadApiCall();
  }, []);

  const closeCallbackHandler = () => {
    try {
      setPopup(false);
      setPopupDel(false);
      setPopupEdit(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    if (props.searchText) {
      const newItem: IGetAppointmentMsg[] = [...globalAppointmentMsg];
      const newArr = newItem.filter(
        (value: IGetAppointmentMsg) =>
          value.messageType
            .toLowerCase()
            .includes(props.searchText.toLowerCase()) ||
          value.message.toLowerCase().includes(props.searchText.toLowerCase())
      );
      setAppointmentMsg(newArr);
    } else {
      setAppointmentMsg(globalAppointmentMsg);
    }
  }, [props.searchText]);

  const getClassNamesFor = (name: string) => {
    try {
      if (!sortConfig) {
        return <DownArrowDisabled />;
      }
      if (sortConfig.key === name && sortConfig.direction === "ascending") {
        return <UpArrow />;
      }
      if (sortConfig.key === name && sortConfig.direction === "descending") {
        return <DownArrow />;
      }
      return <DownArrowDisabled />;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getClassNamesFor, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  return useObserver(() => {
    return (
      <Grid className="appointmentMsg-container">
        <Grid className="appointmentMsg">
          <Grid
            className={"appointmentMsg-left"}
          >{`Total Appointment Messages ${
            appointmentMsg.length > 0 ? `(${appointmentMsg.length})` : ""
          }`}</Grid>
          <LightTooltip
            title={ConstantVariables.RoleBasedAccessErrorMessage}
            disableHoverListener={
              globalStore.actionMenuSelectedUserRole ===
              globalStore.fullControlGroup
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                className={"newAppointmentBtn"}
                disabled={
                  globalStore.actionMenuSelectedUserRole !==
                  globalStore.fullControlGroup
                }
                style={{
                  opacity:
                    globalStore.actionMenuSelectedUserRole !==
                    globalStore.fullControlGroup
                      ? 0.5
                      : 1,
                }}
                startIcon={<AddIcon />}
                onClick={() => {
                  setPopup(true);
                  AppInsightMethods.trackAppInsightsEvent(
                    pageName,
                    AppInsightsConstants.Information,
                    `The "Lobby appointmentMsg" button has been clicked.`,
                    globalStore.loginUserEmail,
                    window.location.href,
                    ""
                  );
                }}
              >
                Appointment Message
              </Button>
            </span>
          </LightTooltip>
        </Grid>
        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}

        {!showSpinner && items && items.length > 0 && (
          <TableContainer className={"appointmentMsgList_table_container"}>
            <Table
              className={`${classes.table} appointmentMsgList_table`}
              aria-label="simple table"
            >
              <TableHead className={"appointmentMsgListHeader"}>
                <TableRow>
                  <TableCell
                    align={alignLeftText}
                    style={{ width: 316 }}
                    className={`headerCell `}
                    tabIndex={0}
                    onClick={() => requestSort("messageType")}
                  >
                    {"Message Type"}
                    {getClassNamesFor("messageType")}
                  </TableCell>
                  <TableCell
                    align={alignLeftText}
                    className={"headerCell"}
                    onClick={() => requestSort("message")}
                  >
                    {"Message Description"}
                    {getClassNamesFor("message")}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={"appointmentMsgListBody"}>
                {items
                  ?.slice(startIndex, endIndex)
                  .map((row: IGetAppointmentMsg) => (
                    <TableRow key={`${row.id}`} id={row.id}>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell titleCell overflowHidden"
                        title={row.messageType}
                      >
                        <span className="bold">{row.messageType}</span>
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell overflowHidden appointmentMsgDescription"
                        title={row.message}
                        style={{ minWidth: 663 }}
                      >
                        {row.message}
                      </TableCell>
                      <TableCell align={alignLeftText} className="bodyCell">
                        <></>
                      </TableCell>
                      <TableCell align={alignLeftText} tabIndex={0}>
                        {""}
                      </TableCell>
                      <TableCell>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              onClick={() => {
                                setPopupEdit(true);
                                globalStore.setActionMenuSelectedAppointmentMsg(
                                  row
                                );
                              }}
                              id={`${row.id}`}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Edit"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>

                      <TableCell>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              id={`${row.id}`}
                              onClick={() => {
                                setPopupDel(true);
                                globalStore.setActionMenuSelectedAppointmentMsg(
                                  row
                                );
                              }}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Delete"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!showSpinner && appointmentMsg.length === 0 && <NoResult />}
        {items && items.length > 7 && (
          <Pagination
            itemCount={items.length}
            itemsPerPage={itemsPerPageCount}
            paginationCallback={(startInd: number, endInd: number) => {
              setStartIndex(startInd);
              setEndIndex(endInd);
            }}
          />
        )}
        <AddAppointmentMessage
          callBackGetMsgList={getAppointmentMsgList}
          popup={popupT}
          closeCallback={() => closeCallbackHandler()}
        />
        <DeleteAppointmentMessage
          callBackGetMsgList={getAppointmentMsgList}
          popup={popupDel}
          closeCallback={() => closeCallbackHandler()}
        />
        <EditAppointmentMessage
          callBackGetMsgList={getAppointmentMsgList}
          popup={popupEdit}
          closeCallback={() => closeCallbackHandler()}
        />
      </Grid>
    );
  });
}
