/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import DashboardTabs from "../Dashboard/DashboardTabs";
import { AppInsightsConstants } from "../../Constants";
import { AppInsightMethods } from "../../AppInsightMethods";
import LobbyMessageList from "./LobbyMessageList";
import ReminderList from "../Reminders/ReminderList";
import AppointmentMessageList from "../AppointmentMessage/AppointmentMessageList";

export default function Appointments() {
  const pageName = "Appointments";
  const appInsights = useAppInsightsContext();
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState("Appointment Messages");
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  return (
    <Grid>
      <DashboardTabs
        parentTabName={pageName}
        selectedSubTab={value}
        handleTabChange={(e: any, newValue: string) => {
          setValue(newValue);
          AppInsightMethods.trackAppInsightsEvent(
            pageName,
            AppInsightsConstants.Information,
            `The "${newValue}" tab has been clicked on the ${pageName} section.`,
            "",
            window.location.href,
            ""
          );
        }}
        searchText={searchText}
        setSearchText={(val: string) => setSearchText(val)}
      />
      {value === "Appointment Messages" && (
        <Grid>
          <AppointmentMessageList searchText={searchText} />
        </Grid>
      )}
      {value === "Lobby Messages" && (
        <Grid>
          <LobbyMessageList searchText={searchText} />
        </Grid>
      )}
      {value === "Reminders" && (
        <Grid>
          <ReminderList searchText={searchText} />
        </Grid>
      )}
    </Grid>
  );
}
