/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Backdrop, CircularProgress, Grid, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useObserver } from "mobx-react-lite";
import {
  useTrackMetric,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import cancel from "../../assets/cancel.png";
import ok from "../../assets/ok.png";
import { useGlobalStore } from "../../state/GlobalStore";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import { AppInsightMethods } from "../../AppInsightMethods";

interface ITestAppointment {
  callBackFetchTestAppointmentList: () => void;
  // popup: boolean;
  popupMultiple: boolean;
  closeCallback: () => void;
}

export default function DeleteMulTestAppointments(props: ITestAppointment) {
  const pageName = "Delete Multiple testAppointments";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const helper = helperMethods();
  const [alertMeassage, setalertMeassage] = React.useState(false);
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [isFetch, setIsfetch] = useState(false);

  useEffect(() => {
    trackComponent();
  }, []);
  const handleClose = () => {
    try {
      if (!props.popupMultiple) {
        globalStore.setOpenedModalTestAppointment("none");
        globalStore.setActionMenuSelectedTestAppointment(null);
        setalertMeassage(false);
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          AppInsightsConstants.Information,
          `Delete Test Appointment close/cancel button click event.`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleClose, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const deleteCustomerByIds = () => {
    const arrayids: string[] = [];
    globalStore.actionMenuSelectedTestAppointmentObj?.forEach((d) => {
      if (d.isChecked) {
        arrayids.push(d.televisitId);
      }
    });
    if (arrayids.length > 0) {
      setIsfetch(true);
      apiService
        .delTestAppointSel(arrayids)
        .then((res: any) => {
          setIsfetch(false);
          if (res === "success") {
            helper.setSuccessMessageEvent(
              "Test Appointment deleted successfully!"
            );
          }
          handleClose();
          props.closeCallback();
          props.callBackFetchTestAppointmentList();
        })

        .catch((err) => {
          setIsfetch(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at Test Appointment API call, Component: ${pageName} Error : ${err}`
          );
        });
    }
  };

  return useObserver(() => {
    return (
      <>
        <Dialog open={props.popupMultiple} onClose={handleClose}>
          <Grid className="deleteDialogtestUser">
            <DialogTitle className="titleDeletetestUser">
              <b style={{ marginRight: 353 }}>Delete</b>
              <IconButton
                onClick={() => {
                  handleClose();
                  props.closeCallback();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <DialogContentText className="contentTexttestUser">
                Are you sure you want to delete?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose;
                  props.closeCallback();
                }}
                className="btnStyle"
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button
                onClick={() => deleteCustomerByIds()}
                style={{ paddingRight: "2rem" }}
              >
                <img
                  src={ok}
                  alt="ok"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
            {alertMeassage && (
              <span className={"errorMessage deleteMaterialDialogtestUser"}>
                {ConstantVariables.Common_Error_Text}
              </span>
            )}
          </Grid>
        </Dialog>
        {isFetch && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
            open={isFetch}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
}
