export const Constants = {
  oltivaHealthCareText: "OLTIVA HEALTHCARE",
};
export class AppInsightsConstants {
  public static readonly Information = "Information";

  public static readonly Error = "Error";

  public static readonly Exception = "Exception";
}

export const ConstantVariables = {
  Common_Error_Text: "Something went wrong. Please try again.",
  Loading_Text: "Loading..",
  Camera_Text: "Camera",
  Speaker_Text: "Speaker",
  Microphone_Text: "Microphone",
  Network_Text: "Network Connection",
  Camera_Off_Text: "Your camera is off",
  Inbox_Text: "Inbox",
  New_Message_Received_Text: "A new message received!",
  Sign_In_With_Google_Text: "Sign in with Google",
  Sign_In_With_Facebook_Text: "Sign in with Facebook",
  Sign_In_With_Microsoft_Text: "Sign in with Microsoft",
  Sign_In_With_LinkedIn_Text: "Sign in with LinkedIn",
  Sign_In_With_MyChart_Text: "Sign in with MyChart",
  Sign_In_As_Guest_Text: "Sign in as Guest",
  Sign_In_Failed_Text: "Sign-in failed! Please try again.",
  DeleteDoneText: "Successfully Deleted",
  Client_Terms_Of_Service_Text:
    "Oltiva will obtain your name, email address, language preference, and profile picture from the chosen provider. Please read detailed privacy policy and terms of service.",
  Excellent_Text: "Excellent",
  Very_Good_Text: "Very Good",
  Good_Text: "Good",
  Fair_Text: "Fair",
  Poor_Text: "Poor",
  Send_Invite_Button_Text: "Send Invite",
  RoleBasedAccessErrorMessage: "You don't have permission to do this",
  Link_Copied_Text: "Link Copied",
  Participants_Text: "Participants",
  Email_Validation_Text: "Please Enter a Valid Email",
  Date_Of_Birth_Validation_Text: "Please select date of birth",
  Phone_Validation_Text: "Please provide valid phone number",
  Provide_Email_Validation_Text: "Please provide valid email address",
  First_Name_Validation_Text: "Please provide first name",
  Last_Name_Validation_Text: "Please provide last name",
  Patient_Registration_Form_Text: "Patient Registration Form",
  Patient_Registration_Message_Text:
    "Fill the below form to complete the registration with Oltiva services.",
  First_Name_Text: "First Name",
  Last_Name_Text: "Last Name",
  Primary_Email_Address_Text: "Primary Email Address",
  Secondary_Email_Address_Text: "Secondary Email Address",
  Primary_Phone_Number_Text: "Primary Phone Number",
  Secondary_Phone_Number_Text: "Secondary Phone Number",
  Date_Of_Birth_Text: "Date of Birth",
  Mandatory_Terms_And_Conditions_Text:
    "Please check this box to indicate that you accept the Terms and Conditions.",
  Mandatory_Terms_And_Conditions_Message_Text:
    "By checking, you're consent to receiving SMS messages from Oltiva",
  Cancel_Button_Text: "Cancel",
  Register_Button_Text: "Register",
  Read_And_Agree_Text: "Read and agree with Oltiva",
  Term_And_Conditions_Text: "terms and conditions",
  Check_Email_Inbox_Confirmation_Text:
    "Please check your email inbox for confirmation.",
  Fetch_User_Details_Failed_Text: "Failed to retrieve user details",
  Relationship_Validation_Text: "Please select relationship",
  ErrorBoundaryTextMessage: "Something went wrong. Please try again.",
  ImageFile:
    "https://upload.wikimedia.org/wikipedia/commons/2/2d/Snake_River_%285mb%29.jpg",
  ClientId: "a5ba5d31-5daf-4cfd-85b0-f3958a9f90f4",
  Authority:
    "https://login.microsoftonline.com/e03de411-1263-4660-8112-fe1342e1dca3",
  Scope: "a5ba5d31-5daf-4cfd-85b0-f3958a9f90f4/.default",
  mobileMaxWidth: 900,
  VirtualVisitLoginText:
    "Select your preferred login platform to continue with the Visit",
  RegistrationLoginText:
    "Select your preferred login platform to continue with the Registration",
  Access_Info:
    "The information is available in read only mode .You not have permissions to make any changes. Please contact system administrator for any issues.",
  Status: {
    scheduled: "Scheduled",
    completed: "Completed",
    inProgress: "In-Progress",
    waiting: "Waiting",
    delayed: "Delayed",
  },
  Meeting_List_Header_Column_One_Text: "Patient Name",
  Meeting_List_Header_Column_Two_Text: "Patient ID",
  Meeting_List_Header_Column_Three_Text: "Appointment Date",
  Meeting_List_Header_Column_Four_Text: "Appointment Time",
  Meeting_List_Header_Column_Five_Text: "Status",
  Meeting_List_Header_Column_Six_Text: "Provider",
  No_Records_Text: "No Records",
  Appointment_Date_Time_Text: "Appointment Date & Time",
  Provider_Text: "Provider",
  Duration_Text: "Duration",
  Duration_Format_Text: "(HH:MM:SS)",
  Back_Text: "Back",
  Next_Text: "Next",
  Previous_Text: "Previous",
  heading1Info:
    "Recommended using Font size in between min 28px to 32px max and font type to be Semibold or bold to maintain consistency in the overall theme. It has an impact on the success statements and main headings.",
  heading2Info:
    "Recommended using Font size in between min 22px to 26px max and font type to be Semibold or bold to maintain consistency in the overall theme. It has an impact on headings & tabs.",
  heading3Info:
    "Recommended using Font size in between min 20px to 24px max and font type to be regular to maintain consistency in the overall theme. It has an impact on the notifications & left menu tabs.",
  body1Info:
    "Recommended using Font size in between min 22px to 26px max and font type to be regular to maintain consistency in the overall theme. It has an impact on the subheading content, meeting exit statements & chatbot name.",
  body2Info:
    "Recommended using Font size in between min 20px to 24px max and font type to be regular to maintain consistency in the overall theme. It has an impact on the Subheadings & heading taglines.",
  body3Info:
    "Recommended using Font size in between min 18px to 22px max and font type to be regular to maintain consistency in the overall theme. It has an impact on body text.",
  body4Info:
    "Recommended using Font size in between min 16px to 20px max and font type to be regular to maintain consistency in the overall theme. It has an impact on form filling data & popup information.",
  body5Info:
    "Recommended using Font size in between min 16px to 20px max and font type to be Semibold or bold to maintain consistency in the overall theme. It has an impact on on form conditions.",
  body6Info:
    "Recommended using Font size in between min 14px to 18px max and font type to be regular to maintain consistency in the overall theme. It has an impact on the time frame and names in a chat panel.",
  body7Info:
    "Recommended using Font size in between min 12px to 16px max and font type to be regular to maintain consistency in the overall theme. It has an impact on the card view subheadings.",
  buttonInfo:
    "Recommended using Font size in between min 18px to 22px max and font type to be Semibold or bold to maintain consistency in the overall theme. It has an impact on sub-sub headings, buttons & tabs.",
  labelInfo:
    "Recommended using Font size in between min 18px to 22px max and font type to be Semibold or bold to maintain consistency in the overall theme. It has an impact on labels.",
};
