/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Backdrop,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as React from "react";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/GlobalStore";
import materialDocumentImage from "../../assets/materials_document.png";
import cancel from "../../assets/cancel.png";
import save from "../../assets/save.png";
import upload from "../../assets/upload.png";
import MultipleFileUploadField from "../UploadFile/DragnDrop";
import { ReactComponent as Thumbnail } from "../../assets/ImageThumbnail.svg";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#ADADAD",
    fontStyle: "italic",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const useStyles = makeStyles({
  customLabelColor: {
    color: "#fff",
  },
  paperRoot: {
    top: "72%",
    width: "200px",
    height: "119px",
    position: "absolute",
    marginLeft: "29.5rem",
  },
  dialog: {
    width: "720px",
    height: "800px",
    maxWidth: "720px",
  },
  dialogURL: {
    width: "720px",
    height: "722px",
    maxWidth: "720px",
  },
  dropZone: {
    width: "95%",
    border: "dashed",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    marginLeft: "13px",
    marginTop: "16px",
    marginBottom: "13px",
    boxSizing: "border-box",
    minHeight: "184px",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
});

interface IVideoButton {
  callBackGetVideoList: () => void;
  popup: boolean;
  closeCallback: () => void;
}

const EditVideo = (props: IVideoButton) => {
  const globalStore = useGlobalStore();
  const pageName = "EditVideo";
  const [uploadFile, setUploadFile] = React.useState<File>();
  const [thumbnailFiles, setThumbnailFiles] = React.useState<File>();
  const [title, setTitle] = useState("");
  const [fileN, setFileN] = useState("");
  const [description, setDescription] = useState("");
  const [filename, setFileName] = useState("");
  const [urlVal, setUrlVal] = useState<string>("");
  const [thumbnailFileName, setThumbnailFileName] = useState<
    string | undefined
  >("");
  const [thumbnailFile, setThumbnailFile] = useState<string | undefined>(
    "Default Image"
  );
  const [uploads, setUpload] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showTitleErrorMgs, setShowtitleErrorMgs] = useState("");
  const [setDescriptionErrorMgs, setShowDescriptionErrorMgs] = useState("");
  const [showFileErrorMgs, setShowFileErrorMgs] = useState("");
  const [showTitleErrorMessage, setShowTitleErrormsg] = useState(false);
  const [fileSizeexceed, setFileSizeExceed] = useState("");
  const [fileUploadSizeExceed, setFileUploadSizeExceed] = useState("");
  const [selectedSubTab, setSelectedSubTab] = useState("Upload Video");

  const [thumbnailFileExtensionError, setThumbnailFileExtensionError] =
    useState("");
  const classes = useStyles();
  const helperAdd = helperMethods();

  const handleChange = (e) => {
    try {
      let url = URL.createObjectURL(e.target.files[0]);
      setFileN(url);
      url = "";
      setThumbnailFile("");
      setThumbnailFiles(e.target.files[0]);
      setThumbnailFileName(e.target.files[0].name);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    if (globalStore.actionMenuSelectedVideo) {
      setTitle(globalStore.actionMenuSelectedVideo.title);
      setDescription(globalStore.actionMenuSelectedVideo.description);
      setFileName(globalStore.actionMenuSelectedVideo.fileName);
      if (
        globalStore.actionMenuSelectedVideo.thumbnailFileName === "" ||
        globalStore.actionMenuSelectedVideo.thumbnailFileName === null
      ) {
        setThumbnailFile("Default Image");
      } else {
        setThumbnailFile(globalStore.actionMenuSelectedVideo.thumbnailFileName);
      }
      setFileN(globalStore.actionMenuSelectedVideo.thumbnailUrl);
    }
  }, [globalStore.actionMenuSelectedVideo]);

  const confirmEditVideo = () => {
    try {
      let videoData = false;
      videoData = validate();
      if (videoData) {
        setShowTitleErrormsg(true);
        return;
      }
      let fileSizeValidation = false;
      fileSizeValidation = fileSizeValidate();
      if (fileSizeValidation) {
        return;
      }
      let fileUploadSizeValidation = false;
      fileUploadSizeValidation = fileUploadSizeValidate();
      if (fileUploadSizeValidation) {
        return;
      }

      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      if (globalStore.actionMenuSelectedVideo?.id) {
        formData.append("id", globalStore.actionMenuSelectedVideo?.id);
      }
      if (uploadFile === undefined) {
        if (globalStore.actionMenuSelectedVideo?.fileUrl) {
          formData.append("url", globalStore.actionMenuSelectedVideo?.fileUrl);
        }
      }
      if (thumbnailFiles === undefined) {
        if (globalStore.actionMenuSelectedVideo?.thumbnailUrl) {
          formData.append(
            "thumbnailUrl",
            globalStore.actionMenuSelectedVideo?.thumbnailUrl
          );
        }
      } else {
        formData.append("thumbnailUrl", "");
      }
      if (uploadFile) {
        formData.append("uploadFile", uploadFile, uploadFile.name);
      }
      if (thumbnailFiles) {
        formData.append(
          "thumbnailUploadFile",
          thumbnailFiles,
          thumbnailFiles.name
        );
      }
      setShowSpinner(true);
      apiService
        .editMaterial(formData)
        .then((res: any) => {
          if (res === "success") {
            helperAdd.setSuccessMessageEvent("Successfully Updated");
            setShowSpinner(false);
          }
          props.closeCallback();
          globalStore.setOpenedModal("none");
          props.callBackGetVideoList();
          setUpload(false);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at Edit video API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      setShowSpinner(false);
      AppInsightMethods.TrackAppInsightsException(
        `Error at confirm edit, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const onCancelClickEventHandler = () => {
    try {
      if (!props.popup) {
        globalStore.setOpenedModal("none");
        setThumbnailFile("");
        if (
          globalStore.actionMenuSelectedVideo?.thumbnailFileName === "" ||
          globalStore.actionMenuSelectedVideo?.thumbnailFileName === null
        ) {
          setThumbnailFileName("Default Image");
        } else {
          setThumbnailFileName(
            globalStore.actionMenuSelectedVideo?.thumbnailFileName
          );
        }
        setUpload(false);
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const validate = () => {
    try {
      let validationFailed = false;

      if (title === "") {
        validationFailed = true;
        setShowtitleErrorMgs("Please enter title");
      } else {
        setShowtitleErrorMgs("");
      }
      if (description === "") {
        validationFailed = true;
        setShowDescriptionErrorMgs("Please add Description");
      } else {
        setShowDescriptionErrorMgs("");
      }
      if (
        (typeof uploadFile?.name === "undefined" || uploadFile.name === "") &&
        globalStore.actionMenuSelectedVideo?.fileName === ""
      ) {
        validationFailed = true;
        setShowFileErrorMgs("Please upload file");
      } else {
        setShowFileErrorMgs("");
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Edit video API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  /* onBlur handler for title field */
  const onBlurTitle = () => {
    try {
      if (title === "") {
        setShowtitleErrorMgs("Please enter Title");
      } else {
        setShowtitleErrorMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurTitle, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* onBlur handler for description field */
  const onBlurDescription = () => {
    try {
      if (description === "") {
        setShowDescriptionErrorMgs("Please add Description");
      } else {
        setShowDescriptionErrorMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurDescription, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Thumbnail File Validation */
  const fileSizeValidate = () => {
    try {
      let filesizevalidationFailed = false;
      const maxSize = 100;
      if (thumbnailFiles) {
        const fsize = thumbnailFiles.size / 1024;
        if (fsize > maxSize) {
          filesizevalidationFailed = true;
          setFileSizeExceed("File size should not be more than 100kB");
        } else {
          setFileSizeExceed("");
        }
        if (
          thumbnailFiles?.name.split(".").pop() !== "jpg" &&
          thumbnailFiles?.name.split(".").pop() !== "jpeg" &&
          thumbnailFiles?.name.split(".").pop() !== "png" &&
          thumbnailFiles?.name.split(".").pop() !== "gif" &&
          thumbnailFiles?.name.split(".").pop() !== "JPG" &&
          thumbnailFiles?.name.split(".").pop() !== "JPEG" &&
          thumbnailFiles?.name.split(".").pop() !== "PNG" &&
          thumbnailFiles?.name.split(".").pop() !== "GIF"
        ) {
          filesizevalidationFailed = true;
          setThumbnailFileExtensionError("File Format not supported");
        } else {
          setThumbnailFileExtensionError("");
        }
      }
      return filesizevalidationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at fileSizeValidate, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };
  /* Upload File Validation */
  const fileUploadSizeValidate = () => {
    try {
      let filesizevalidationFailed = false;
      const maxSize = 2;
      if (uploadFile) {
        const fsize = uploadFile.size / (1024 * 1024);
        if (fsize > maxSize) {
          filesizevalidationFailed = true;
          setFileUploadSizeExceed("File size should not be more than 2MB");
        } else {
          setFileUploadSizeExceed("");
        }
      }
      return filesizevalidationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at fileUploadSizeValidate, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  const handleTabChange = (e, val) => {
    setSelectedSubTab(val);
  };

  return useObserver(() => {
    return (
      <Dialog
        open={props.popup}
        onClose={onCancelClickEventHandler}
        classes={{
          paper:
            selectedSubTab === "Upload Video"
              ? classes.dialog
              : classes.dialogURL,
        }}
      >
        <DialogTitle style={{ paddingBottom: 0 }}>
          <b style={{ marginRight: 532 }}>Edit Video</b>
          <IconButton
            onClick={() => {
              onCancelClickEventHandler();
              props.closeCallback();
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid className={"video-text"}>
            Title
            <span className={"video-title"}>*</span>
          </Grid>
          <Grid className={"videoText-box"}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Relaxation Video Title"
              required
              id="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              error={!!showTitleErrorMgs}
              onBlur={() => onBlurTitle()}
            />
          </Grid>
          <div className={"video-error"}>{showTitleErrorMgs || ""}</div>
          <Grid className={"video-text"}>
            Description<span className={"video-title"}>*</span>
          </Grid>
          <Grid className={"videoText-box"}>
            <TextField
              fullWidth
              id="description"
              required
              variant="outlined"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              value={description}
              placeholder="Type Video Description"
              multiline
              rows={4}
              error={!!setDescriptionErrorMgs}
              onBlur={() => onBlurDescription()}
            />
          </Grid>
          <div className={"video-error"}>{setDescriptionErrorMgs || ""}</div>

          <Grid className={"editSummary_nav_tabs_container"}>
            <>
              <Grid
                className={`editSummary_tab ${
                  selectedSubTab === "Upload Video" ? "active" : ""
                }`}
                onClick={(e) => handleTabChange(e, "Upload Video")}
              >
                <span>Upload Video</span>
              </Grid>
              <Grid
                className={`editSummary_tab ${
                  selectedSubTab === "URL" ? "active" : ""
                }`}
                onClick={(e) => handleTabChange(e, "URL")}
              >
                <span>URL</span>
              </Grid>
            </>
          </Grid>
          {selectedSubTab === "Upload Video" ? (
            <>
              <Grid className="videoText-box">
                {!uploads && (
                  <Grid className="fileUpload">
                    <span>
                      {filename}
                      <Button onClick={() => setUpload(true)}>
                        <CloseRoundedIcon className="crossbtnUploadFile" />
                      </Button>
                    </span>
                    <div className="progressbars">
                      <LinearProgress variant="determinate" value={100} />
                    </div>
                  </Grid>
                )}

                {uploads && (
                  <Grid
                    className={
                      showTitleErrorMessage ? "videoRedBox" : "videoText-box"
                    }
                  >
                    <MultipleFileUploadField
                      handleFileChange={(fileUp: File) => {
                        setUploadFile(fileUp);
                        if (fileUp && fileUp.name !== "") {
                          setShowFileErrorMgs("");
                          setShowTitleErrormsg(false);
                        }
                      }}
                      type="video"
                    />
                  </Grid>
                )}
              </Grid>
              <div className={"video-error"}>{showFileErrorMgs || ""}</div>
              <div className={"video-error"}>{fileUploadSizeExceed || ""}</div>
              <Grid className={"video-text"}>
                Upload thumbnail image&nbsp;
                <LightTooltip title="If the reference image is not uploaded default image will appear in lobby for this material.">
                  <IconButton>
                    <InfoOutlinedIcon
                      style={{ width: "15px", height: "15px" }}
                    />
                  </IconButton>
                </LightTooltip>
              </Grid>
              <em style={{ fontSize: "12px", color: "#ADADAD" }}>
                (Upload reference image for the material)
              </em>
              <div>
                <label htmlFor="files" className="uploadbtnVideo">
                  <span style={{ marginLeft: "12px" }}>
                    <img
                      src={upload}
                      alt="upload"
                      className="uploadArrowVideo"
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    />
                    Image
                  </span>
                  <span className="fileName">{thumbnailFileName}</span>
                  {thumbnailFiles === undefined && (
                    <span className="thumbnailFilename">
                      {thumbnailFile}
                      <CloseRoundedIcon className="thumbnailcrossbtn" />
                    </span>
                  )}

                  <input
                    id="files"
                    hidden
                    name="upload-photo"
                    type="file"
                    accept="image/*"
                    onChange={handleChange}
                  />
                </label>
              </div>

              <Grid className="backgroundBoxVideo">
                <Thumbnail />
                <div className="fileInfo">200px X 120px</div>
              </Grid>

              <div className="uploadContentMgs">
                Supports : PNG, JPEG | Max Size : 100 KB&emsp;
                {fileN.length > 0 && (
                  <span className={"video-error"}>
                    {thumbnailFileExtensionError || ""}
                    <div className={"video-error"}>{fileSizeexceed || ""}</div>
                  </span>
                )}
              </div>
              {fileN.length > 0 && (
                <Card className={classes.paperRoot}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="img"
                      height="140"
                      image={
                        thumbnailFile === "Default Image"
                          ? materialDocumentImage
                          : fileN
                      }
                      title="Contemplative Reptile"
                    />
                  </CardActionArea>
                </Card>
              )}
            </>
          ) : (
            <>
              {" "}
              <Grid className="videoText-box">
                <p className="thumbNailText">
                  Paste YouTube link or other video source URL
                </p>
                {!uploads && (
                  <Grid className="videoURL">
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter video source URL or YouTube link"
                      required
                      id="title"
                      value={urlVal}
                      onChange={(e) => {
                        setUrlVal(e.target.value);
                      }}
                    />
                  </Grid>
                )}
                {uploads && (
                  <Grid
                    className={
                      showTitleErrorMessage ? "videoRedBox" : "videoText-box"
                    }
                  >
                    <MultipleFileUploadField
                      handleFileChange={(fileUp: File) => {
                        setUploadFile(fileUp);
                        if (fileUp && fileUp.name !== "") {
                          setShowFileErrorMgs("");
                          setShowTitleErrormsg(false);
                        }
                      }}
                      type="video"
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ paddingBottom: 16 }}>
          <Button
            onClick={() => {
              onCancelClickEventHandler();
              props.closeCallback();
            }}
          >
            <img
              src={cancel}
              alt="cancel"
              className="logo pointer cursor"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              tabIndex={0}
            />
          </Button>
          <Button onClick={confirmEditVideo}>
            <img
              src={save}
              alt="save"
              className="logo pointer cursor"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              tabIndex={0}
            />
          </Button>
        </DialogActions>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Dialog>
    );
  });
};
export default EditVideo;
