import React from "react";
import { Grid, LinearProgress } from "@mui/material";
import { FileHeader } from "./FileHeader";

export interface SingleFileUploadWithProgressProps {
  file: File;
  onDelete: (file: File) => void;
}

export function SingleFileUploadWithProgress({
  file,
  onDelete,
}: SingleFileUploadWithProgressProps) {
  return (
    <>
      <Grid item style={{ color: "#1447D4", marginTop: "-2.5rem" }}>
        <span className="fileStyle">
          <FileHeader file={file} onDelete={onDelete} />
        </span>
      </Grid>
      <LinearProgress
        variant="determinate"
        value={100}
        className="progressbarsError"
      />
    </>
  );
}
