/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Alert,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Backdrop,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useObserver } from "mobx-react-lite";
import { AntSwitch } from "../Materials/MaterialList";
import { IMeetingSettings } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";
import { useGlobalStore } from "../../state/GlobalStore";
import { LightTooltip } from "../Relationship/RelationshipList";
import { AppInsightMethods } from "../../AppInsightMethods";
import { sessionStorage } from "../../state/SessionStorage";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
  root: {
    borderBottom: "none",
  },
});

interface IMeetingSettingsProps {
  searchString: string;
}

export default function MeetingSettings(props: IMeetingSettingsProps) {
  const pageName = "MeetingSettings";
  const alignLeftText = "left";
  const classes = useStyles();
  const globalStore = useGlobalStore();
  const [isFetch, setIsFetch] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [meetingSettings, setMeetingSettings] = useState<IMeetingSettings[]>(
    []
  );
  const rangeArr = [1, 2, 3, 4, 5, 6, 7];

  const getMeetingSettings = () => {
    try {
      setIsFetch(true);
      apiService
        .getMeetingSettings()
        .then((value: IMeetingSettings[]) => {
          setIsFetch(false);
          if (value.length > 0) {
            setMeetingSettings(value);
            sessionStorage.setWithExpiry("meetingSettings", value, 600);
          }
        })
        .catch((error: any) => {
          setIsFetch(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get Meeting API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getAllSystemSettings, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    const pageLoadApiCall = async () => {
      const meetingSet = await sessionStorage.getWithExpiry("meetingSettings");
      if (meetingSet) {
        setMeetingSettings(meetingSet);
      } else {
        getMeetingSettings();
      }
    };
    pageLoadApiCall();
  }, []);

  useEffect(() => {
    const onSearchStringChangeEvent = async () => {
      const newItems: IMeetingSettings[] = await sessionStorage.getWithExpiry(
        "meetingSettings"
      );
      if (props.searchString) {
        const newArr = newItems.filter((value: IMeetingSettings) =>
          value.settingsName
            .toLowerCase()
            .includes(props.searchString.toLowerCase())
        );
        setMeetingSettings(newArr);
      } else {
        setMeetingSettings(newItems);
      }
    };
    onSearchStringChangeEvent();
  }, [props.searchString]);

  const callUpdateApi = (toggleValue: IMeetingSettings) => {
    try {
      apiService
        .updateMeetingSettings(toggleValue)
        .then((res: any) => {
          if (res === "success") {
            setShowSpinner(false);
          }
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at update meeting seetings API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at callUpdateApi, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const isSettingChangeEvent = (settings: IMeetingSettings) => {
    try {
      const nextState = !(settings.settingsValue === "true");
      const toggleValue: IMeetingSettings = {
        id: settings.id,
        settingsType: settings.settingsType,
        settingsName: settings.settingsName,
        settingsValue: nextState.toString(),
        isDeleted: true,
        uploadedTime: new Date().toString(),
        uploadedBy: settings.uploadedBy,
      };
      setShowSpinner(true);
      const meetingSettingsNew: IMeetingSettings[] = JSON.parse(
        JSON.stringify(meetingSettings)
      );
      meetingSettingsNew.filter(
        (value: IMeetingSettings) => value.id === settings.id
      )[0].settingsValue = nextState.toString();
      setMeetingSettings(meetingSettingsNew);
      sessionStorage.setWithExpiry("meetingSettings", meetingSettingsNew, 600);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `The MeetingSettings ${settings.settingsName} has been changed to ${
          nextState ? "Active" : "InActive"
        } state.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      callUpdateApi(toggleValue);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at isSettingChangeEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const setParticipantCount = (
    settings: IMeetingSettings,
    selectedValue: number
  ) => {
    try {
      const toggleValue: IMeetingSettings = {
        id: settings.id,
        settingsType: settings.settingsType,
        settingsName: settings.settingsName,
        settingsValue: selectedValue.toString(),
        isDeleted: true,
        uploadedTime: new Date().toString(),
        uploadedBy: settings.uploadedBy,
      };
      setShowSpinner(true);
      const meetingSettingsNew: IMeetingSettings[] = JSON.parse(
        JSON.stringify(meetingSettings)
      );
      meetingSettingsNew.filter(
        (value: IMeetingSettings) => value.id === settings.id
      )[0].settingsValue = selectedValue.toString();
      setMeetingSettings(meetingSettingsNew);
      sessionStorage.setWithExpiry("meetingSettings", meetingSettingsNew, 600);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `The participant count has been changed to ${selectedValue.toString()}.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      callUpdateApi(toggleValue);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at setParticipantCount, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Grid className="meetingSettings">
        <Alert severity="info" className={"infoAlert"}>
          {`Currently the "Meeting Settings" are not configurable`}
        </Alert>
        {isFetch && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}

        {!isFetch && meetingSettings && meetingSettings.length > 0 && (
          <TableContainer className={"meetingSettings_table_container"}>
            <Table
              className={`${classes.table} meetingSettings_table`}
              aria-label="simple table"
            >
              <TableHead className={"meetingSettingssHeader"}>
                <TableRow>
                  <TableCell
                    align={alignLeftText}
                    style={{ minWidth: 200 }}
                    className={`headerCell`}
                  >
                    {"Setting Type"}
                  </TableCell>
                  <TableCell align={alignLeftText} className={`headerCell`}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} className={`headerCell`}>
                    {""}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={"meetingSettingsBody"}>
                {meetingSettings.map((row: IMeetingSettings) => (
                  <TableRow
                    key={`${row.id}`}
                    id={row.id}
                    className={classes.root}
                  >
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell"
                      title={row.settingsName}
                    >
                      {row.settingsName}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell"
                      style={{ minWidth: 190 }}
                    >
                      <LightTooltip
                        title={ConstantVariables.RoleBasedAccessErrorMessage}
                        disableHoverListener={
                          globalStore.actionMenuSelectedUserRole ===
                          globalStore.fullControlGroup
                        }
                      >
                        <span>
                          {row.settingsType.toLowerCase() === "toggle" && (
                            <AntSwitch
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              checked={row.settingsValue === "true"}
                              name="checked"
                              onChange={() => isSettingChangeEvent(row)}
                            />
                          )}
                        </span>
                      </LightTooltip>
                      {row.settingsType.toLowerCase() === "dropdown" && (
                        <FormControl>
                          <LightTooltip
                            title={
                              ConstantVariables.RoleBasedAccessErrorMessage
                            }
                            disableHoverListener={
                              globalStore.actionMenuSelectedUserRole ===
                              globalStore.fullControlGroup
                            }
                          >
                            <span>
                              <Select
                                variant="outlined"
                                id="participantcount"
                                disabled={
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                }
                                style={{
                                  opacity:
                                    globalStore.actionMenuSelectedUserRole !==
                                    globalStore.fullControlGroup
                                      ? 0.75
                                      : 1,
                                }}
                                value={Number(row.settingsValue)}
                                onChange={(e) => {
                                  setParticipantCount(
                                    row,
                                    Number(e.target.value)
                                  );
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                                className={"participantcount"}
                              >
                                {rangeArr &&
                                  rangeArr.length > 0 &&
                                  rangeArr.map((val: number) => {
                                    return (
                                      <MenuItem value={val} key={val}>
                                        {val}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </span>
                          </LightTooltip>
                        </FormControl>
                      )}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell"
                      style={{ minWidth: 250 }}
                    >
                      {""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Grid>
    );
  });
}
