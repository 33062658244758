import * as React from "react";
import { Grid, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { ReactComponent as IndentationImg } from "../../assets/indentationIcon.svg";
import { useGlobalStore } from "../../state/GlobalStore";

interface ITextIndentProps {
  textIndent: string;
  headingName: string;
}

export default function Indentation(props: ITextIndentProps) {
  const globalStore = useGlobalStore();

  return (
    <Grid className={"searchBoxLetter"}>
      <TextField
        id="header-SearchBox"
        value={props.textIndent.substring(0, props.textIndent.length - 2)}
        onChange={(event) => {
          globalStore.setSearchText(
            `${event.target.value}px`,
            props.headingName,
            "textIndent"
          );
        }}
      />
      <IndentationImg />
    </Grid>
  );
}
