import * as React from "react";
import {
  Divider,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { observer, useObserver } from "mobx-react-lite";
import { useGlobalStore } from "../../state/GlobalStore";

const fontWeight = [
  "Light",
  "Semilight",
  "Regular",
  "Semibold",
  "Bold",
  "Black",
  "Italic",
  "Semibold Italic",
  "Bold Italic",
  "Semilight Italic",
];

interface IFontWeightDropdownProps {
  fontWeight: string;
  fontStyle: string;
  headingName: string;
}

export default function FontWeightDropdown(props: IFontWeightDropdownProps) {
  const globalStore = useGlobalStore();

  const getWeightAndStyle = (dropdownVal: string) => {
    const val = {
      fontWeight: "400",
      fontStyle: "normal",
    };
    switch (dropdownVal) {
      case "Light":
        val.fontWeight = "300";
        val.fontStyle = "normal";
        break;
      case "Semilight":
        val.fontWeight = "350";
        val.fontStyle = "normal";
        break;
      case "Regular":
        val.fontWeight = "400";
        val.fontStyle = "normal";
        break;
      case "Semibold":
        val.fontWeight = "600";
        val.fontStyle = "normal";
        break;
      case "Bold":
        val.fontWeight = "700";
        val.fontStyle = "normal";
        break;
      case "Black":
        val.fontWeight = "900";
        val.fontStyle = "normal";
        break;
      case "Italic":
        val.fontWeight = "400";
        val.fontStyle = "Italic";
        break;
      case "Semibold Italic":
        val.fontWeight = "600";
        val.fontStyle = "Italic";
        break;
      case "Bold Italic":
        val.fontWeight = "700";
        val.fontStyle = "Italic";
        break;
      case "Semilight Italic":
        val.fontWeight = "350";
        val.fontStyle = "Italic";
        break;

      default:
        return val;
    }
    return val;
  };

  const getDropdownValue = (fontWeightVal: string, fontStyleVal: string) => {
    if (fontWeightVal === "300" && fontStyleVal === "normal") {
      return "Light";
    }
    if (fontWeightVal === "400" && fontStyleVal === "normal") {
      return "Regular";
    }
    if (fontWeightVal === "350" && fontStyleVal === "normal") {
      return "Semilight";
    }
    if (fontWeightVal === "600" && fontStyleVal === "normal") {
      return "Semibold";
    }
    if (fontWeightVal === "700" && fontStyleVal === "normal") {
      return "Bold";
    }
    if (fontWeightVal === "900" && fontStyleVal === "normal") {
      return "Black";
    }
    if (fontWeightVal === "400" && fontStyleVal === "Italic") {
      return "Italic";
    }
    if (fontWeightVal === "600" && fontStyleVal === "Italic") {
      return "Semibold Italic";
    }
    if (fontWeightVal === "700" && fontStyleVal === "Italic") {
      return "Bold Italic";
    }
    if (fontWeightVal === "350" && fontStyleVal === "Italic") {
      return "Semilight Italic";
    }
    return "Light";
  };

  return (
    <FormControl
      size="small"
      style={{
        backgroundColor: "white",
        border: "1px solid #ECEBED",
        borderRadius: "6px",
        width: "161px",
      }}
    >
      <Select
        variant="outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        value={getDropdownValue(props.fontWeight, props.fontStyle)}
        inputProps={{ "aria-label": "Without label" }}
        className="dropdown"
      >
        {fontWeight.map((item) => (
          <MenuItem
            key={item}
            value={item}
            onClick={() => {
              const tempItem = getWeightAndStyle(item);

              globalStore.setSelectedFontWeight(
                tempItem.fontWeight,
                tempItem.fontStyle,
                props.headingName
              );
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
