/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import CircleIcon from "@mui/icons-material/Circle";
import CancelIcon from "@mui/icons-material/Cancel";

import circleCheck from "../../assets/circleCheck.svg";
import { ReactComponent as CancelIconSummary } from "../../assets/CancelIconSummary.svg";
import errorIcon from "../../assets/errorIcon.svg";
import { useGlobalStore } from "../../state/GlobalStore";

export default function VerticalStepper() {
  const globalStore = useGlobalStore();
  const Accordion = withStyles({
    root: {
      border: "1px solid #DDE3EC",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionError = withStyles({
    root: {
      border: "1px solid #EC5959",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      marginBottom: -1,
      minHeight: 56,
      "&$expanded": {
        minHeight: 56,
      },
    },
    content: {
      "&$expanded": {
        margin: "12px 0",
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      heading: {
        font: "normal normal 600 20px/27px Segoe UI",
        flexBasis: "9%",
        flexShrink: 0,
      },
      headingDisable: {
        font: "normal normal 600 20px/27px Segoe UI",
        flexBasis: "9%",
        flexShrink: 0,
        color: "#8A8A8A",
        opacity: 0.5,
      },
      errorHeading: {
        font: "normal normal 600 20px/27px Segoe UI",
        flexShrink: 0,
        color: "#EC5959",
      },
      secondaryHeadingDisable: {
        font: "normal normal 600 18px/24px Segoe UI",
        color: "#8A8A8A",
        opacity: 0.5,
        textTransform: "capitalize",
      },
      secondaryHeading: {
        font: "normal normal 600 18px/24px Segoe UI",
        color: "#000000",
      },
      color: {
        color: "green",
      },
      step: {
        height: "1rem",
        "&$completed": {
          color: "#18760A",
          borderColor: "#199D05",
        },

        "&$disabled": {
          color: "#696969",
          borderColor: "#8A8A8A",
        },
      },

      alternativeLabel: {},
      active: {}, // needed so that the &$active tag works
      completed: {},
      labelContainer: {},
      // disabled: {},
      actionsContainer: {
        marginBottom: theme.spacing(2),
      },
      resetContainer: {
        padding: theme.spacing(3),
      },
    })
  );

  const classes = useStyles();
  const [expanded, setExpanded] = useState("");

  const handleChange = (item: string) => {
    if (expanded === item) setExpanded("");
    if (expanded !== item) setExpanded(item);
  };

  function getTimeAMPM(Val) {
    const date = new Date(Val);
    let hours: string | number = date.getHours();
    let minutes: string | number = date.getMinutes();
    // Check whether AM or PM
    const newformat = hours >= 12 ? "PM" : "AM";
    // Find current hour in AM-PM Format
    hours %= 12;
    // To display "0" as "12"
    hours = hours !== 0 ? (hours < 10 ? `0${hours} ` : hours) : 12;
    minutes = minutes < 10 ? `0${minutes} ` : minutes; // single digit todo

    return `${hours}: ${minutes} ${newformat}`;
  }

  return useObserver(() => {
    const keys = Object.keys(globalStore.stepDetailsNew);
    return (
      <Grid style={{ width: "100%" }} className="verticalStepper">
        <Stepper orientation="vertical">
          {!Object.values(globalStore.stepDetailsNew).every(
            (o) => o === null
          ) === true &&
            Object.keys(globalStore.stepDetailsNew).map((key, index) =>
              globalStore.stepDetailsNew[key] !== null ? (
                globalStore.stepDetailsNew[key]?.status === "Success" ? (
                  <Step
                    key={globalStore.stepDetailsNew[key].state}
                    expanded
                    disabled={false}
                    completed
                    className={
                      keys[index] !==
                      Object.keys(globalStore.stepDetailsNew).pop()
                        ? "stepCompleted"
                        : "stepDisabled"
                    }
                  >
                    <StepLabel
                      classes={{
                        alternativeLabel: classes.alternativeLabel,
                        labelContainer: classes.labelContainer,
                      }}
                      StepIconProps={{
                        classes: {
                          root: classes.step,
                          completed: classes.completed,
                          active: classes.active,
                        },
                      }}
                    >
                      {""}
                    </StepLabel>
                    <StepContent>
                      <Accordion
                        expanded={
                          expanded === globalStore.stepDetailsNew[key].state
                        }
                        onChange={() =>
                          handleChange(globalStore.stepDetailsNew[key].state)
                        }
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1"
                          id={globalStore.stepDetailsNew[key].state}
                        >
                          <Typography className={classes.heading}>
                            {getTimeAMPM(
                              globalStore.stepDetailsNew[key].timeStamp
                            )}
                          </Typography>
                          <Typography className={classes.secondaryHeading}>
                            {globalStore.stepDetailsNew[key].message}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ paddingTop: "0px" }}>
                          {(key === "emailStatus" ||
                            key === "meetingLink" ||
                            key === "loginDetails") && (
                            <Typography
                              style={{
                                font: "normal normal normal 16px/21px Segoe UI",
                                paddingLeft: "116px",
                              }}
                            >
                              {globalStore.stepDetailsNew[key]?.description}
                            </Typography>
                          )}
                          {(key === "exitStatus" ||
                            key === "callStatus" ||
                            key === "deviceSettings") && (
                            <Grid>
                              {globalStore.stepDetailsNew[key]?.actions?.map(
                                (contentItem, indexTemp) => (
                                  <Grid
                                    style={{
                                      display: "flex",
                                      paddingLeft: "116px",
                                      marginTop: indexTemp === 0 ? "" : "17px",
                                    }}
                                    key={"contentItem.action"}
                                  >
                                    <img src={circleCheck} alt="circleCheck" />

                                    <Typography
                                      style={{
                                        font: "normal normal normal 16px/21px Segoe UI",
                                        paddingLeft: "36px",
                                      }}
                                    >
                                      {" "}
                                      {contentItem.action}
                                    </Typography>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          )}

                          {key === "lobbyPage" && (
                            <Grid style={{ columnCount: 3 }}>
                              {globalStore.stepDetailsNew[key]?.actions?.map(
                                (contentItem, contentIndex) => (
                                  <Grid
                                    style={{
                                      display: "flex",
                                      paddingLeft: "116px",
                                      marginTop:
                                        contentIndex === 0 ? "" : "17px",
                                    }}
                                    key={contentItem.action}
                                  >
                                    {contentItem.status !== "Success" ? (
                                      <Typography
                                        style={{
                                          font: "normal normal 600 18px/24px Segoe UI",
                                          color: "#18760A",
                                        }}
                                      >
                                        {" "}
                                        {contentItem.status}
                                      </Typography>
                                    ) : (
                                      <img
                                        src={circleCheck}
                                        alt="circleCheck"
                                      />
                                    )}

                                    <Typography
                                      style={{
                                        font: "normal normal normal 16px/21px Segoe UI",
                                        paddingLeft: "36px",
                                      }}
                                    >
                                      {" "}
                                      {contentItem.action}
                                    </Typography>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </StepContent>
                  </Step>
                ) : (
                  <Step
                    key={globalStore.stepDetailsNew[key].state}
                    expanded
                    className="stepNextError"
                  >
                    <StepLabel
                      classes={{
                        alternativeLabel: classes.alternativeLabel,
                        labelContainer: classes.labelContainer,
                      }}
                      StepIconProps={{
                        classes: {
                          root: classes.step,
                          completed: classes.completed,
                          active: classes.active,
                        },
                      }}
                      className="stepErrorLabel"
                      StepIconComponent={CancelIconSummary}
                    >
                      {""}
                    </StepLabel>
                    <StepContent>
                      <AccordionError
                        expanded={
                          expanded === globalStore.stepDetailsNew[key].state
                        }
                        style={{
                          background: "#EC59591A 0% 0% no-repeat padding-box",
                        }}
                        onChange={() =>
                          handleChange(globalStore.stepDetailsNew[key].state)
                        }
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1"
                          id={globalStore.stepDetailsNew[key].state}
                        >
                          <Typography className={classes.heading}>
                            {getTimeAMPM(
                              globalStore.stepDetailsNew[key].timeStamp
                            )}
                          </Typography>
                          <Typography className={classes.errorHeading}>
                            {globalStore.stepDetailsNew[key].message}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ paddingTop: "0px" }}>
                          {(key === "emailStatus" ||
                            key === "meetingLink" ||
                            key === "loginDetails") && (
                            <Typography
                              style={{
                                font: "normal normal normal 16px/21px Segoe UI",
                                paddingLeft: "116px",
                              }}
                            >
                              {globalStore.stepDetailsNew[key]?.description}
                            </Typography>
                          )}
                          {(key === "exitStatus" ||
                            key === "callStatus" ||
                            key === "deviceSettings") && (
                            <Grid>
                              {globalStore.stepDetailsNew[key]?.actions?.map(
                                (contentItem, indexTemp) => (
                                  <Grid
                                    style={{
                                      display: "flex",
                                      paddingLeft: "116px",
                                      marginTop: indexTemp === 0 ? "" : "17px",
                                    }}
                                    key={contentItem.action}
                                  >
                                    {contentItem.status === "Failed" ? (
                                      <>
                                        {" "}
                                        <img
                                          src={errorIcon}
                                          alt="circleError"
                                        />
                                        <Typography
                                          style={{
                                            font: "normal normal normal 16px/21px Segoe UI",
                                            paddingLeft: "36px",
                                            color: "#EC5959",
                                          }}
                                        >
                                          {" "}
                                          {contentItem.action}
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <img
                                          src={circleCheck}
                                          alt="circleCheck"
                                        />
                                        <Typography
                                          style={{
                                            font: "normal normal normal 16px/21px Segoe UI",
                                            paddingLeft: "36px",
                                          }}
                                        >
                                          {" "}
                                          {contentItem.action}
                                        </Typography>
                                      </>
                                    )}
                                  </Grid>
                                )
                              )}
                            </Grid>
                          )}

                          {key === "lobbyPage" && (
                            <Grid style={{ columnCount: 3 }}>
                              {globalStore.stepDetailsNew[key]?.actions?.map(
                                (contentItem, contentIndex) => (
                                  <Grid
                                    style={{
                                      display: "flex",
                                      paddingLeft: "116px",
                                      marginTop:
                                        contentIndex === 0 ? "" : "17px",
                                    }}
                                    key={contentItem.action}
                                  >
                                    {contentItem.action ===
                                    "No. of Participants" ? (
                                      <>
                                        <Typography
                                          style={{
                                            font: "normal normal 600 18px/24px Segoe UI",
                                            color: "#18760A",
                                          }}
                                        >
                                          {" "}
                                          {contentItem.status}
                                        </Typography>
                                        <Typography
                                          style={{
                                            font: "normal normal normal 16px/21px Segoe UI",
                                            paddingLeft: "36px",
                                          }}
                                        >
                                          {" "}
                                          {contentItem.action}
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {contentItem.status === "Failed" ? (
                                          <>
                                            {" "}
                                            <img
                                              src={errorIcon}
                                              alt="circleError"
                                            />
                                            <Typography
                                              style={{
                                                font: "normal normal normal 16px/21px Segoe UI",
                                                paddingLeft: "36px",
                                                color: "#EC5959",
                                              }}
                                            >
                                              {" "}
                                              {contentItem.action}
                                            </Typography>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <img
                                              src={circleCheck}
                                              alt="circleCheck"
                                            />
                                            <Typography
                                              style={{
                                                font: "normal normal normal 16px/21px Segoe UI",
                                                paddingLeft: "36px",
                                              }}
                                            >
                                              {" "}
                                              {contentItem.action}
                                            </Typography>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Grid>
                                )
                              )}
                            </Grid>
                          )}
                        </AccordionDetails>
                      </AccordionError>
                    </StepContent>
                  </Step>
                )
              ) : (
                <Step
                  key={globalStore.stepDetailsNew[key]}
                  expanded
                  disabled
                  completed={false}
                  className="stepDisabled"
                >
                  <StepLabel
                    classes={{
                      alternativeLabel: classes.alternativeLabel,
                      labelContainer: classes.labelContainer,
                    }}
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                      },
                    }}
                  >
                    {""}
                  </StepLabel>
                  <StepContent>
                    <Accordion
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1"
                        id={globalStore.stepDetailsNew[key]}
                      >
                        <Typography className={classes.headingDisable}>
                          {"00:00"}
                        </Typography>
                        <Typography className={classes.secondaryHeadingDisable}>
                          {key.replace(/[A-Z]/g, " $&").trim()}
                        </Typography>
                      </AccordionSummary>
                    </Accordion>
                  </StepContent>
                </Step>
              )
            )}
        </Stepper>
      </Grid>
    );
  });
}
