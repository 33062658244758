/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  CircularProgress,
  TextField,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import add from "../../assets/add.png";
import cancel from "../../assets/cancel.png";
import { IAddTestUser } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { AppInsightsConstants } from "../../Constants";

const useStyle = makeStyles({
  dialog: {
    width: 720,
    height: 610,
    maxWidth: 720,
  },
});

interface ITestUser {
  callBackFetchTestUserList: () => void;
  popup: boolean;
  closeCallback: () => void;
}

export default function AddTestUser(props: ITestUser) {
  const globalStore = useGlobalStore();

  const pageName = "Add Test User";
  const classes = useStyle();
  const helperAdd = helperMethods();
  const [testFirstUserN, setTestFirstUserN] = useState<string>("");
  const [testLastUserN, setTestLastUserN] = useState<string>("");
  const [testEmailN, setTestEmailN] = useState<string>("");
  const [testPhoneN, setTestPhoneN] = useState<string>("");
  const [showtestFirstUserNError, setShowtestFirstUserNError] = useState("");
  const [showtestLasttUserNError, setShowtestLastUserNError] = useState("");
  const [showtestEmailNError, setShowtestEmailError] = useState("");
  const [showtestPhoneNError, setShowtestPhoneNError] = useState("");
  // const [showError, setShowErrorMsg] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const onCancelClickEventHandler = () => {
    try {
      globalStore.setOpenedModalTestUser("none");
      setTestFirstUserN("");
      setTestLastUserN("");
      setTestEmailN("");
      setTestPhoneN("");
      setShowtestFirstUserNError("");
      setShowtestLastUserNError("");
      setShowtestEmailError("");
      setShowtestPhoneNError("");
      // setShowErrorMsg(false);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Add Test User button click event.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const validate = () => {
    try {
      let validationFailed = false;

      if (testFirstUserN === "" || testFirstUserN.trim().length === 0) {
        validationFailed = true;
        setShowtestFirstUserNError("Please enter First Name");
      } else {
        setShowtestFirstUserNError("");
      }
      if (testLastUserN === "" || testLastUserN.trim().length === 0) {
        validationFailed = true;
        setShowtestLastUserNError("Please enter Last Name");
      } else {
        setShowtestLastUserNError("");
      }
      if (testEmailN === "" || testEmailN.trim().length === 0) {
        validationFailed = true;
        setShowtestEmailError("Please enter Email Address");
      } else {
        setShowtestEmailError("");
      }
      if (testPhoneN === "" || testPhoneN.trim().length === 0) {
        validationFailed = true;
        setShowtestPhoneNError("Please enter Phone Number");
      } else {
        setShowtestPhoneNError("");
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Add Test User API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  const addTestUserAPICall = () => {
    try {
      let materialData = false;
      materialData = validate();
      if (materialData) {
        return;
      }
      const testUserObj: IAddTestUser = {
        firstName: testFirstUserN,
        lastName: testLastUserN,
        middleName: "",
        email: testEmailN,
        phone: testPhoneN,
      };
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Add Test User button click event. Remainder : ${testFirstUserN}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setShowSpinner(true);
      apiService
        .addTestUser(testUserObj)
        .then((res) => {
          if (res === "success") {
            helperAdd.setSuccessMessageEvent("Test User added successfully!");
          }
          onCancelClickEventHandler();
          props.closeCallback();
          globalStore.setOpenedModalTestUser("none");
          helperAdd.setSuccessMessageEvent("Test User added successfully!");
          props.callBackFetchTestUserList();
          setShowSpinner(false);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at add Test USer API call, Component: ${pageName} Error : ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at add Test User API, Component: ${pageName} Error : ${error}`
      );
    }
  };

  /* onBlur handler for testUser field */
  const onBlurTestUserFirstName = () => {
    try {
      if (testFirstUserN === "" && testFirstUserN.trim().length === 0) {
        setShowtestFirstUserNError("Please enter First Name");
      } else {
        setShowtestFirstUserNError("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurTestUserFirstName, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const onBlurTestUserLastName = () => {
    try {
      if (testLastUserN === "" && testLastUserN.trim().length === 0) {
        setShowtestLastUserNError("Please enter Last Name");
      } else {
        setShowtestLastUserNError("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurTestUserFirstName, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const onBlurTestUserEmail = () => {
    try {
      if (testEmailN === "" && testEmailN.trim().length === 0) {
        setShowtestEmailError("Please enter Email Address");
      } else {
        setShowtestEmailError("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurTestUserFirstName, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const onBlurTestUserPhone = () => {
    try {
      if (testPhoneN === "" && testPhoneN.trim().length === 0) {
        setShowtestPhoneNError("Please enter Phone Number");
      } else {
        setShowtestPhoneNError("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurTestUserFirstName, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Dialog
        onClose={() => globalStore.setOpenedModalTestUser("none")}
        open={props.popup}
        classes={{ paper: classes.dialog }}
        className="testUserContainer"
      >
        <DialogTitle className="testUserTitle">
          <b style={{ marginRight: 485 }}>Add Test User</b>
          <IconButton
            onClick={() => {
              onCancelClickEventHandler();
              props.closeCallback();
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className="testUserContent">
          <span className="helpertextColor">
            Fill the below details to add the test user with Oltiva services.
          </span>
          <Grid className={"testUser-text"}>
            First Name
            <span className={"material-title"}>*</span>
          </Grid>
          <Grid className={"relationText-box"}>
            <TextField
              value={testFirstUserN}
              fullWidth
              variant="outlined"
              placeholder="Enter first name"
              required
              onChange={(e) => {
                setTestFirstUserN(e.target.value);
              }}
              error={!!showtestFirstUserNError}
              onBlur={() => onBlurTestUserFirstName()}
            />
          </Grid>
          <div className={"material-error"}>
            {showtestFirstUserNError || ""}
          </div>
          <Grid className={"testUser-text"}>
            Last Name
            <span className={"material-title"}>*</span>
          </Grid>
          <Grid className={"relationText-box"}>
            <TextField
              value={testLastUserN}
              fullWidth
              variant="outlined"
              placeholder="Enter last name"
              required
              onChange={(e) => {
                setTestLastUserN(e.target.value);
              }}
              error={!!showtestLasttUserNError}
              onBlur={() => onBlurTestUserLastName()}
            />
          </Grid>
          <div className={"material-error"}>
            {showtestLasttUserNError || ""}
          </div>
          <Grid className={"testUser-text"}>
            Email Address
            <span className={"material-title"}>*</span>
          </Grid>
          <Grid className={"relationText-box"}>
            <TextField
              value={testEmailN}
              fullWidth
              variant="outlined"
              placeholder="Enter email address"
              required
              onChange={(e) => {
                setTestEmailN(e.target.value);
              }}
              error={!!showtestEmailNError}
              onBlur={() => onBlurTestUserEmail()}
            />
          </Grid>
          <div className={"material-error"}>{showtestEmailNError || ""}</div>
          <Grid className={"testUser-text"}>
            Phone Number
            <span className={"material-title"}>*</span>
          </Grid>
          <Grid className={"relationText-box"}>
            <TextField
              value={testPhoneN}
              fullWidth
              variant="outlined"
              placeholder="Enter phone number"
              required
              onChange={(e) => {
                setTestPhoneN(e.target.value);
              }}
              error={!!showtestPhoneNError}
              onBlur={() => onBlurTestUserPhone()}
            />
          </Grid>
          <div className={"material-error"}>{showtestPhoneNError || ""}</div>
        </DialogContent>
        <Grid>
          <DialogActions className="btnClick">
            <Button
              onClick={() => {
                onCancelClickEventHandler();
                props.closeCallback();
              }}
            >
              <img
                src={cancel}
                alt="cancel"
                className="logo pointer cursor"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                tabIndex={0}
              />
            </Button>
            <Button onClick={addTestUserAPICall}>
              <img
                src={add}
                alt="save"
                className="logo pointer cursor"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                tabIndex={0}
              />
            </Button>
          </DialogActions>
          {showSpinner && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 999,
              }}
              open={showSpinner}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      </Dialog>
    );
  });
}
