/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import {
  useTrackMetric,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import Resources from "../Resources/Resources";
import Theme from "../Theme/Theme";
import Fonts from "../Fonts/Fonts";
import { useGlobalStore } from "../../state/GlobalStore";
import Layout from "../Layout/Layout";
import LogoMain from "../Logo/LogoMain";
import { SnackBar } from "./SnackBar";

interface IDashboardContainerContentProps {
  tabName: string;
}

export default function CustomContainerContent(
  props: IDashboardContainerContentProps
) {
  const pageName = "customContainerContent";
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    return (
      <Grid className={"customContainerContent"}>
        {props.tabName === "Theme" && <Theme />}
        {props.tabName === "Font" && <Fonts />}
        {props.tabName === "Logo" && <LogoMain />}
        {props.tabName === "Layout" && <Layout />}
      </Grid>
    );
  });
}
