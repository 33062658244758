/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import add from "../../assets/add.png";
import cancel from "../../assets/cancel.png";
import { IAddConfigurationMsg } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";

const useStyle = makeStyles({
  dialog: {
    width: 523,
    height: 551,
    maxWidth: 720,
  },
});

interface IMessageButton {
  callBackGetMsgList: () => void;
  popup: boolean;
  closeCallback: () => void;
}

export default function AddLobbyMessage(props: IMessageButton) {
  const globalStore = useGlobalStore();
  const pageName = "Add Lobby Message";
  const classes = useStyle();
  const helperAdd = helperMethods();
  const [messageIn, setMessage] = useState<string>("");
  const [messageTypeIn, setMessageType] = useState<string>("");
  const [showMsgErrorMgs, setShowMsgErrorMgs] = useState("");
  const [showMsgTypeErrorMgs, setShowMsgTypeErrorMgs] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const onCancelClickEventHandler = () => {
    try {
      globalStore.setOpenedModalMsg("none");
      setMessage("");
      setMessageType("");
      setShowMsgErrorMgs("");
      setShowMsgTypeErrorMgs("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const validate = () => {
    try {
      let validationFailed = false;

      if (messageIn === "" || messageIn.trim().length === 0) {
        validationFailed = true;
        setShowMsgErrorMgs("Please enter Message");
      } else {
        setShowMsgErrorMgs("");
      }
      if (messageTypeIn === "" || messageTypeIn.trim().length === 0) {
        validationFailed = true;
        setShowMsgTypeErrorMgs("Please enter Message Type");
      } else {
        setShowMsgTypeErrorMgs("");
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Add configuration API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  const updateField = () => {
    try {
      setMessage("");
      setMessageType("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at updateField, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const addMessage = () => {
    try {
      let materialData = false;
      materialData = validate();
      if (materialData) {
        return;
      }
      const msgObj: IAddConfigurationMsg = {
        id: "",
        message: messageIn,
        messageType: messageTypeIn,
        createdBy: "publicTester3",
        createdTime: "",
        type: "",
      };
      setShowSpinner(true);
      apiService
        .postConfigurationMsg(msgObj)
        .then((res) => {
          if (res === "success") {
            helperAdd.setSuccessMessageEvent("Message added successfully!");
          }
          props.closeCallback();
          updateField();
          globalStore.setOpenedModalMsg("none");
          helperAdd.setSuccessMessageEvent("Message added successfully!");
          props.callBackGetMsgList();
          setShowSpinner(false);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at Configuration Msg API call, Component: ${pageName} Error : ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Configuration Msg API, Component: ${pageName} Error : ${error}`
      );
    }
  };

  /* onBlur handler for Message field */
  const onBlurMsg = () => {
    try {
      if (messageIn === "" && messageIn.trim().length === 0) {
        setShowMsgErrorMgs("Please enter Message");
      } else {
        setShowMsgErrorMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurMsg, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* onBlur handler for Message Type field */
  const onBlurMsgType = () => {
    try {
      if (messageTypeIn === "" && messageTypeIn.trim().length === 0) {
        setShowMsgTypeErrorMgs("Please enter Message Type");
      } else {
        setShowMsgTypeErrorMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurMsgType, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <div>
        <Dialog
          onClose={() => globalStore.setOpenedModalMsg("none")}
          open={props.popup}
          classes={{ paper: classes.dialog }}
          className="msgContainer"
        >
          <DialogTitle className="msgTitle">
            <b style={{ marginRight: 224 }}>Add Lobby Message</b>
            <IconButton
              onClick={() => {
                onCancelClickEventHandler();
                props.closeCallback();
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="msgContent">
            <Grid className={"msg-text"}>
              Message Type
              <span className={"material-title"}>*</span>
            </Grid>
            <Grid className={"msgText-box"}>
              <TextField
                value={messageTypeIn}
                fullWidth
                variant="outlined"
                placeholder="Message Type"
                required
                id="messageType"
                onChange={(e) => {
                  setMessageType(e.target.value);
                }}
                error={!!showMsgTypeErrorMgs}
                onBlur={() => onBlurMsgType()}
              />
            </Grid>
            <div className={"material-error"}>{showMsgTypeErrorMgs || ""}</div>
            <Grid className={"msg-text"}>
              Message Description
              <span className={"material-title"}>*</span>
            </Grid>
            <Grid className={"msgText-box2"}>
              <TextField
                value={messageIn}
                fullWidth
                multiline
                rows={8}
                variant="outlined"
                placeholder="Type Message Description"
                required
                id="message"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                error={!!showMsgErrorMgs}
                onBlur={() => onBlurMsg()}
              />
            </Grid>
            <div className={"material-error"}>{showMsgErrorMgs || ""}</div>
          </DialogContent>
          <Grid>
            <DialogActions className="btnClick">
              <Button
                onClick={() => {
                  onCancelClickEventHandler();
                  props.closeCallback();
                }}
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button onClick={addMessage}>
                <img
                  src={add}
                  alt="save"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
            {showSpinner && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 999,
                }}
                open={showSpinner}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Grid>
        </Dialog>
      </div>
    );
  });
}
