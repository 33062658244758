/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  CircularProgress,
  TextField,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import save from "../../assets/save.png";
import cancel from "../../assets/cancel.png";
import { IEditRelation } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { AppInsightsConstants } from "../../Constants";

const useStyle = makeStyles({
  dialog: {
    width: 720,
    height: 547,
    maxWidth: 720,
  },
});

interface IRelationButton {
  callBackGetRelatnList: () => void;
  popup: boolean;
  closeCallback: () => void;
}

export default function EditRelationshipSettings(props: IRelationButton) {
  const globalStore = useGlobalStore();
  const pageName = "Edit Relationship";
  const classes = useStyle();
  const helperAdd = helperMethods();
  const [relationship, setRelationship] = useState<string>("");

  const [showRelationErrorMgs, setShowRelationTypeErrorMgs] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (globalStore.actionMenuSelectedRelationship) {
      setRelationship(globalStore.actionMenuSelectedRelationship.relation);
    }
  }, [globalStore.actionMenuSelectedRelationship]);

  const onCancelClickEventHandler = () => {
    try {
      globalStore.setOpenedModalRelation("none");
      setRelationship("");
      setShowRelationTypeErrorMgs("");
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Edit Relationship settings close/cancel button click event.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const validate = () => {
    try {
      let validationFailed = false;

      if (relationship === "" || relationship.trim().length === 0) {
        validationFailed = true;
        setShowRelationTypeErrorMgs("Please enter Relationship");
      } else {
        setShowRelationTypeErrorMgs("");
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Add Relationship API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  const updateField = () => {
    try {
      setRelationship("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at updateField, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const EditRelationship = () => {
    try {
      let materialData = false;
      materialData = validate();
      if (materialData) {
        return;
      }
      const relationObj: IEditRelation = {
        id: globalStore.actionMenuSelectedRelationship?.id,
        relation: relationship,
      };
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Edit Relationship settings, changed Name: ${relationObj.relation}, Id: ${relationObj.id}.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setShowSpinner(true);
      apiService
        .editRelationshipSettings(relationObj)
        .then((res) => {
          if (res === "success") {
            helperAdd.setSuccessMessageEvent(
              "Relationship updated successfully!"
            );
          }
          updateField();
          props.closeCallback();
          globalStore.setOpenedModalRelation("none");
          helperAdd.setSuccessMessageEvent(
            "Relationship updated successfully!"
          );
          props.callBackGetRelatnList();
          setShowSpinner(false);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at edit Relationship API call, Component: ${pageName} Error : ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at edit Relationship API, Component: ${pageName} Error : ${error}`
      );
    }
  };

  /* onBlur handler for Message Type field */
  const onBlurrelationType = () => {
    try {
      if (relationship === "" && relationship.trim().length === 0) {
        setShowRelationTypeErrorMgs("Please enter Relationship");
      } else {
        setShowRelationTypeErrorMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurrelationType, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <div>
        <Dialog
          onClose={() => globalStore.setOpenedModalRelation("none")}
          open={props.popup}
          classes={{ paper: classes.dialog }}
          className="relationContainer"
        >
          <DialogTitle className="relationTitle">
            <b style={{ marginRight: 452 }}>Edit Relationship</b>
            <IconButton
              onClick={() => {
                onCancelClickEventHandler();
                props.closeCallback();
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="relationContent">
            <Grid className={"relation-text"}>
              Relationship
              <span className={"material-title"}>*</span>
            </Grid>
            <Grid className={"relationText-box"}>
              <TextField
                value={relationship}
                fullWidth
                variant="outlined"
                placeholder="Enter Relationship"
                required
                id="messageType"
                onChange={(e) => {
                  setRelationship(e.target.value);
                }}
                error={!!showRelationErrorMgs}
                onBlur={() => onBlurrelationType()}
              />
            </Grid>
            <div className={"material-error"}>{showRelationErrorMgs || ""}</div>
          </DialogContent>
          <Grid>
            <DialogActions className="btnClick">
              <Button
                onClick={() => {
                  onCancelClickEventHandler();
                  props.closeCallback();
                }}
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button onClick={EditRelationship}>
                <img
                  src={save}
                  alt="save"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
            {showSpinner && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 999,
                }}
                open={showSpinner}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Grid>
        </Dialog>
      </div>
    );
  });
}
