import { ConstantVariables } from "../../Constants";

export const dashboardUtils = {
  /* Method for getting background color base on meeting status */
  getBackGroundColor: (status: string) => {
    try {
      let statusColor;
      switch (status) {
        case ConstantVariables.Status.inProgress:
          statusColor = "inProgress";
          break;
        case ConstantVariables.Status.waiting:
          statusColor = "waiting";
          break;
        case ConstantVariables.Status.delayed:
          statusColor = "delayed";
          break;
        case ConstantVariables.Status.completed:
          statusColor = "completed";
          break;
        case ConstantVariables.Status.scheduled:
        default:
          statusColor = "scheduled";
          break;
      }
      return statusColor;
    } catch (error) {
      return "#DCDCDC";
    }
  },
};
