/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import moment from "moment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useGlobalStore } from "../../state/GlobalStore";
import cancel from "../../assets/cancel.png";
import Edit from "../../assets/Edit.png";
import { ReactComponent as File } from "../../assets/File.svg";

const useStyles = makeStyles({
  paperRoot: {
    top: "72%",
    width: "200px",
    height: "119px",
    position: "absolute",
    marginLeft: "29.5rem",
  },
  dialog: {
    width: "720px",
    height: "778px",
    maxWidth: "720px",
  },
  dropZone: {
    width: "95%",
    border: "dashed",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    marginLeft: "13px",
    marginTop: "16px",
    marginBottom: "13px",
    boxSizing: "border-box",
    minHeight: "184px",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
});

interface IVideoButton {
  popup: boolean;
  closeCallback: () => void;
  popupE: () => void;
}

const ViewVideo = (props: IVideoButton) => {
  const globalStore = useGlobalStore();
  const pageName = "ViewVideo";
  const appInsights = useAppInsightsContext();
  const classes = useStyles();

  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  const onCancelClickEventHandler = () => {
    props.closeCallback();
  };

  return useObserver(() => {
    return (
      <Dialog
        open={props.popup}
        onClose={onCancelClickEventHandler}
        classes={{ paper: classes.dialog }}
      >
        <Grid className={"viewVideoContainer"}>
          <Grid>
            <DialogTitle className="viewVideoTitle">
              <b>{globalStore.actionMenuSelectedVideo?.title}</b>
              <IconButton
                onClick={() => {
                  onCancelClickEventHandler();
                  props.closeCallback();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </DialogTitle>
            <Grid className="viewVideo">
              Modified&nbsp;
              {globalStore.actionMenuSelectedVideo?.createdTime && (
                <>
                  <span>
                    {moment(
                      new Date(globalStore.actionMenuSelectedVideo?.createdTime)
                    ).format("ll")}
                    {" at "}
                    {moment(
                      new Date(globalStore.actionMenuSelectedVideo?.createdTime)
                    ).format("LT")}
                    {" | "}
                  </span>
                  {globalStore.actionMenuSelectedVideo?.isActive === true ? (
                    <span className="primaryColor">Active </span>
                  ) : (
                    <span className="primaryColorGray">Inactive</span>
                  )}
                </>
              )}
            </Grid>
            <Grid style={{ marginTop: "40px", textAlign: "center" }}>
              <video width="455px" height="227px" controls>
                <source
                  src={globalStore.actionMenuSelectedVideo?.fileUrl}
                  type="video/mp4"
                />
              </video>
            </Grid>
            <Grid className="viewDescription">
              {globalStore.actionMenuSelectedVideo?.description}
            </Grid>
          </Grid>
          <Grid>
            <DialogActions className="btnClick">
              <Button onClick={() => onCancelClickEventHandler()}>
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button onClick={() => props.popupE()}>
                <img
                  src={Edit}
                  alt="save"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    );
  });
};
export default ViewVideo;
