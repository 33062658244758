/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useState } from "react";
import { useObserver } from "mobx-react-lite";
import Button from "@mui/material/Button";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { Grid, IconButton, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DialogTitle from "@mui/material/DialogTitle";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import cancel from "../../assets/cancel.png";
import Edit from "../../assets/Edit.png";
import { ReactComponent as File } from "../../assets/File.svg";
import materials_document from "../../assets/materials_document.png";

const useStyle = makeStyles({
  dialog: {
    width: "720px",
    height: "778px",
    maxWidth: "720px",
  },
});

interface IMaterialButton {
  popup: boolean;
  popupE: () => void;
  closeCallback: () => void;
}

const ViewMaterial = (props: IMaterialButton) => {
  const globalStore = useGlobalStore();
  const pageName = "ViewMaterial";
  const [showSpinner, setShowSpinner] = useState(true);
  const classes = useStyle();

  const onCancelClickEventHandler = () => {
    try {
      globalStore.setOpenedModal("none");
      props.closeCallback();
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Dialog
        onClose={() => globalStore.setOpenedModal("none")}
        open={props.popup}
        classes={{ paper: classes.dialog }}
        className="viewMaterialitem"
      >
        <Grid className={"viewMaterialContainer"}>
          <Grid>
            <DialogTitle className="viewMaterialTitle">
              <b>{globalStore.actionMenuSelectedMaterial?.title}</b>
              <IconButton onClick={() => onCancelClickEventHandler()}>
                <CloseRoundedIcon />
              </IconButton>
            </DialogTitle>
            <Grid className="viewMaterial">
              Modified&nbsp;
              {globalStore.actionMenuSelectedMaterial?.createdTime && (
                <>
                  <span>
                    {moment(
                      new Date(
                        globalStore.actionMenuSelectedMaterial?.createdTime
                      )
                    ).format("ll")}
                    {" at "}
                    {moment(
                      new Date(
                        globalStore.actionMenuSelectedMaterial?.createdTime
                      )
                    ).format("LT")}
                    {" | "}
                  </span>

                  {globalStore.actionMenuSelectedMaterial?.isActive === true ? (
                    <span className="primaryColor">Active </span>
                  ) : (
                    <span className="primaryColorGray">Inactive</span>
                  )}
                </>
              )}
            </Grid>
            <Grid className="viewDescription">
              <img
                src={
                  globalStore.actionMenuSelectedMaterial?.thumbnailFileName ===
                    "" ||
                  globalStore.actionMenuSelectedMaterial?.thumbnailFileName ===
                    null
                    ? materials_document
                    : globalStore.actionMenuSelectedMaterial?.thumbnailUrl
                }
                alt="img"
                onLoad={() => setShowSpinner(false)}
              />
              {showSpinner && (
                <CircularProgress style={{ marginRight: "34rem" }} />
              )}
            </Grid>
            <Grid className="viewDescription">
              {globalStore.actionMenuSelectedMaterial?.description}
            </Grid>
            <Grid>
              <a
                href={globalStore.actionMenuSelectedMaterial?.url}
                download={globalStore.actionMenuSelectedMaterial?.fileName}
              >
                <File className="UploadfileName" />
                <span>{globalStore.actionMenuSelectedMaterial?.fileName}</span>
              </a>
            </Grid>
          </Grid>
          <Grid>
            <DialogActions className="btnClick">
              <Button onClick={() => onCancelClickEventHandler()}>
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button onClick={() => props.popupE()}>
                <img
                  src={Edit}
                  alt="save"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    );
  });
};
export default ViewMaterial;
