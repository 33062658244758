/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-param-reassign: "error" */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Typography,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import { useObserver } from "mobx-react-lite";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import { sessionStorage } from "../../state/SessionStorage";
import { ReactComponent as DeleteIcon } from "../../assets/DeleteIcon.svg";
import { apiService } from "../../services/ApiService";
import { ITestAppointmentList } from "../../state/StoreTypes";
import Pagination from "../Pagination/Pagination";
import AddTestAppointment from "./AddTestAppointment";
import { LightTooltip } from "../Relationship/RelationshipList";
import DelTestAppointment from "./DelTestAppointment";
import { NoUsers } from "../Resources/Resources";
import DeleteMulTestAppointments from "./DeleteMultipleAppointments";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

export default function TestAppointmentList() {
  const pageName = "Test Appointment List";
  const alignLeftText = "left";
  const globalStore = useGlobalStore();
  const [showSpinner, setShowSpinner] = useState(false);
  const itemsPerPageCount = 7;
  const [startIndex, setStartIndex] = useState(0);
  const [popupT, setPopup] = useState(false);
  const [endIndex, setEndIndex] = useState(itemsPerPageCount);
  const [popupDel, setPopupDel] = useState(false);
  const [delPopUp, setDelPopUp] = useState(false);
  const [testAppointmentListObj, setTestAppointmentListObj] = useState<
    ITestAppointmentList[]
  >([]);
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    setSelectedDate({
      ...selectedDate,
      startDate: new Date(),
      endDate: new Date(),
    });
  }, []);

  useEffect(() => {
    setShowSpinner(true);
    apiService
      .fetchTestAppointment(
        getStartDate(selectedDate.startDate),
        getEndDate(selectedDate.endDate)
      )
      .then((data: ITestAppointmentList[]) => {
        setShowSpinner(false);
        setTestAppointmentListObj(data);
        globalStore.setActionMenuSelectedTestAppointmentObj(data);
        globalStore.setActionMenuSelectedTestAppointmentObj(
          testAppointmentListObj
        );
        sessionStorage.setWithExpiry("testAppointmentList", data, 600);
      })
      .catch((error: any) => {
        setShowSpinner(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at fetchTestAppointmentList API call, fetchTestAppointmentList, Component: ${pageName}, Error: ${error}`
        );
      });
  }, [globalStore.actionMenuSelectedTestAppointment?.televisitId]);

  const getStartDate = (date: Date) => {
    return moment(new Date(date.setHours(0, 0, 0, 0))).format();
  };

  const getEndDate = (date: Date) => {
    return moment(new Date(date.setHours(23, 59, 59, 0))).format();
  };

  const fetchTestAppointmentList = () => {
    try {
      setShowSpinner(true);
      apiService
        .fetchTestAppointment(
          getStartDate(selectedDate.startDate),
          getEndDate(selectedDate.endDate)
        )
        .then((data: ITestAppointmentList[]) => {
          setShowSpinner(false);
          setTestAppointmentListObj(data);

          sessionStorage.setWithExpiry("testAppointmentList", data, 600);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at fetchTestAppointmentList API call, fetchTestAppointmentList, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at fetchTestAppointmentList, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const closeCallbackHandler = () => {
    try {
      setPopup(false);
      setPopupDel(false);
      setDelPopUp(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const deleteCustomerByIds = () => {
    const arrayids: string[] = [];
    testAppointmentListObj.forEach((d) => {
      if (d.isChecked) {
        arrayids.push(d.televisitId);
      }
    });
    if (arrayids.length > 0) {
      setDelPopUp(true);
    }
  };

  useEffect(() => {
    const pageLoadApiCall = async () => {
      const testappoint = await sessionStorage.getWithExpiry(
        "testAppointmentList"
      );
      if (testappoint) {
        setTestAppointmentListObj(testappoint);
      } else {
        fetchTestAppointmentList();
      }
    };
    pageLoadApiCall();
  }, []);

  return useObserver(() => {
    return (
      <Grid className="testAppointment-container">
        <Grid className="testAppointment">
          <Grid className={"testAppointment-left"}>{`Total Test Appointments ${
            testAppointmentListObj.length > 0
              ? `(${testAppointmentListObj.length})`
              : ""
          }`}</Grid>
          <Grid className="deleteIconTest">
            {testAppointmentListObj.length > 0 ? (
              <DeleteIcon
                onClick={() => {
                  deleteCustomerByIds();
                  globalStore.setActionMenuSelectedTestAppointmentObj(
                    testAppointmentListObj
                  );
                }}
              />
            ) : (
              ""
            )}
          </Grid>
          <LightTooltip
            title={ConstantVariables.RoleBasedAccessErrorMessage}
            disableHoverListener={
              globalStore.actionMenuSelectedUserRole ===
              globalStore.fullControlGroup
            }
          >
            <span>
              <Button
                variant="contained"
                disabled={
                  globalStore.actionMenuSelectedUserRole !==
                  globalStore.fullControlGroup
                }
                style={{
                  opacity:
                    globalStore.actionMenuSelectedUserRole !==
                    globalStore.fullControlGroup
                      ? 0.5
                      : 1,
                }}
                className={"newMaterialBtnAppoint"}
                startIcon={<AddIcon />}
                onClick={() => {
                  setPopup(true);
                  AppInsightMethods.trackAppInsightsEvent(
                    pageName,
                    AppInsightsConstants.Information,
                    `The "Test Appointment" button has been clicked.`,
                    globalStore.loginUserEmail,
                    window.location.href,
                    ""
                  );
                }}
              >
                Test Appointment
              </Button>
            </span>
          </LightTooltip>
        </Grid>
        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}

        {!showSpinner &&
          testAppointmentListObj &&
          testAppointmentListObj.length > 0 && (
            <TableContainer className={"testAppointmentList_table_container"}>
              <Table
                className={`${classes.table} testAppointmentList_table`}
                aria-label="simple table"
              >
                <TableHead className={"testAppointmentListHeader"}>
                  <TableRow>
                    <TableCell>
                      <input
                        type="checkbox"
                        disabled={
                          globalStore.actionMenuSelectedUserRole !==
                          globalStore.fullControlGroup
                        }
                        style={{
                          opacity:
                            globalStore.actionMenuSelectedUserRole !==
                            globalStore.fullControlGroup
                              ? 0.5
                              : 1,
                        }}
                        checked={
                          !testAppointmentListObj.some(
                            (user) => user?.isChecked !== true
                          )
                        }
                        onChange={(e) => {
                          const value = e.target.checked;
                          setTestAppointmentListObj(
                            testAppointmentListObj.map((d) => {
                              d.isChecked = value;
                              return d;
                            })
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={`headerCell `}
                      tabIndex={0}
                    >
                      {"Test User Name"}
                    </TableCell>

                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 250 }}
                      className={"headerCell"}
                    >
                      {"Test Provider Name"}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 150 }}
                      className={"headerCell"}
                    >
                      {"Appointment Date"}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 210 }}
                      className={"headerCell"}
                    >
                      {"Appointment Time"}
                    </TableCell>

                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={"headerCell"}
                    >
                      {"Appointment Duration"}
                    </TableCell>

                    <TableCell align={alignLeftText} tabIndex={0}>
                      {""}
                    </TableCell>
                    <TableCell align={alignLeftText} tabIndex={0}>
                      {""}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={"testAppointmentListBody"}>
                  {testAppointmentListObj
                    ?.slice(startIndex, endIndex)
                    .map((row: ITestAppointmentList) => (
                      <TableRow key={`${row.id}`} id={row.id}>
                        <TableCell>
                          <input
                            type="checkbox"
                            disabled={
                              globalStore.actionMenuSelectedUserRole !==
                              globalStore.fullControlGroup
                            }
                            style={{
                              opacity:
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                                  ? 0.5
                                  : 1,
                            }}
                            checked={row.isChecked}
                            onChange={(e) => {
                              const value = e.target.checked;

                              setTestAppointmentListObj(
                                testAppointmentListObj.map((sd) => {
                                  if (sd.id === row.id) {
                                    sd.isChecked = value;
                                  }
                                  return sd;
                                })
                              );
                            }}
                          />
                        </TableCell>

                        <TableCell
                          align={alignLeftText}
                          tabIndex={0}
                          className="bodyCell titleCell overflowHidden"
                        >
                          {`${row.patientFirstName} ${row.patientLastName}`}
                        </TableCell>
                        <TableCell
                          align={alignLeftText}
                          tabIndex={0}
                          className="bodyCell  overflowHidden"
                        >
                          {`${row.providerRequest[0].title}${"."} ${
                            row.providerRequest[0].firstName
                          } ${row.providerRequest[0].lastName}`}
                        </TableCell>
                        <TableCell
                          align={alignLeftText}
                          tabIndex={0}
                          className="bodyCell  overflowHidden"
                        >
                          {moment(new Date(row.scheduleDateTimeTZD)).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell
                          align={alignLeftText}
                          tabIndex={0}
                          className="bodyCell  overflowHidden"
                        >
                          {moment(new Date(row.scheduleDateTimeTZD)).format(
                            "LT"
                          )}
                        </TableCell>
                        <TableCell
                          align={alignLeftText}
                          tabIndex={0}
                          className="bodyCell  overflowHidden"
                        >
                          {`${row.duration} ${"Min"}`}
                        </TableCell>

                        <TableCell>
                          <LightTooltip
                            title={
                              ConstantVariables.RoleBasedAccessErrorMessage
                            }
                            disableHoverListener={
                              globalStore.actionMenuSelectedUserRole ===
                              globalStore.fullControlGroup
                            }
                          >
                            <span>
                              <Button
                                variant="text"
                                className="editnDel"
                                disabled={
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                }
                                style={{
                                  opacity:
                                    globalStore.actionMenuSelectedUserRole !==
                                    globalStore.fullControlGroup
                                      ? 0.5
                                      : 1,
                                }}
                                onClick={() => {
                                  window.open(row.teamsMeetingUrl, "_blank");
                                  AppInsightMethods.trackAppInsightsEvent(
                                    pageName,
                                    AppInsightsConstants.Information,
                                    `Join button has been clicked. `,
                                    globalStore.loginUserEmail,
                                    window.location.href,
                                    ""
                                  );
                                }}
                              >
                                <Typography style={{ textTransform: "none" }}>
                                  {"Join"}
                                </Typography>
                              </Button>
                            </span>
                          </LightTooltip>
                        </TableCell>
                        <TableCell>
                          <LightTooltip
                            title={
                              ConstantVariables.RoleBasedAccessErrorMessage
                            }
                            disableHoverListener={
                              globalStore.actionMenuSelectedUserRole ===
                              globalStore.fullControlGroup
                            }
                          >
                            <span>
                              <Button
                                variant="text"
                                className="editnDel"
                                disabled={
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                }
                                style={{
                                  opacity:
                                    globalStore.actionMenuSelectedUserRole !==
                                    globalStore.fullControlGroup
                                      ? 0.5
                                      : 1,
                                }}
                                onClick={() => {
                                  globalStore.setActionMenuSelectedTestAppointment(
                                    row
                                  );

                                  setPopupDel(true);
                                  AppInsightMethods.trackAppInsightsEvent(
                                    pageName,
                                    AppInsightsConstants.Information,
                                    `Delete Test Appointment button has been clicked. Test User : ${row.patientFirstName}, id: ${row.id}`,
                                    globalStore.loginUserEmail,
                                    window.location.href,
                                    ""
                                  );
                                }}
                              >
                                <Typography style={{ textTransform: "none" }}>
                                  {"Delete"}
                                </Typography>
                              </Button>
                            </span>
                          </LightTooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        {!showSpinner && testAppointmentListObj.length === 0 && <NoUsers />}
        {testAppointmentListObj && testAppointmentListObj.length > 7 && (
          <Pagination
            itemCount={testAppointmentListObj.length}
            itemsPerPage={itemsPerPageCount}
            paginationCallback={(startInd: number, endInd: number) => {
              setStartIndex(startInd);
              setEndIndex(endInd);
            }}
          />
        )}
        <AddTestAppointment
          callBackFetchTestAppointmentList={fetchTestAppointmentList}
          popup={popupT}
          closeCallback={() => closeCallbackHandler()}
        />
        <DelTestAppointment
          callBackFetchTestAppointmentList={fetchTestAppointmentList}
          popup={popupDel}
          closeCallback={() => closeCallbackHandler()}
        />
        <DeleteMulTestAppointments
          callBackFetchTestAppointmentList={fetchTestAppointmentList}
          popupMultiple={delPopUp}
          closeCallback={() => closeCallbackHandler()}
        />
      </Grid>
    );
  });
}
