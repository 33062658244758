/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  CardActionArea,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { observer, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import { useGlobalStore } from "../../state/GlobalStore";
import { apiService } from "../../services/ApiService";
import { AppInsightMethods } from "../../AppInsightMethods";

const DefaultFonts = () => {
  const globalStore = useGlobalStore();
  const pageName = "DefaultFonts";
  const appInsights = useAppInsightsContext();
  const [showSpinner, setShowSpinner] = useState(false);
  const [value, setFontValue] = useState("Segoe UI");

  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
    setShowSpinner(true);
    apiService
      .getFontdetails()
      .then((res: any) => {
        globalStore.getDefaultFontDetails(res);
        const customVal = res.filter((item) => item.isRecentlyAdded === true);
        globalStore.setCustomFontsOnLoad(customVal[0]);
        setFontValue(
          res.filter((item) => item.fontType === "Default")[0].fontName
        );
        setShowSpinner(false);
      })
      .catch((error: any) => {
        setShowSpinner(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at get fontdetails API call, Component: ${pageName}, Error: ${error}`
        );
      });
  }, []);

  const handleFontSave = () => {
    // save values here
    setShowSpinner(true);
    apiService
      .saveDefaultFontDetailsApi(value)
      .then((res: any) => {
        if (res === "Success") {
          setShowSpinner(false);
          globalStore.setShowDefaultSavePop(true);
          setTimeout(() => {
            globalStore.setShowDefaultSavePop(false);
          }, 5000);
        }
      })
      .catch((error: any) => {
        setShowSpinner(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at save default fontdetails API call, Component: ${pageName}, Error: ${error}`
        );
      });
  };

  const getDropdownValue = (fontWeightVal: string, fontStyleVal: string) => {
    if (fontWeightVal === "300" && fontStyleVal === "normal") {
      return "Light";
    }
    if (fontWeightVal === "400" && fontStyleVal === "normal") {
      return "Regular";
    }
    if (fontWeightVal === "350" && fontStyleVal === "normal") {
      return "Semilight";
    }
    if (fontWeightVal === "600" && fontStyleVal === "normal") {
      return "Semibold";
    }
    if (fontWeightVal === "700" && fontStyleVal === "normal") {
      return "Bold";
    }
    if (fontWeightVal === "900" && fontStyleVal === "normal") {
      return "Black";
    }
    if (fontWeightVal === "400" && fontStyleVal === "Italic") {
      return "Italic";
    }
    if (fontWeightVal === "600" && fontStyleVal === "Italic") {
      return "Semibold Italic";
    }
    if (fontWeightVal === "700" && fontStyleVal === "Italic") {
      return "Bold Italic";
    }
    if (fontWeightVal === "350" && fontStyleVal === "Italic") {
      return "Semilight Italic";
    }
    return "Light";
  };

  const handleFontSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFontValue((event.target as HTMLInputElement).value);
  };

  return useObserver(() => {
    return (
      <Grid className="defaultFontGrid">
        <div className="cardMain">
          {globalStore.defaultCardValue.map((item) => (
            <>
              <FormControl className="frmCtr" key={item.fontName}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  className="radioGrp"
                  value={value}
                  onChange={handleFontSelection}
                >
                  <FormControlLabel
                    className="frmCtrLbl"
                    value={item.fontName}
                    control={<Radio />}
                    label={item.fontName}
                  />
                </RadioGroup>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {item.fontDetails.map((subItem) => (
                  <Card
                    sx={{ minWidth: 275, width: 340 }}
                    className="cardDefaultFont"
                    key={item.fontName + subItem.heading}
                  >
                    <CardContent className="cardContentDefaultFont">
                      <Typography
                        className="Heading"
                        style={{
                          font: `${subItem.fontStyle} ${subItem.fontWeight} ${subItem.fontSize}/${subItem.lineHeight} ${subItem.fontFamily}`,
                          textIndent: subItem.textIndent,
                          letterSpacing: subItem.letterSpacing,
                          textTransform: subItem?.textTransform,
                          textDecoration: subItem.textDecoration,
                        }}
                      >
                        {subItem.heading}
                      </Typography>
                      <Typography className="fontSizeWeight">
                        {subItem.fontFamily},{" "}
                        {getDropdownValue(
                          subItem.fontWeight,
                          subItem.fontStyle
                        )}
                      </Typography>
                      <Typography className="lineHeight">
                        {subItem.fontSize}/{subItem.lineHeight}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </>
          ))}
        </div>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <div className="buttons">
          <Button className="cancelButton">Cancel</Button>
          <Button className="saveButton" onClick={handleFontSave}>
            Save
          </Button>
        </div>
      </Grid>
    );
  });
};
export default observer(DefaultFonts);
