// eslint-disable jsx-props-no-spreading
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { Grid, Typography } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import moment from "moment";

import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useGlobalStore } from "../../state/GlobalStore";
import configData from "../../config.json";
import { apiService } from "../../services/ApiService";
import { ConstantVariables } from "../../Constants";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  "& .summaryFilterCheckbox": {
    "& .MuiTypography-root": {
      font: "normal normal normal 16px/20px Segoe UI",
      color: "#3D3D3D",
    },
    "& .MuiCheckbox-root": {
      padding: "9px 0px 9px 9px",

      "& .MuiSvgIcon-root": { color: "inherit" },
    },
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 230,
    marginLeft: 0,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {},
    "& .MuiMenuItem-root": {
      paddingLeft: 0,
      backgroundColor: "#fff",
      paddingTop: 0,
      paddingBottom: 0,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "#fff",
      },
    },
  },
}));

const filterTimeValP1 = [
  { value: "06:00 AM - 08:00 AM", checked: false },
  { value: "08:00 AM - 10:00 AM", checked: false },
  { value: "10:00 AM - 12:00 PM", checked: false },
  { value: "12:00 PM - 02:00 PM", checked: false },
  { value: "02:00 PM - 04:00 PM", checked: false },
  { value: "04:00 PM - 06:00 PM", checked: false },
];
const filterTimeValP2 = [
  { value: "06:00 AM - 08:00 AM", checked: false },
  { value: "08:00 AM - 10:00 AM", checked: false },
  { value: "10:00 AM - 12:00 PM", checked: false },
  { value: "12:00 PM - 02:00 PM", checked: false },
  { value: "02:00 PM - 04:00 PM", checked: false },
  { value: "04:00 PM - 06:00 PM", checked: false },
];

interface IdateTimeFiler {
  spinnerFunc: (val: boolean) => void;
}

export default function DateTimeFilter(props: IdateTimeFiler) {
  const pageName = "DateTimeFilter";
  const appInsights = useAppInsightsContext();

  const trackComponent = useTrackMetric(appInsights, pageName);
  const globalStore = useGlobalStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isPanel1Open, setPanel1Open] = useState(false);
  const [isPanel2Open, setPanel2Open] = useState(false);
  const [checkedValuesToday, setCheckedValuesToday] = useState<any>([]);
  const [checkedValuesYesterday, setCheckedValuesYesterday] = useState<any>([]);

  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
    endDate: new Date(),
  });

  const [expanded, setExpanded] = React.useState<string | false>("");

  useEffect(() => {
    trackComponent();
    filterTimeValP1.map((itemTemp) => {
      itemTemp.checked = false;
      return itemTemp;
    });
    filterTimeValP2.map((itemTemp) => {
      itemTemp.checked = false;
      return itemTemp;
    });
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      setPanel2Open(false);
      setPanel1Open(false);
      filterTimeValP1.splice(6, 6);
      filterTimeValP2.splice(6, 6);
    };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPanel2Open(false);
    setPanel1Open(false);
    filterTimeValP1.splice(6, 6);
    filterTimeValP2.splice(6, 6);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showMoreToday = (item) => {
    if (item === true) {
      filterTimeValP1.push(
        { value: "06:00 PM - 08:00 PM", checked: false },
        { value: "08:00 PM - 10:00 PM", checked: false },
        { value: "10:00 PM - 12:00 AM", checked: false },
        { value: "12:00 AM - 02:00 AM", checked: false },
        { value: "02:00 AM - 04:00 AM", checked: false },
        { value: "04:00 AM - 06:00 AM", checked: false }
      );
    } else if (item === false) {
      filterTimeValP1.splice(6, 6);
    }
    setPanel1Open(item);
  };

  const showMoreYesterday = (item) => {
    if (item === true) {
      filterTimeValP2.push(
        { value: "06:00 PM - 08:00 PM", checked: false },
        { value: "08:00 PM - 10:00 PM", checked: false },
        { value: "10:00 PM - 12:00 AM", checked: false },
        { value: "12:00 AM - 02:00 AM", checked: false },
        { value: "02:00 AM - 04:00 AM", checked: false },
        { value: "04:00 AM - 06:00 AM", checked: false }
      );
    } else if (item === false) {
      filterTimeValP2.splice(6, 6);
    }
    setPanel2Open(item);
  };

  const checkBoxToday = (e, item: any) => {
    if (e.target.checked) {
      setCheckedValuesToday((currentArray) => [...currentArray, item]);
      filterTimeValP1.map((itemTemp) => {
        if (item === itemTemp.value) {
          itemTemp.checked = true;
        }
        return itemTemp;
      });
    } else {
      const filteredArray = checkedValuesToday.filter((ele) => ele !== item);
      setCheckedValuesToday(filteredArray);
      filterTimeValP1.map((itemTemp) => {
        if (item === itemTemp.value) {
          itemTemp.checked = false;
        }
        return itemTemp;
      });
    }
  };

  const checkBoxYesterday = (e, item: any) => {
    if (e.target.checked) {
      setCheckedValuesYesterday((currentArray) => [...currentArray, item]);

      filterTimeValP2.map((itemTemp) => {
        if (item === itemTemp.value) {
          itemTemp.checked = true;
        }
        return itemTemp;
      });
    } else {
      const filteredArray = checkedValuesYesterday.filter(
        (ele) => ele !== item
      );
      setCheckedValuesYesterday(filteredArray);
      filterTimeValP2.map((itemTemp) => {
        if (item === itemTemp.value) {
          itemTemp.checked = false;
        }
        return itemTemp;
      });
    }
  };

  const getStartDate = (date: Date) => {
    return moment(new Date(date.setHours(0, 0, 0, 0))).format();
  };

  const getEndDate = (date: Date) => {
    return moment(new Date(date.setHours(23, 59, 59, 0))).format();
  };

  const clearAllClick = () => {
    setExpanded("");
    props.spinnerFunc(true);

    apiService
      .getAppointments(
        getStartDate(selectedDate.startDate),
        getEndDate(selectedDate.endDate),
        configData.PROVIDER_GROUP_ID,
        globalStore.loginUserAadId
      )
      .then((res) => {
        if (res.length !== 0) {
          const myResponse = res;
          globalStore.setAppointmentsList(myResponse);
          globalStore.setOpenRecord(true);

          const statusArr: any = [];
          statusArr.push({ count: res.length, status: "All" });
          const status = [
            ConstantVariables.Status.scheduled,
            ConstantVariables.Status.waiting,
            ConstantVariables.Status.delayed,
            ConstantVariables.Status.inProgress,
            ConstantVariables.Status.completed,
          ];
          status.forEach((element) => {
            statusArr.push({
              count: res.filter((val) => val.status === element).length,
              status: element,
            });
          });
          globalStore.setCardData(statusArr);
          props.spinnerFunc(false);
        } else if (res.length === 0) {
          globalStore.setAppointmentsList([]);
          globalStore.setOpenRecord(false);
          globalStore.setCardData([]);
          props.spinnerFunc(false);
        }
      })
      .catch((error: any) => {
        props.spinnerFunc(false);
      });
    filterTimeValP2.map((item) => {
      item.checked = false;
      return item;
    });
    filterTimeValP1.map((item) => {
      item.checked = false;
      return item;
    });

    setCheckedValuesYesterday([]);
    setCheckedValuesToday([]);
  };

  const getFormattedDate = (date: Date, hour: number) => {
    return moment(new Date(date.setHours(hour, 0, 0, 0))).format();
  };

  const getEaxctHrFunc = (hour: string) => {
    switch (hour) {
      case "06:00 AM":
        return 6;
      case "08:00 AM":
        return 8;
      case "10:00 AM":
        return 10;
      case "12:00 AM":
        return 24;
      case "02:00 AM":
        return 2;
      case "04:00 AM":
        return 4;
      case "06:00 PM":
        return 18;
      case "08:00 PM":
        return 20;
      case "10:00 PM":
        return 22;
      case "12:00 PM":
        return 12;
      case "02:00 PM":
        return 14;
      case "04:00 PM":
        return 16;
      default:
        return 12;
    }
  };

  const applyClick = () => {
    props.spinnerFunc(true);
    setExpanded("");
    // to get the selected checkbox values in form of array

    if (
      checkedValuesYesterday.length === 0 &&
      checkedValuesToday.length === 0
    ) {
      apiService
        .getAppointments(
          getStartDate(selectedDate.startDate),
          getEndDate(selectedDate.endDate),
          configData.PROVIDER_GROUP_ID,
          globalStore.loginUserAadId
        )
        .then((res) => {
          if (res.length !== 0) {
            const myResponse = res;
            globalStore.setAppointmentsList(myResponse);
            globalStore.setOpenRecord(true);

            const statusArr: any = [];
            statusArr.push({ count: res.length, status: "All" });
            const status = [
              ConstantVariables.Status.scheduled,
              ConstantVariables.Status.waiting,
              ConstantVariables.Status.delayed,
              ConstantVariables.Status.inProgress,
              ConstantVariables.Status.completed,
            ];
            status.forEach((element) => {
              statusArr.push({
                count: res.filter((val) => val.status === element).length,
                status: element,
              });
            });
            globalStore.setCardData(statusArr);
            props.spinnerFunc(false);
          } else if (res.length === 0) {
            globalStore.setAppointmentsList([]);
            globalStore.setOpenRecord(false);
            globalStore.setCardData([]);

            props.spinnerFunc(false);
          }
        })
        .catch((error: any) => {
          props.spinnerFunc(false);
        });
    } else {
      const mergedSelectedVal = []
        .concat(
          ...(checkedValuesYesterday.length > 0
            ? checkedValuesYesterday
            : checkedValuesToday
          ).map((item) => item.split("-"))
        )
        .map((item: any) =>
          getFormattedDate(
            checkedValuesYesterday.length > 0
              ? new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
              : new Date(),
            getEaxctHrFunc(item.trim())
          )
        );

      props.spinnerFunc(true);
      apiService
        .getAppointmentsByFilter(
          mergedSelectedVal,
          configData.PROVIDER_GROUP_ID,
          globalStore.loginUserAadId
        )
        .then((res) => {
          if (res.length !== 0) {
            const myResponse = res;
            globalStore.setAppointmentsList(myResponse);
            globalStore.setOpenRecord(true);
            const statusArr: any = [];
            statusArr.push({ count: res.length, status: "All" });
            const status = [
              ConstantVariables.Status.scheduled,
              ConstantVariables.Status.waiting,
              ConstantVariables.Status.delayed,
              ConstantVariables.Status.inProgress,
              ConstantVariables.Status.completed,
            ];
            status.forEach((element) => {
              statusArr.push({
                count: res.filter((val) => val.status === element).length,
                status: element,
              });
            });
            globalStore.setCardData(statusArr);
            props.spinnerFunc(false);
          } else if (res.length === 0) {
            globalStore.setAppointmentsList([]);
            globalStore.setOpenRecord(false);
            globalStore.setCardData([]);
            props.spinnerFunc(false);
          }
        })
        .catch((error: any) => {
          props.spinnerFunc(false);
        });
    }
  };

  return useObserver(() => {
    return (
      <div className="dateFilter">
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {checkedValuesToday.length > 0 && "Today"}
          {checkedValuesYesterday.length > 0 && "Yesterday"}
          {checkedValuesYesterday.length === 0 &&
            checkedValuesToday.length === 0 &&
            "All"}
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem disableRipple>
            <Accordion
              style={{
                boxShadow: "none",
                marginTop: "0px",
                pointerEvents:
                  checkedValuesYesterday.length > 0 ? "none" : "auto",
              }}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                style={{
                  minHeight: "32px",
                  height: "32px",
                  backgroundColor:
                    expanded === "panel1" || checkedValuesToday.length > 0
                      ? "#EBEBEB"
                      : "white",
                }}
              >
                <Typography
                  style={{
                    font: "normal normal normal 16px/20px Segoe UI",
                  }}
                >
                  Today
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ padding: "0px 14px 5px 25px", display: "block" }}
              >
                <FormGroup>
                  {filterTimeValP1.map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name={item.value}
                          checked={item.checked}
                          onClick={(e) => checkBoxToday(e, item.value)}
                        />
                      }
                      label={item.value}
                      key={item.value}
                      className="summaryFilterCheckbox"
                    />
                  ))}
                </FormGroup>

                <Typography
                  style={{
                    font: "normal normal 600 14px/16px Segoe UI",
                    color: "#1447D4",
                    cursor: "pointer",
                    marginBottom: "3px",
                  }}
                  onClick={() => showMoreToday(!isPanel1Open)}
                >
                  {isPanel1Open ? " View less" : "View more"}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </MenuItem>

          <MenuItem disableRipple>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              style={{
                boxShadow: "none",
                marginTop: "0px",
                pointerEvents: checkedValuesToday.length > 0 ? "none" : "auto",
              }}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                style={{
                  minHeight: "32px",
                  height: "32px",
                  backgroundColor:
                    expanded === "panel2" || checkedValuesYesterday.length > 0
                      ? "#EBEBEB"
                      : "white",
                }}
              >
                <Typography
                  style={{
                    font: "normal normal normal 16px/20px Segoe UI",
                  }}
                >
                  Yesterday
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ padding: "0px 14px 5px 25px", display: "block" }}
              >
                <FormGroup>
                  {filterTimeValP2.map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={item.value}
                          color="primary"
                          checked={item.checked}
                          onClick={(e) => checkBoxYesterday(e, item.value)}
                        />
                      }
                      label={item.value}
                      key={item.value}
                      className="summaryFilterCheckbox"
                    />
                  ))}
                </FormGroup>
                <Typography
                  style={{
                    font: "normal normal 600 14px/16px Segoe UI",
                    color: "#1447D4",
                    cursor: "pointer",
                    marginBottom: "3px",
                  }}
                  onClick={() => showMoreYesterday(!isPanel2Open)}
                >
                  {isPanel2Open ? " View less" : "View more"}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <Grid style={{ display: "flex", justifyContent: "space-around" }}>
            {" "}
            <Typography
              style={{
                font: "normal normal normal 16px/21px Segoe UI",
                color: "#ADADAD",
                cursor: "pointer",
              }}
              onClick={() => clearAllClick()}
            >
              Clear All
            </Typography>{" "}
            <Typography
              style={{
                font: "normal normal 600 16px/21px Segoe UI",
                color: "#18760A",
                cursor: "pointer",
              }}
              // className={
              //   checkedValuesYesterday.length === 0 &&
              //   checkedValuesToday.length === 0
              //     ? "applyDisable"
              //     : "applyEnable"
              // }
              onClick={() => applyClick()}
            >
              Apply
            </Typography>
          </Grid>
        </StyledMenu>
      </div>
    );
  });
}
