/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useObserver } from "mobx-react-lite";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IDurationMin, IUpdateSystemSettings } from "../../state/StoreTypes";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";

interface IEditSystemSettingsProps {
  openEditPopup: boolean;
  closeCallback: (b: boolean) => void;
}

const mockDuration: Array<IDurationMin> = [
  {
    key: "5",
    value: "5 minutes",
  },
  {
    key: "10",
    value: "10 minutes",
  },
  {
    key: "15",
    value: "15 minutes",
  },
  {
    key: "30",
    value: "30 minutes",
  },
  {
    key: "45",
    value: "45 minutes",
  },
  {
    key: "60",
    value: "60 minutes",
  },
];

const useStyles = makeStyles({
  dialog: {
    maxWidth: "720px",
  },
});

export default function EditSystemSettings(props: IEditSystemSettingsProps) {
  const pageName = "EditSystemSettings";
  const globalStore = useGlobalStore();
  const classes = useStyles();
  const helper = helperMethods();
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSaveBtnClick = () => {
    try {
      setShowSpinner(true);
      if (globalStore.selectedSystemSetting) {
        const updateSysSettingObj: IUpdateSystemSettings = {
          id: globalStore.selectedSystemSetting?.id,
          settingsType: globalStore.selectedSystemSetting?.settingsType,
          time: globalStore.selectedSystemSetting?.time,
        };
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          AppInsightsConstants.Information,
          `The SystemSettings ${globalStore.selectedSystemSetting?.settingsType} has been changed to ${globalStore.selectedSystemSetting?.time} minutes.`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
        apiService
          .updateSystemSettings(updateSysSettingObj)
          .then((value: any) => {
            if (value && value === "success") {
              helper.setSuccessMessageEvent(
                "System settings updated successfully."
              );
              props.closeCallback(false);
              AppInsightMethods.trackAppInsightsEvent(
                pageName,
                AppInsightsConstants.Information,
                `System settings update API call success.`,
                globalStore.loginUserEmail,
                window.location.href,
                ""
              );
            }
            setShowSpinner(false);
            setErrorMessage("");
          })
          .catch((error: any) => {
            setShowSpinner(false);
            setErrorMessage(ConstantVariables.ErrorBoundaryTextMessage);
            AppInsightMethods.TrackAppInsightsException(
              `Error at onSaveBtnClick, Component: ${pageName}, Error: ${error}`
            );
          });
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at update System Settings API call, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  return useObserver(() => {
    return (
      <>
        <Dialog
          open={props.openEditPopup}
          id={`editSystemSettings-dialog`}
          classes={{ paper: classes.dialog }}
        >
          <Grid className={"editSystemSettings-dialog-container"}>
            <Grid className="editSystemSettings-dialog-title">
              <span>Edit System Setting</span>
              <CloseRoundedIcon onClick={() => props.closeCallback(true)} />
            </Grid>
            <Grid className={"editSystemSettings-dialog-content"}>
              <Grid className="editSystemSettings-dialog-description">
                <p>
                  {globalStore.selectedSystemSetting
                    ? `${globalStore.selectedSystemSetting.title} :`
                    : ""}
                </p>
                <p>
                  {globalStore.selectedSystemSetting
                    ? globalStore.selectedSystemSetting.description
                    : ""}
                </p>
              </Grid>
              <Grid className="editSystemSettings-duration">
                <InputLabel className="label">
                  {"Time"}
                  <sup>*</sup>
                </InputLabel>

                <FormControl>
                  <Select
                    variant="outlined"
                    id="createAppointment-Duration"
                    value={
                      globalStore.selectedSystemSetting
                        ? globalStore.selectedSystemSetting.time
                        : ""
                    }
                    onChange={(e) => {
                      globalStore.setSystemSettingsDuration(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    className="dropdown duration"
                  >
                    {mockDuration.map((val: IDurationMin) => {
                      return (
                        <MenuItem value={val.key} key={val.key}>
                          {val.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions className={"editSystemSettings-dialog-BtnContainer"}>
              <Button
                onClick={() => props.closeCallback(true)}
                variant="contained"
                className={"cancelBtn"}
              >
                Cancel
              </Button>
              <Button
                onClick={onSaveBtnClick}
                variant="contained"
                className={"saveBtn"}
              >
                Save
              </Button>
            </DialogActions>
            {!!errorMessage && (
              <span className="errorMessage">{errorMessage}</span>
            )}
          </Grid>
        </Dialog>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
}
