/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import { StepIconProps } from "@mui/material/StepIcon";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useGlobalStore } from "../../state/GlobalStore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    color: {
      color: "green",
    },

    step: {
      "&$completed": {
        color: "#18760A",
        borderColor: "#199D05",
      },
      "&$active": {
        color: "#18760A",
        borderColor: "#199D05",
      },
      "&$disabled": {
        color: "#696969",
        borderColor: "#8A8A8A",
      },
    },

    alternativeLabel: {},
    active: {}, // needed so that the &$active tag works
    completed: {},
    labelContainer: {},
  })
);

export default function HorizontalStepper() {
  const classes = useStyles();
  const globalStore = useGlobalStore();
  const pageName = "HorizontalStepper";
  const appInsights = useAppInsightsContext();

  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    return (
      <Grid className="stepper">
        {" "}
        <Stepper alternativeLabel>
          {!Object.values(globalStore.stepDetailsNew).every(
            (o) => o === null
          ) === true &&
            Object.keys(globalStore.stepDetailsNew).map((key, index) =>
              globalStore.stepDetailsNew[key] !== null ? (
                globalStore.stepDetailsNew[key]?.status === "Success" ? (
                  <Step
                    key={globalStore.stepDetailsNew[key].state}
                    disabled={false}
                    active={false}
                    completed
                    className={"stepCompleted"}
                  >
                    <StepLabel
                      classes={{
                        alternativeLabel: classes.alternativeLabel,
                        labelContainer: classes.labelContainer,
                      }}
                      StepIconProps={{
                        classes: {
                          root: classes.step,
                          completed: classes.completed,
                          active: classes.active,
                        },
                      }}
                    >
                      <div
                        style={{
                          font: "normal normal bold 16px/21px Segoe UI",
                          textTransform: "uppercase",
                        }}
                      >
                        {globalStore.stepDetailsNew[key].state}
                      </div>
                    </StepLabel>
                  </Step>
                ) : (
                  <Step key={"error"} className={"stepError"}>
                    <StepLabel
                      className="stepLable"
                      StepIconComponent={ReportProblemIcon}
                    >
                      <div
                        style={{
                          font: "normal normal 600 16px/21px Segoe UI",
                          color: "#EC5959",
                          textTransform: "uppercase",
                        }}
                      >
                        {globalStore.stepDetailsNew[key].state}
                      </div>
                    </StepLabel>
                  </Step>
                )
              ) : (
                <Step
                  key={globalStore.stepDetailsNew[key]}
                  disabled
                  active={false}
                  completed={false}
                  className={"stepDisabled"}
                >
                  <StepLabel
                    classes={{
                      alternativeLabel: classes.alternativeLabel,
                      labelContainer: classes.labelContainer,
                    }}
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                      },
                    }}
                  >
                    <div
                      style={{
                        font: "normal normal 600 16px/21px Segoe UI",
                        textTransform: "uppercase",
                      }}
                    >
                      {key.replace(/[A-Z]/g, " $&").trim()}
                    </div>
                  </StepLabel>
                </Step>
              )
            )}
        </Stepper>
      </Grid>
    );
  });
}
