/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  MenuItem,
  Menu,
  CircularProgress,
  Switch,
  styled,
  Backdrop,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import { useObserver } from "mobx-react-lite";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import AddMaterial from "./AddMaterial";
import DeleteMaterial from "./DeleteMaterial";
import { IMaterial } from "../../state/GlobalStateType";
import { IUpdateState, IPostUserRole } from "../../state/StoreTypes";
import Pagination from "../Pagination/Pagination";
import { apiService } from "../../services/ApiService";
import { AppInsightMethods } from "../../AppInsightMethods";
import { LightTooltip } from "../Relationship/RelationshipList";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import EditMaterial from "./EditMaterial";
import { useSortableData } from "../Dashboard/useSortableData";
import { ReactComponent as DownArrowDisabled } from "../../assets/down-arrow-disabled-icon.svg";
import { ReactComponent as DownArrow } from "../../assets/down-arrow-icon.svg";
import { ReactComponent as UpArrow } from "../../assets/up-arrow-icon.svg";
import { NoResult } from "../Resources/Resources";
import ViewMaterial from "./ViewMaterial";
import { sessionStorage } from "../../state/SessionStorage";
import configData from "../../config.json";
import { useGlobalStore } from "../../state/GlobalStore";

interface IMaterialListProps {
  searchText: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 56,
  height: 24,
  padding: 0,
  display: "flex",

  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(32px)",
      color: "#4A8409",
      "&.Mui-disabled": {
        color: "#E7E7E9",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#E7E7E9" : "#E9F7E5",
        border: "1px solid #4A8409",
      },
    },
    color: "#8a8a8a",
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 19,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 21,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(231, 231, 233, 1)",
    border:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "2px solid #ADADAD69",
    boxSizing: "border-box",
  },
}));

export default function MaterialList(props: IMaterialListProps) {
  const pageName = "MaterialList";
  const appInsights = useAppInsightsContext();
  const alignLeftText = "left";
  const itemsPerPageCount = 7;
  const classes = useStyles();
  const [anchorEls, setAnchorEls] = useState({} as any);
  const [materialList, setMaterialList] = useState<IMaterial[]>([]);
  const [globalMaterialList, setGlobalMaterialList] = useState<IMaterial[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSpin, setShowSpin] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [popupT, setPopup] = useState(false);
  const [popupDel, setPopupDel] = useState(false);
  const [popupView, setPopupView] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const [endIndex, setEndIndex] = useState(itemsPerPageCount);
  const globalStore = useGlobalStore();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const { items, requestSort, sortConfig } = useSortableData(materialList, {
    key: "title",
    direction: "ascending",
  });
  useEffect(() => {
    const getRoleUser = async () => {
      const userRoleObj: IPostUserRole = {
        groupId: await configData.ADMIN_GROUP_ID,
        userId: globalStore.loginUserAadId,
      };
      setShowSpin(true);
      apiService
        .getUserRole(userRoleObj)
        .then((res) => {
          setShowSpin(false);
          globalStore.setDataAvailable(true);
          globalStore.setActionMenuSelectedUserRole(res);
        })
        .catch((error: any) => {
          setShowSpin(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get User Role settings API call, Component: ${pageName}, Error: ${error}`
          );
        });
    };
    getRoleUser();
  }, []);

  const getMaterialListApiCall = () => {
    try {
      setShowSpinner(true);
      apiService
        .getMaterialList()
        .then((data: IMaterial[]) => {
          setShowSpinner(false);
          setMaterialList(data);
          setGlobalMaterialList(data);
          sessionStorage.setWithExpiry("materialList", data, 600);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at getMaterialList API call, getMaterialListApiCall, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getMaterialListApiCall, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    trackComponent();
    const pageLoadApiCall = async () => {
      const materials = await sessionStorage.getWithExpiry("materialList");
      if (materials) {
        setMaterialList(materials);
        setGlobalMaterialList(materials);
      } else {
        getMaterialListApiCall();
      }
    };

    pageLoadApiCall();
  }, []);

  useEffect(() => {
    if (props.searchText) {
      const newItem: IMaterial[] = [...globalMaterialList];
      const newArr = newItem.filter(
        (value: IMaterial) =>
          value.title.toLowerCase().includes(props.searchText.toLowerCase()) ||
          value.description
            .toLowerCase()
            .includes(props.searchText.toLowerCase())
      );
      setMaterialList(newArr);
    } else {
      setMaterialList(globalMaterialList);
    }
  }, [props.searchText]);

  const closeCallbackHandler = () => {
    try {
      setPopup(false);
      setPopupDel(false);
      setPopupEdit(false);
      setPopupView(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const editPopUp = () => {
    try {
      setPopupEdit(true);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at editPopUp, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const isActive = (row: string, isActiveState: boolean) => {
    const toggleValue: IUpdateState = {
      id: row,
      isActive: !isActiveState,
    };
    const materialListNew: IMaterial[] = JSON.parse(
      JSON.stringify(materialList)
    );
    materialListNew.filter((value: IMaterial) => value.id === row)[0].isActive =
      !isActiveState;
    setIsFetch(true);
    setMaterialList(materialListNew);
    sessionStorage.setWithExpiry("materialList", materialListNew, 600);
    apiService
      .updateState(toggleValue)
      .then((res: any) => {
        if (res === "success") {
          setIsFetch(false);
        }
      })
      .catch((error: any) => {
        setIsFetch(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at Edit Material API call, Component: ${pageName}, Error: ${error}`
        );
      });
  };
  /* Method for opening more actions menu */
  const handleActionClick = (materialId: string, event: any) => {
    anchorEls[materialId] = event?.target;
    setAnchorEls({ ...anchorEls });
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightsConstants.Information,
      `Action Menu Opened, PatientId: ${materialId}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };
  /* Method for closing more actions menu */
  const handleActionClose = (materialId: string) => {
    anchorEls[materialId] = null;
    setAnchorEls({ ...anchorEls });
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightsConstants.Information,
      `Action Menu Closed, PatientId: ${materialId}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return <DownArrowDisabled />;
    }
    if (sortConfig.key === name && sortConfig.direction === "ascending") {
      return <UpArrow />;
    }
    if (sortConfig.key === name && sortConfig.direction === "descending") {
      return <DownArrow />;
    }
    return <DownArrowDisabled />;
  };

  return useObserver(() => {
    return (
      <Grid className="material-container">
        <Grid className="material">
          <Grid className={"material-left"}>{`Total Materials ${
            materialList.length > 0 ? `(${materialList.length})` : ""
          }`}</Grid>
          <LightTooltip
            title={ConstantVariables.RoleBasedAccessErrorMessage}
            disableHoverListener={
              globalStore.actionMenuSelectedUserRole ===
              globalStore.fullControlGroup
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                className="newMaterialBtn"
                startIcon={<AddIcon />}
                disabled={
                  globalStore.actionMenuSelectedUserRole !==
                  globalStore.fullControlGroup
                }
                style={{
                  opacity:
                    globalStore.actionMenuSelectedUserRole !==
                    globalStore.fullControlGroup
                      ? 0.5
                      : 1,
                }}
                onClick={() => {
                  setPopup(true);
                  globalStore.setOpenedModal("create");
                  AppInsightMethods.trackAppInsightsEvent(
                    pageName,
                    AppInsightsConstants.Information,
                    `The "New material" button has been clicked.`,
                    globalStore.loginUserEmail,
                    window.location.href,
                    ""
                  );
                }}
              >
                New Material
              </Button>
            </span>
          </LightTooltip>
        </Grid>

        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}
        {showSpin && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}
        {!showSpinner && !showSpin && items && items.length > 0 && (
          <TableContainer className={"materialList_table_container"}>
            <Table
              className={`${classes.table} materialList_table`}
              aria-label="simple table"
            >
              <TableHead className={"materialListHeader"}>
                <TableRow>
                  <TableCell
                    align={alignLeftText}
                    style={{ minWidth: 200 }}
                    className={`headerCell `}
                    tabIndex={0}
                    onClick={() => requestSort("title")}
                  >
                    {"Title"}
                    {getClassNamesFor("title")}
                  </TableCell>

                  <TableCell
                    align={alignLeftText}
                    style={{ minWidth: 250 }}
                    className={"headerCell"}
                    onClick={() => requestSort("description")}
                  >
                    {"Description"}
                    {getClassNamesFor("description")}
                  </TableCell>
                  <TableCell
                    align={alignLeftText}
                    style={{ minWidth: 150 }}
                    className={"headerCell"}
                    onClick={() => requestSort("modifiedBy")}
                  >
                    {"Modified By"}
                    {getClassNamesFor("modifiedBy")}
                  </TableCell>
                  <TableCell
                    align={alignLeftText}
                    style={{ minWidth: 210 }}
                    className={"headerCell"}
                    onClick={() => requestSort("modifiedDate")}
                  >
                    {"Modified Date & Time"}
                    {getClassNamesFor("modifiedDate")}
                  </TableCell>

                  <TableCell
                    align={alignLeftText}
                    style={{ minWidth: 200 }}
                    className={"headerCell"}
                  >
                    {"File"}
                  </TableCell>
                  <TableCell
                    align={alignLeftText}
                    tabIndex={0}
                    className={"headerCell"}
                    style={{ minWidth: 100 }}
                    onClick={() => requestSort("isActive")}
                  >
                    {"Status"}
                    {getClassNamesFor("isActive")}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={"materialListBody"}>
                {items?.slice(startIndex, endIndex).map((row: IMaterial) => (
                  <TableRow key={`${row.id}`} id={row.id}>
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell titleCell overflowHidden"
                      title={row.title}
                    >
                      <span className="bold">{row.title}</span>
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell overflowHidden"
                      title={row.description}
                      style={{ minWidth: 250 }}
                    >
                      {row.description}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell overflowHidden"
                      style={{ minWidth: 150 }}
                      title={row.modifiedBy}
                    >
                      {row.modifiedBy?.split("@")[0]}
                    </TableCell>
                    <TableCell align={alignLeftText} className="bodyCell">
                      {moment(new Date(row.modifiedDate)).format("ll")}
                      {" | "}
                      {moment(new Date(row.modifiedDate)).format("LT")}
                    </TableCell>

                    <TableCell
                      align={alignLeftText}
                      className="bodyCell"
                      style={{ minWidth: 200 }}
                    >
                      <a
                        href={`${row.url}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <span>{row.fileName}</span>
                      </a>
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell"
                      style={{ minWidth: 200 }}
                    >
                      <LightTooltip
                        title={ConstantVariables.RoleBasedAccessErrorMessage}
                        disableHoverListener={
                          globalStore.actionMenuSelectedUserRole ===
                          globalStore.fullControlGroup
                        }
                      >
                        <span>
                          <AntSwitch
                            disabled={
                              globalStore.actionMenuSelectedUserRole !==
                              globalStore.fullControlGroup
                            }
                            checked={row.isActive}
                            name="checked"
                            onChange={() => isActive(row.id, row.isActive)}
                          />
                        </span>
                      </LightTooltip>
                    </TableCell>
                    <TableCell align={alignLeftText} className="bodyCell">
                      <>
                        <MoreHorizIcon
                          onClick={(e) => {
                            globalStore.setActionMenuSelectedMaterial(row);
                            handleActionClick(`${row.id}`, e);
                          }}
                          className={"pointer"}
                        />
                        <Menu
                          id={`${row.id}`}
                          anchorEl={anchorEls[`${row.id}`]}
                          keepMounted
                          open={Boolean(anchorEls[`${row.id}`])}
                          onClose={() => handleActionClose(`${row.id}`)}
                        >
                          <LightTooltip
                            title={
                              ConstantVariables.RoleBasedAccessErrorMessage
                            }
                            disableHoverListener={
                              globalStore.actionMenuSelectedUserRole ===
                              globalStore.fullControlGroup
                            }
                          >
                            <span>
                              <MenuItem
                                disabled={
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                }
                                style={{
                                  opacity:
                                    globalStore.actionMenuSelectedUserRole !==
                                    globalStore.fullControlGroup
                                      ? 0.5
                                      : 1,
                                }}
                                onClick={() => {
                                  setPopupView(true);
                                  handleActionClose(`${row.id}`);
                                  globalStore.setOpenedModal("view");
                                }}
                              >
                                {"View Material"}
                              </MenuItem>
                            </span>
                          </LightTooltip>
                          <LightTooltip
                            title={
                              ConstantVariables.RoleBasedAccessErrorMessage
                            }
                            disableHoverListener={
                              globalStore.actionMenuSelectedUserRole ===
                              globalStore.fullControlGroup
                            }
                          >
                            <span>
                              <MenuItem
                                style={{
                                  opacity:
                                    globalStore.actionMenuSelectedUserRole !==
                                    globalStore.fullControlGroup
                                      ? 0.5
                                      : 1,
                                }}
                                disabled={
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                }
                                onClick={() => {
                                  setPopupEdit(true);
                                  handleActionClose(`${row.id}`);
                                  globalStore.setOpenedModal("edit");
                                }}
                              >
                                {"Edit Material"}
                              </MenuItem>
                            </span>
                          </LightTooltip>
                          <LightTooltip
                            title={
                              ConstantVariables.RoleBasedAccessErrorMessage
                            }
                            disableHoverListener={
                              globalStore.actionMenuSelectedUserRole ===
                              globalStore.fullControlGroup
                            }
                          >
                            <span>
                              <MenuItem
                                disabled={
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                }
                                style={{
                                  opacity:
                                    globalStore.actionMenuSelectedUserRole !==
                                    globalStore.fullControlGroup
                                      ? 0.5
                                      : 1,
                                }}
                                onClick={() => {
                                  setPopupDel(true);
                                  handleActionClose(`${row.id}`);
                                  globalStore.setOpenedModal("delete");
                                  globalStore.setSelectedMaterialName(
                                    row.title
                                  );
                                }}
                              >
                                {"Delete"}
                              </MenuItem>
                            </span>
                          </LightTooltip>
                        </Menu>
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {items && items.length > 7 && (
          <Pagination
            itemCount={items.length}
            itemsPerPage={itemsPerPageCount}
            paginationCallback={(startInd: number, endInd: number) => {
              setStartIndex(startInd);
              setEndIndex(endInd);
            }}
          />
        )}
        {!showSpinner && materialList.length === 0 && <NoResult />}
        {isFetch && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={isFetch}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <ViewMaterial
          popup={popupView}
          popupE={() => editPopUp()}
          closeCallback={() => closeCallbackHandler()}
        />
        <EditMaterial
          callBackGetMaterialList={getMaterialListApiCall}
          popup={popupEdit}
          closeCallback={() => closeCallbackHandler()}
        />
        <DeleteMaterial
          callBackGetMaterialList={getMaterialListApiCall}
          popup={popupDel}
          closeCallback={() => closeCallbackHandler()}
        />
        <AddMaterial
          callBackGetMaterialList={getMaterialListApiCall}
          popup={popupT}
          closeCallback={() => closeCallbackHandler()}
        />
      </Grid>
    );
  });
}
