import * as React from "react";
import {
  Divider,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

interface IFontFamilyDropdownProps {
  fontFamily: string;
}

export default function FontFamilyDropdown(props: IFontFamilyDropdownProps) {
  return (
    <FormControl
      size="small"
      style={{
        width: "100%",
        backgroundColor: "white",
        border: "1px solid #ECEBED",
        borderRadius: "6px",
      }}
    >
      <Select
        variant="outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        value={"0"}
        inputProps={{ "aria-label": "Without label" }}
        className="dropdown"
        disabled
      >
        <MenuItem key={"0"} value={"0"} disabled>
          {props.fontFamily}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
