/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  CircularProgress,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import moment from "moment";
import { LocalizationProvider } from "@mui/lab";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import add from "../../assets/add.png";
import cancel from "../../assets/cancel.png";
import { IAddTestAppointment, ITestProvider } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { AppInsightsConstants } from "../../Constants";
import OrganizerForm from "../TestAppointment/OrganizerForm";

const useStyle = makeStyles({
  dialog: {
    width: 730,
    height: 689,
    maxWidth: 730,
  },
});

interface ITestAppointment {
  callBackFetchTestAppointmentList: () => void;
  popup: boolean;
  closeCallback: () => void;
}
interface IDurationMin {
  key: number;
  value: string;
}

const mockDuration: Array<IDurationMin> = [
  {
    key: 5,
    value: "5 minutes",
  },
  {
    key: 10,
    value: "10 minutes",
  },
  {
    key: 15,
    value: "15 minutes",
  },
  {
    key: 30,
    value: "30 minutes",
  },
  {
    key: 45,
    value: "45 minutes",
  },
  {
    key: 60,
    value: "60 minutes",
  },
];

export default function AddTestAppointmentTab(props: ITestAppointment) {
  const globalStore = useGlobalStore();
  const pageName = "Create Test Appointment";
  const classes = useStyle();
  const helperAdd = helperMethods();
  const [testUserN, setTestUserN] = useState<string>("");
  const [testAppointmentDateN, setTestAppointmentDateN] = useState<string>("");
  const [testAppointmentTime, setTestAppointmentTime] = useState<string>(
    new Date().toString()
  );
  const [testAppointmentDuration, setTestAppointmentDuration] =
    useState<number>(15);
  const [showNameRequiredMgs, setShowNameRequiredMgs] = useState(false);
  const [showtestUserNError, setShowtestUserNError] = useState("");
  const [showtestProviderNError, setShowtestProviderNError] = useState("");
  const [showtestAppointmentTimeError, setShowtestAppointmentTimeNError] =
    useState("");
  const [showtestDurationError, setShowtestDurationNError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const [providerState, setProviderState] = useState<ITestProvider>(
    {} as ITestProvider
  );

  useEffect(() => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    date.toString();
    setTestAppointmentDateN(date);
    if (globalStore.actionMenuSelectedTestUser) {
      const name = globalStore.actionMenuSelectedTestUser.firstName.concat(
        " ",
        globalStore.actionMenuSelectedTestUser.lastName
      );
      setTestUserN(name);
    }
  }, [globalStore.actionMenuSelectedTestUser]);

  const onCancelClickEventHandler = () => {
    try {
      globalStore.setOpenedModalTestAppointment("none");

      setTestAppointmentDuration(15);
      setTestAppointmentTime(new Date().toString());
      setShowtestProviderNError("");
      setShowtestUserNError("");
      setShowNameRequiredMgs(false);
      setProviderState({} as ITestProvider);
      setShowtestAppointmentTimeNError("");

      setShowtestDurationNError("");
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Cancel button click event.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* onChange handler for provider field */
  const onChangeHandler = (providerObj: ITestProvider) => {
    try {
      setProviderState(providerObj);
      setShowNameRequiredMgs(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onChangeHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Validations for provider details */
  const validateProviderForm = () => {
    try {
      let isFailed = false;
      if (typeof globalStore.provider === "undefined") {
        setShowNameRequiredMgs(true);
        isFailed = true;
      } else if (
        typeof globalStore.provider.firstName === "undefined" ||
        globalStore.provider.firstName === ""
      ) {
        setShowNameRequiredMgs(true);
        isFailed = true;
      } else {
        setShowNameRequiredMgs(false);
      }
      return isFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at validateProviderForm, Component: ${pageName}, Error: ${error}`
      );
      return false;
    }
  };

  const validate = () => {
    try {
      let validationFailed = false;

      if (testUserN === "" || testUserN.trim().length === 0) {
        validationFailed = true;
        setShowtestUserNError("Please enter Test User Name");
      } else {
        setShowtestUserNError("");
      }
      if (
        testAppointmentTime === "" ||
        testAppointmentTime.trim().length === 0
      ) {
        validationFailed = true;
        setShowtestAppointmentTimeNError("Please enter Appointment Time");
      } else {
        setShowtestAppointmentTimeNError("");
      }
      if (testAppointmentDuration === 0) {
        validationFailed = true;
        setShowtestDurationNError("Please enter Duration");
      } else {
        setShowtestDurationNError("");
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Add Test appointment API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  /* onChange handler for time picker */
  const handleTimeChange = (date: any) => {
    try {
      const dateFormat = date ? moment(date).format("LT") : "";
      if (dateFormat === "Invalid date") {
        setTestAppointmentTime(date);
        setShowtestAppointmentTimeNError("Enter Valid Time");
      } else {
        setTestAppointmentTime(date);
        setShowtestAppointmentTimeNError("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleTimeChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const addTestAppointmentAPICall = () => {
    try {
      let materialData = false;
      materialData = validate();
      let providerValidationFailed = false;
      providerValidationFailed = validateProviderForm();
      if (materialData || providerValidationFailed) {
        return;
      }

      const providerStateArr: ITestProvider[] = [];
      providerStateArr.push(providerState);
      const testAppointmentObj: IAddTestAppointment = {
        firstName: testUserN,
        providerRequest: providerState,
        appointmentDuration: testAppointmentDuration,
        scheduledDateTime: moment(testAppointmentTime).format(),
        lastName: "",
        middleName: "",
        userId: "",
        email: "",
        phone: "",
        timeZone: "",
      };
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Add Test Appointment button click event. Remainder : ${testUserN}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setShowSpinner(true);
      apiService
        .addTestAppointment(testAppointmentObj)
        .then((res) => {
          if (res === "success") {
            helperAdd.setSuccessMessageEvent(
              "Test Appointment added successfully!"
            );
          }
          onCancelClickEventHandler();
          props.closeCallback();
          globalStore.setOpenedModalTestUser("none");
          helperAdd.setSuccessMessageEvent(
            "Test Appointment added successfully!"
          );
          props.callBackFetchTestAppointmentList();
          setShowSpinner(false);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at add Test Appointment API call, Component: ${pageName} Error : ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at add Test Appointment API, Component: ${pageName} Error : ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <div>
        <Dialog
          onClose={() => globalStore.setOpenedModalTestAppointment("none")}
          open={props.popup}
          classes={{ paper: classes.dialog }}
          className="testAppointmentContainer"
        >
          <DialogTitle className="testAppointmentTitle">
            <b style={{ marginRight: 370 }}>Create Test Appointment</b>
            <IconButton
              onClick={() => {
                onCancelClickEventHandler();
                props.closeCallback();
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className="testAppointmentContent">
            <span className="helpertextColor">
              Fill the below details to create test appointment with Oltiva
              services.
            </span>
            <Grid className={"testAppointment-text"}>
              Test User
              <span className={"material-title"}>*</span>
            </Grid>
            <Grid className={"testAppointmentText-box"}>
              <TextField
                value={testUserN}
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <div className={"material-error"}>{showtestUserNError || ""}</div>
            <Grid className={"testAppointment-text"}>
              Test Provider
              <span className={"material-title"}>*</span>
            </Grid>
            <OrganizerForm
              onChangeHandler={onChangeHandler}
              providerState={providerState}
              showNameRequiredMgs={showNameRequiredMgs}
            />

            <div className={"material-error"}>
              {showtestProviderNError || ""}
            </div>
            <Grid className={"testAppointment-text"}>
              Appointment Date
              <span className={"material-title"}>*</span>
            </Grid>
            <Grid className={"testAppointmentText-box"}>
              <TextField
                value={testAppointmentDateN}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid className={"testAppointment-text"}>
              Appointment Time
              <span className={"material-title"}>*</span>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <TimePicker
                  value={testAppointmentTime}
                  onChange={handleTimeChange}
                  renderInput={(params) => (
                    <TextField
                      placeholder="HH:MM AM"
                      style={{ width: 650 }}
                      {...params}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>

            <div className={"material-error"}>
              {showtestAppointmentTimeError || ""}
            </div>
            <Grid className="testAppointment-text">
              Appointment Duration
              <span className={"material-title"}>*</span>
              <FormControl
                className={"testAppointmentText-box "}
                sx={{ mt: 1, width: "100%" }}
              >
                <Select
                  variant="outlined"
                  id="createAppointment-Duration"
                  error={!!showtestDurationError}
                  value={testAppointmentDuration}
                  onChange={(e) => {
                    setTestAppointmentDuration(Number(e.target.value));
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  className="dropdown duration"
                >
                  {mockDuration.map((val: IDurationMin) => {
                    return (
                      <MenuItem value={val.key} key={val.key}>
                        {val.value}
                      </MenuItem>
                    );
                  })}
                </Select>
                <div className={"material-error"}>
                  {showtestDurationError || ""}
                </div>
              </FormControl>
            </Grid>
          </DialogContent>
          <Grid>
            <DialogActions className="btnClick">
              <Button
                onClick={() => {
                  onCancelClickEventHandler();
                  props.closeCallback();
                }}
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button onClick={addTestAppointmentAPICall}>
                <img
                  src={add}
                  alt="save"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
            {showSpinner && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 999,
                }}
                open={showSpinner}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Grid>
        </Dialog>
      </div>
    );
  });
}
