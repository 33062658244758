/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Grid, IconButton } from "@mui/material";
import Typography from "@material-ui/core/Typography";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useObserver } from "mobx-react-lite";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import WarningIcon from "@mui/icons-material/Warning";
import Popover from "@material-ui/core/Popover";
import CloseIcon from "@mui/icons-material/Close";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import Logout from "../../assets/logout.svg";
import { IAppointmentList, SortType } from "../../state/GlobalStateType";

export default function ErrorPopup() {
  const pageName = "ErrorPopup";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPopover, setShowPopover] = React.useState(false);
  const isOpen = true;
  /* Click event handler for inbox open popover */
  const handleClick = (event: any) => {
    try {
      setAnchorEl(event.currentTarget);
      setShowPopover(true);
      // AppInsightMethods.trackAppInsightsEvent(
      //   pageName,
      //   AppInsightsConstants.Information,
      //   `The Inbox has been opened on the meeting screen.`,
      //   "todo---value here",
      //   window.location.href,
      //   ""
      // );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Click event handler for inbox close popover */
  const handleClose = () => {
    setAnchorEl(null);
    setShowPopover(false);
  };

  /* Keyboard event handler for inbox close popover */
  const inboxCloseKeyDownHandler = (event: any) => {
    if (event.key === "Enter") {
      handleClose();
    }
  };

  const viewDetailsClick = () => {
    globalStore.setSummaryDetailsPage(true);
    globalStore.setTabName("Summary");
    setAnchorEl(null);
    setShowPopover(false);
    const appointments: IAppointmentList[] = globalStore.appointmentsList;
    globalStore.setMeetingDetailedView(appointments, 0, appointments.length);
  };

  return useObserver(() => {
    return (
      <>
        <Grid
          className={"dashboard_header_right_icon"}
          style={{ cursor: "pointer" }}
        >
          <NotificationsNoneIcon onClick={(e) => handleClick(e)} />
          {isOpen && <span className={"notificationIndicator"}>2</span>}
        </Grid>
        <Popover
          id="ErrorPopup"
          open={showPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          className={"errorPopup"}
        >
          <Grid className={"errorPopupContainer"}>
            <Grid className={"errorPopupHeader"}>
              <p className={"errorPopupText"}>Error Notification :</p>
              <CloseIcon
                onClick={() => {
                  handleClose();
                }}
                tabIndex={0}
                style={{ cursor: "pointer" }}
                onKeyDown={(e) => inboxCloseKeyDownHandler(e)}
              />
            </Grid>
            {globalStore.ErrorMsg.length > 0 &&
              globalStore.ErrorMsg.map((item) => (
                <>
                  {" "}
                  <Grid className="dFlex basicDet">
                    <Typography className="mR">{item.name}</Typography>|
                    <Typography className="mR mL">{item.patientId}</Typography>|
                    <Typography className="mL">{item.provider}</Typography>
                  </Grid>
                  <Grid className="dFlex errDet">
                    <WarningIcon
                      style={{
                        color: "#CF2A2A",
                        marginRight: "8px",
                        fontSize: "20px",
                      }}
                    />
                    <Typography className="errorName">{item.error}</Typography>
                    <Typography
                      className="viewDeat"
                      onClick={() => viewDetailsClick()}
                    >
                      View Details
                    </Typography>
                  </Grid>
                </>
              ))}

            {globalStore.ErrorMsg.length === 0 && (
              <Grid>
                <section className={"inboxContent"}>{"No errors"}</section>
              </Grid>
            )}
          </Grid>
        </Popover>
      </>
    );
  });
}
