/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useObserver } from "mobx-react-lite";
import React, { ReactNode, useEffect, useState } from "react";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { AccountInfo } from "@azure/msal-browser";
import { CircularProgress, Grid } from "@mui/material";
import { authApiService } from "../services/AuthenticationApiService";
import { useGlobalStore } from "../state/GlobalStore";
import { AppInsightMethods } from "../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../state/StoreTypes";

export type PropsWithChildren = { children: ReactNode };

export function Authentication(props: PropsWithChildren) {
  const pageName = "Authentication";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const [isError, setIsError] = useState(false);
  const [user, setUser] = useState<AccountInfo | undefined>();
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
    authApiService
      .signIn()
      .then((account) => {
        if (account) {
          setUser(account);
          globalStore.setLoginUserEmail(account.username);
          globalStore.setLoginUserAadId(account.localAccountId);
          AppInsightMethods.trackAppInsightsEvent(
            pageName,
            Constants.Information,
            `Authentication succeeded.`,
            globalStore.loginUserEmail,
            window.location.href,
            JSON.stringify(account)
          );
        } else {
          AppInsightMethods.TrackAppInsightsException(
            `Error at SSO Login, Account object is Null or Empty at PageLoad. Component: ${pageName}`
          );
        }
      })
      .catch((error: any) => {
        setIsError(true);
        AppInsightMethods.TrackAppInsightsException(
          `Error at Auth API to Get Login User Details, Component: ${pageName}, Error: ${error}`
        );
      });
  }, []);

  return useObserver(() => {
    return (
      <>
        {user && props.children}
        {!user && !isError && (
          <Grid
            className="auth-spinner"
            container
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={90} />
          </Grid>
        )}
        {isError && (
          <div data-testid="authentication-wrapper-error">
            {ConstantVariables.User_Details_Failed_Message_Text}
          </div>
        )}
      </>
    );
  });
}
