/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-dynamic-require */
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import Typography from "@material-ui/core/Typography";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { ReactComponent as ThemeActive } from "../../assets/themeActive.svg";
import { ReactComponent as Theme } from "../../assets/theme.svg";
import { ReactComponent as Font } from "../../assets/font.svg";
import { ReactComponent as FontActive } from "../../assets/fontActive.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as LogoActive } from "../../assets/logoActive.svg";
import { ReactComponent as Layout } from "../../assets/layout.svg";
import { ReactComponent as LayoutActive } from "../../assets/layoutActive.svg";
import Profile from "../../assets/profile_pic.png";
import { ICustomLeftNav, ILeftNav, ISubTab } from "../../state/GlobalStateType";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";

const customLeftNav: ICustomLeftNav[] = [
  {
    tabName: "Theme",
    icon: "home_icon.svg",
    iconName: "Home Icon",
    className: "active",
  },
  {
    tabName: "Font",
    icon: "config_icon.svg",
    iconName: "Config Icon",
    className: "",
  },
  {
    tabName: "Logo",
    icon: "settings_icon.svg",
    iconName: "Settings Icon",
    className: "",
  },
  {
    tabName: "Layout",
    icon: "settings_icon.svg",
    iconName: "Settings Icon",
    className: "",
  },
];

export interface ICustomLeftNavProps {
  ontabChangeEvent: (s: string) => void;
}

export default function CustomLeftNav(props: ICustomLeftNavProps) {
  const pageName = "CustomLeftNav";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const [navBar, setNavBar] = useState<ICustomLeftNav[]>(customLeftNav);
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  const menuItemClickEvent = (tabName: string) => {
    try {
      const clonedLeftTabs: ICustomLeftNav[] = JSON.parse(
        JSON.stringify(customLeftNav)
      );
      clonedLeftTabs.forEach((value: ICustomLeftNav) => {
        value.className = "";
        // value.subTabs.forEach((val: ISubTab) => {
        //   val.className = "";
        // });
      });
      clonedLeftTabs.filter(
        (value: ICustomLeftNav) => value.tabName === tabName
      )[0].className = "active";
      //   clonedLeftTabs
      //     .filter((value: ICustomLeftNav) => value.tabName === tabName)[0]
      //     .subTabs.filter(
      //       (val: ISubTab) => val.tabName === childTab
      //     )[0].className = "active";
      setNavBar(clonedLeftTabs);
      props.ontabChangeEvent(tabName);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `The Clicked Menu is ${tabName}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at menuItemClickEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const getSvgIcon = (tabName: string, className: string): JSX.Element => {
    try {
      switch (tabName.toLowerCase()) {
        case "theme":
          switch (className.toLowerCase()) {
            case "active":
              return <ThemeActive />;
            case "":
              return <Theme />;
            default:
              return <Theme />;
          }
        case "font":
          switch (className.toLowerCase()) {
            case "active":
              return <FontActive />;
            case "":
              return <Font />;
            default:
              return <Font />;
          }
        case "logo":
          switch (className.toLowerCase()) {
            case "active":
              return <LogoActive />;
            case "":
              return <Logo />;
            default:
              return <Logo />;
          }
        case "layout":
          switch (className.toLowerCase()) {
            case "active":
              return <LayoutActive />;
            case "":
              return <Layout />;
            default:
              return <Layout />;
          }

        default:
          return <ThemeActive />;
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getSvgIcon, Component: ${pageName}, Error: ${error}`
      );
      return <ThemeActive />;
    }
  };
  return useObserver(() => {
    return (
      <Grid className={"custom_sidebar"}>
        <img src={Profile} alt="Profile" />
        <Typography className="adminName">John Lee</Typography>
        <Typography className="adminDesignation">System Admin</Typography>
        {navBar.map((left: ICustomLeftNav) => (
          <Grid
            className={"sidebar_parent_container"}
            key={`${left.tabName}`}
            id={`${left.tabName}Id`}
          >
            <Grid
              className={`sidebar_child ${left.className}`}
              onClick={() => menuItemClickEvent(left.tabName)}
            >
              {getSvgIcon(left.tabName, left.className)}
              <span>{left.tabName}</span>
            </Grid>
            {/* {left.subTabs.map((sub: ISubTab) => {
              return (
                <Grid
                  className={`sidebar_child ${sub.className}`}
                  onClick={() => menuItemClickEvent(left.tabName, sub.tabName)}
                  key={`${sub.tabName}`}
                  id={`${sub.tabName}Id`}
                >
                  {getSvgIcon(sub.tabName)}
                  <span>{sub.tabName}</span>
                </Grid>
              );
            })} */}
          </Grid>
        ))}
      </Grid>
    );
  });
}
