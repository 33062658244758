import * as React from "react";
import { Grid, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { ReactComponent as LineHeightImg } from "../../assets/lineHightImgNew.svg";
import { useGlobalStore } from "../../state/GlobalStore";

interface ILineHeightDropdownProps {
  lineHeight: string;
  headingName: string;
}
export default function LineHeight(props: ILineHeightDropdownProps) {
  const globalStore = useGlobalStore();

  return (
    <Grid className={"searchBoxLetter"}>
      <TextField
        id="header-SearchBox"
        value={props.lineHeight.substring(0, props.lineHeight.length - 2)}
        onChange={(event) => {
          globalStore.setSearchText(
            `${event.target.value}px`,
            props.headingName,
            "lineHeight"
          );
        }}
      />
      <LineHeightImg />
    </Grid>
  );
}
