import React from "react";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { FileError } from "react-dropzone";
import { FileHeader } from "./FileHeader";

export interface UploadErrorProps {
  file: File;
  onDelete: (file: File) => void;
  errors: FileError[];
}

export function UploadError({ file, onDelete, errors }: UploadErrorProps) {
  return (
    <>
      <Grid className="uploadErrorParentContainer">
        <FileHeader file={file} onDelete={onDelete} />
      </Grid>
      <LinearProgress
        variant="determinate"
        value={100}
        color="error"
        className="progressbarsError"
      />

      {errors.map((error) => (
        <div key={error.code}>
          <Typography
            color="#FF6262"
            fontSize={12}
            fontStyle={"italic"}
            className="errorMessageFileSize"
          >
            File size exceeds 2 MB
          </Typography>
        </div>
      ))}
    </>
  );
}
