/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { useObserver } from "mobx-react-lite";
import { IGetRelationshipSettings } from "../../state/GlobalStateType";
import { apiService } from "../../services/ApiService";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import { NoResult } from "../Resources/Resources";
import AddRelationship from "./AddRelationshipSettings";
import DeleteRelationSettings from "./DeleteRelationSettings";
import EditRelationshipSettings from "./EditRelationshipSettings";
import { sessionStorage } from "../../state/SessionStorage";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: "rgba(173, 173, 173, 1)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

export default function RelationshipList() {
  const pageName = "Relationship List";
  const appInsights = useAppInsightsContext();
  const classes = useStyles();
  const alignLeftText = "left";
  const [relationList, setrelationList] = useState<IGetRelationshipSettings[]>(
    []
  );
  const globalStore = useGlobalStore();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);
  const [popupT, setPopup] = useState(false);
  const [popupDel, setPopupDel] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);

  const getRelationshipSettings = () => {
    try {
      setShowSpinner(true);
      apiService
        .getRelationshipSettings()
        .then((data: IGetRelationshipSettings[]) => {
          setShowSpinner(false);
          setrelationList(data);
          sessionStorage.setWithExpiry("relationShip", data, 600);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get Relationship settings API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getRelationshipSettings, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const closeCallbackHandler = () => {
    try {
      setPopup(false);
      setPopupDel(false);
      setPopupEdit(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    trackComponent();
    const pageLoadApiCall = async () => {
      const relations = await sessionStorage.getWithExpiry("relationShip");
      if (relations) {
        setrelationList(relations);
      } else {
        getRelationshipSettings();
      }
    };
    pageLoadApiCall();
  }, []);

  return useObserver(() => {
    return (
      <Grid className="relation-container">
        <Grid className="relation">
          <Grid className={"relation-left"}>{`Relationship Settings`}</Grid>
          <LightTooltip
            title={ConstantVariables.RoleBasedAccessErrorMessage}
            disableHoverListener={
              globalStore.actionMenuSelectedUserRole ===
              globalStore.fullControlGroup
            }
          >
            <span>
              <Button
                variant="contained"
                disabled={
                  globalStore.actionMenuSelectedUserRole !==
                  globalStore.fullControlGroup
                }
                style={{
                  opacity:
                    globalStore.actionMenuSelectedUserRole !==
                    globalStore.fullControlGroup
                      ? 0.5
                      : 1,
                }}
                className={"newMaterialBtn"}
                startIcon={<AddIcon />}
                onClick={() => {
                  setPopup(true);
                  AppInsightMethods.trackAppInsightsEvent(
                    pageName,
                    AppInsightsConstants.Information,
                    `The  "Relationship" button has been clicked.`,
                    globalStore.loginUserEmail,
                    window.location.href,
                    ""
                  );
                }}
              >
                Relationship
              </Button>
            </span>
          </LightTooltip>
        </Grid>
        <Divider style={{ width: 1458, marginLeft: 60 }} />
        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}

        {!showSpinner && (
          <TableContainer className={"relationList_table_container"}>
            <Table
              className={`${classes.table} relationList_table`}
              aria-label="simple table"
            >
              <TableBody className={"relationListBody"}>
                {relationList
                  .filter(
                    (row: IGetRelationshipSettings) => row.isDeleted === false
                  )
                  .map((row: IGetRelationshipSettings) => (
                    <TableRow key={`${row.id}`} id={row.id}>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell titleCell overflowHidden"
                        title={row.relation}
                      >
                        <span className="bold">{row.relation}</span>
                      </TableCell>
                      <TableCell align={alignLeftText} className="bodyCell">
                        <></>
                      </TableCell>
                      <TableCell align={alignLeftText} className="bodyCell">
                        <></>
                      </TableCell>
                      <TableCell style={{ paddingLeft: 1100 }}>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              onClick={() => {
                                setPopupEdit(true);
                                globalStore.setActionMenuSelectedRelationship(
                                  row
                                );
                                globalStore.setOpenedModalRelation("edit");
                              }}
                              id={`${row.id}`}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Edit"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>

                      <TableCell>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              id={`${row.id}`}
                              onClick={() => {
                                setPopupDel(true);
                                globalStore.setActionMenuSelectedRelationship(
                                  row
                                );
                                globalStore.setOpenedModalRelation("delete");
                              }}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Delete"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!showSpinner && relationList.length === 0 && <NoResult />}
        <AddRelationship
          callBackGetRelatnList={getRelationshipSettings}
          popup={popupT}
          closeCallback={() => closeCallbackHandler()}
        />
        <DeleteRelationSettings
          callBackGetRelatnList={getRelationshipSettings}
          popup={popupDel}
          closeCallback={() => closeCallbackHandler()}
        />
        <EditRelationshipSettings
          callBackGetRelatnList={getRelationshipSettings}
          popup={popupEdit}
          closeCallback={() => closeCallbackHandler()}
        />
      </Grid>
    );
  });
}
