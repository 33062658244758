/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";
import DashboardTabs from "../Dashboard/DashboardTabs";
import DefaultTheme from "./DefaultTheme";
import CustomTheme from "./CustomTheme";
import { apiService } from "../../services/ApiService";
import { IGetRelationshipSettings } from "../../state/GlobalStateType";
import { IThemeColors } from "../../state/StoreTypes";

export default function Theme() {
  const globalStore = useGlobalStore();
  const pageName = "Theme";
  const appInsights = useAppInsightsContext();
  const [value, setValue] = useState("Default Theme");
  const [searchText, setSearchText] = useState("");
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    return (
      <>
        <Grid>
          <DashboardTabs
            parentTabName={pageName}
            selectedSubTab={value}
            handleTabChange={(e: any, newValue: string) => {
              setValue(newValue);
              AppInsightMethods.trackAppInsightsEvent(
                pageName,
                AppInsightsConstants.Information,
                `The "${newValue}" tab has been clicked on the ${pageName} section.`,
                globalStore.loginUserEmail,
                window.location.href,
                ""
              );
            }}
            searchText={searchText}
            setSearchText={(val: string) => setSearchText(val)}
          />
          {value === "Default Theme" && <DefaultTheme />}
          {value === "Custom Theme" && <CustomTheme />}
        </Grid>
      </>
    );
  });
}
