/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-plusplus: "error" */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Grid, LinearProgress, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import Shape from "../../assets/Shape.svg";
import { ReactComponent as CrossLogo } from "../../assets/crossLogo.svg";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  height: 158,
  width: 158,
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

export interface IPreviewProps {
  // handleFileChange: (file: File) => void;
  handleLogoChange: (file: File, type: string) => void;
  type: string;
}

export default function Previews(props: IPreviewProps) {
  const [files, setFiles] = useState<any>([]);
  const [title, setTitle] = useState("Drop your file here, or");
  const [stitle, ssetTitle] = useState("browse");
  const [logoFileSize, setLogoFileSize] = useState({});
  const [errorUpload, setErrorUpload] = useState<any>([]);

  const [faviconFileSize, setFaviconFileSize] = useState("");

  const [subTitleLogo, setSubTitleLogo] = useState(
    "Supports : PNG, JPEG, SVG | Max Size : 100 kb (Recommended more than 80x80 Px )"
  );
  const [subTitleFavicon, setSubTitleFavicon] = useState(
    "Supports : PNG, JPEG, SVG | Max Size : 100 kb (Recommended more than 40x40 Px)"
  );

  const [fileUploadSizeExceed, setFileUploadSizeExceed] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/svg+xml", "image/svg", "image/png", "image/jpeg"],
    maxSize: 1024 * 100, // 100kb,
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      setSubTitleLogo("");
      ssetTitle("");
      setTitle("");
      setSubTitleFavicon("");
      setLogoFileSize("");
      setFaviconFileSize("");
      if (acceptedFiles.length !== 0) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
      if (fileRejections.length !== 0) {
        setErrorUpload(fileRejections);
      }
    },
  });

  const remove = () => {
    setSubTitleLogo(
      "Supports : PNG, JPEG, SVG | Max Size : 100 kb (Recommended more than 80x80 Px )"
    );
    setSubTitleFavicon(
      "Supports : PNG, JPEG, SVG | Max Size : 100 kb (Recommended more than 40x40 Px)"
    );
    ssetTitle("browse");
    setTitle("Drop your file here, or");
    setFiles([]);
    setFileUploadSizeExceed("");
  };

  const thumbs = files.map((file: any) => (
    <div
      className="dropArea"
      style={{
        display: "inline-flex",
        borderRadius: 2,
        width: 170,
        height: 158,
        boxSizing: "border-box",
      }}
      key={file.name}
    >
      <div style={thumbInner}>
        <img
          src={file.preview}
          alt="imgss"
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <CrossLogo
        style={{ marginLeft: "-5px", marginTop: "-5px" }}
        onClick={remove}
      />
    </div>
  ));

  useEffect(() => {
    if (files.length === 0 && subTitleLogo === "") {
      // to validate file size-

      if (errorUpload[0]?.errors[0]?.code === "file-too-large") {
        setFileUploadSizeExceed("File size should not be more than 100kb.");
      } else {
        setFileUploadSizeExceed("File type should be png, svg, or jpeg.");
      }
    } else {
      setFileUploadSizeExceed("");
    }
    files.map((file) => {
      props.handleLogoChange(file, props.type);
      // image dimensions
      const image = new Image();
      image.addEventListener("load", () => {
        const dimensions = `${image.width}x${image.height}`;
        setLogoFileSize(dimensions);
        setFaviconFileSize(dimensions);
      });
      image.src = URL.createObjectURL(file);
      return 1;
    });

    // to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files, subTitleLogo, errorUpload]);

  const closeError = () => {
    setSubTitleLogo(
      "Supports : PNG, JPEG, SVG | Max Size : 100 kb (Recommended more than 80x80 Px )"
    );
    setSubTitleFavicon(
      "Supports : PNG, JPEG, SVG | Max Size : 100 kb (Recommended more than 40x40 Px)"
    );
    ssetTitle("browse");
    setTitle("Drop your file here, or");
    setFiles([]);
    setFileUploadSizeExceed("");
    setErrorUpload([]);
  };

  return (
    <div>
      <Grid item>
        <div {...getRootProps()}>
          <input {...getInputProps()} required />

          <Grid className="dropDragPOC">
            {thumbs}
            {files.map((file: any) => (
              <div
                style={{
                  marginLeft: "107px",
                }}
              >
                <p
                  key={file.name}
                  style={{
                    font: "normal normal 600 16px/24px Segoe UI",
                    color: "#000000",
                    marginBottom: "8px",
                  }}
                >
                  {file.name}
                </p>
                <p
                  key={file.name}
                  style={{
                    font: "italic normal 400 12px/16px Segoe UI",
                    color: "#ADADAD",
                    marginTop: "8px",
                  }}
                >
                  Format : {file.type} | Size: {file.size / 1000} kb (
                  {props.type === "logo" ? logoFileSize : faviconFileSize})
                </p>
              </div>
            ))}
            {fileUploadSizeExceed !== "" && (
              <Grid className="PreviewError">
                <div style={{ display: "flex" }}>
                  <LinearProgress
                    variant="determinate"
                    value={100}
                    color="error"
                    className="progressbarsErrorPreview"
                  />
                  <CloseIcon
                    className="closePreviewError"
                    onClick={closeError}
                  />
                </div>
                <Typography
                  color="#FF6262"
                  fontSize={12}
                  fontStyle={"italic"}
                  className="textError"
                >
                  {fileUploadSizeExceed}
                </Typography>
              </Grid>
            )}
            <div className={title !== "" ? "imgSet" : "dispNone"}>
              {title !== "" && (
                <img
                  src={Shape}
                  alt="Shape"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                  style={{ marginLeft: "270px" }}
                />
              )}
              <p className="dropDragText">
                {title}
                <span
                  style={{
                    color: "#1447D4",
                    fontWeight: "450",
                  }}
                >
                  &nbsp;{stitle}
                </span>
              </p>
              <em className="dropDragSubTitle">
                {props.type === "logo" ? subTitleLogo : subTitleFavicon}
              </em>
            </div>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
