/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import {
  useTrackMetric,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useGlobalStore } from "../../state/GlobalStore";

export const SnackBar = () => {
  const globalStore = useGlobalStore();

  const handleClick = () => {
    globalStore.setIsCustomized(false);
  };

  return useObserver(() => {
    setInterval(function () {
      globalStore.setIsCustomized(false);
    }, 5000);
    return (
      <>
        <div className="SnackBar">
          <CheckCircleOutlineIcon className="icon" />
          <p style={{ fontWeight: "600", marginRight: "5px" }}>
            {globalStore.customTabName}
          </p>
          <p> saved successfully</p>
          <CloseIcon className="close" onClick={handleClick} />
        </div>
      </>
    );
  });
};
