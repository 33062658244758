/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Backdrop, CircularProgress, Grid, Paper } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { observer, useObserver } from "mobx-react-lite";
import { Button, TextField } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import { useGlobalStore } from "../../state/GlobalStore";
import SearchIcon from "../../assets/search_icon.svg";
import CalendarIcon from "../../assets/calendar_icon.svg";
import FilterIcon from "../../assets/filter_icon.svg";
import ListIcon from "../../assets/list_icon.svg";
import ThemeIcon from "../../assets/theme.svg";
import OltivaIcon from "../../assets/oltiva_logo.svg";
import { AppInsightMethods } from "../../AppInsightMethods";
import { apiService } from "../../services/ApiService";
import { IThemeColors } from "../../state/StoreTypes";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: 320,
    height: 360,
    marginLeft: 190,
    marginTop: -100,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  root1: {
    flexGrow: 1,
    marginTop: -30,
    marginLeft: -50,
    width: 493,
    height: 261,
  },
  paper: {
    margin: "auto",
    maxWidth: 500,
    width: 493,
    height: 300,
    marginLeft: 54,
    // marginTop: 265,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  cardPaper: {
    height: 80,
    width: 80,
    marginBottom: 8,
  },
  cardGrid: {
    marginTop: 30,
    marginLeft: 103,
    width: 400,
    height: 217,
    position: "absolute",
    // left: "19%",
    // top: "32.5%",
  },
});

const useStylesButton = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  })
);

const ColorListItem = ({
  val,
  primaryColorHexValue,
  setPrimaryColorHexValue,
  secondaryColorHexValue,
  setSecondaryColorHexValue,
  pageBackgroundColorHexValue,
  setPageBackgroundColorHexValue,
  subPageBackgroundColorHexValue,
  setSubPageBackgroundColorHexValue,
  alertColorHexValue,
  setAlertColorHexValue,
  headingColorHexValue,
  setHeadingColorHexValue,
  bodyColorHexValue,
  setBodyColorHexValue,
}) => {
  const globalStore = useGlobalStore();
  const classes = useStyles();
  const [rgbaColor, setRgbaColor] = useState<any>("");

  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    setRgbaColor(event.target.value);
    val?.type === "Primary Color"
      ? setPrimaryColorHexValue(event.target.value as string)
      : val?.type === "Secondary Color"
      ? setSecondaryColorHexValue(event.target.value as string)
      : val?.type === "Page Background Color"
      ? setPageBackgroundColorHexValue(event.target.value as string)
      : val?.type === "Sub Page Background Color"
      ? setSubPageBackgroundColorHexValue(event.target.value as string)
      : val?.type === "Alert color"
      ? setAlertColorHexValue(event.target.value as string)
      : val?.type === "Headings Text Color"
      ? setHeadingColorHexValue(event.target.value as string)
      : setBodyColorHexValue(event.target.value as string);
  };

  return useObserver(() => {
    return (
      <Grid className="grid">
        <div
          className="customColor"
          style={{ backgroundColor: `${val.backgroundColor}` }}
        />
        <div className="customColor_details">
          <p className="customColorType">{val.type}</p>
          <p className="customColorDescription">{val.description}</p>
          <input
            type="color"
            className="colorPicker"
            value={
              val?.type === "Primary Color"
                ? primaryColorHexValue
                : val?.type === "Secondary Color"
                ? secondaryColorHexValue
                : val?.type === "Page Background Color"
                ? pageBackgroundColorHexValue
                : val?.type === "Sub Page Background Color"
                ? subPageBackgroundColorHexValue
                : val?.type === "Alert color"
                ? alertColorHexValue
                : val?.type === "Headings Text Color"
                ? headingColorHexValue
                : bodyColorHexValue
            }
            onChange={handleChange}
          />
        </div>
      </Grid>
    );
  });
};
const CustomTheme = () => {
  const globalStore = useGlobalStore();
  const pageName = "Theme";
  const appInsights = useAppInsightsContext();
  const [secondaryColorHexValue, setSecondaryColorHexValue] =
    useState<any>("#26008f");
  const [primaryColorHexValue, setPrimaryColorHexValue] =
    useState<any>("#5329c7");
  const [pageBackgroundColorHexValue, setPageBackgroundColorHexValue] =
    useState<any>("#f5f3f0");
  const [subPageBackgroundColorHexValue, setSubPageBackgroundColorHexValue] =
    useState<any>("#fff0f7");
  const [alertColorHexValue, setAlertColorHexValue] = useState<any>("#f9e1e1");
  const [headingColorHexValue, setHeadingColorHexValue] =
    useState<any>("#48484c");
  const [bodyColorHexValue, setBodyColorHexValue] = useState<any>("#6a4a06");
  const classes = useStyles();
  const buttonClasses = useStylesButton();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [isPreview, setIsPreview] = useState(false);
  const [isValid, setIsValid] = useState<any>("");
  const [themeName, setThemeName] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    trackComponent();
  }, []);

  const CustomColors = [
    {
      key: "Main Colors",
      values: [
        {
          type: "Primary Color",
          description:
            "Select Primary color (Header/buttons/action controls/tags)",
          backgroundColor: primaryColorHexValue,
          active: false,
        },
        {
          type: "Secondary Color",
          description: "Select Secondary color (buttons and tabs )",
          backgroundColor: secondaryColorHexValue,
          active: false,
        },
      ],
    },
    {
      key: "Background Colors",
      values: [
        {
          type: "Page Background Color",
          description: "Select page background color",
          backgroundColor: pageBackgroundColorHexValue,
          active: false,
        },
        {
          type: "Sub Page Background Color",
          description:
            "Select Territory color (left panel, inner pages background color )",
          backgroundColor: subPageBackgroundColorHexValue,
          active: false,
        },
        {
          type: "Alert color",
          description:
            "Select Highlight color ( Error notifications, alert messages,)",
          backgroundColor: alertColorHexValue,
          active: false,
        },
      ],
    },
  ];

  const handleApply = () => {
    globalStore.setIsCustomized(false);
    if (themeName !== "") {
      const themeData: IThemeColors = {
        id: "",
        themeName: globalStore.themeName,
        primaryColor: primaryColorHexValue,
        secondaryColor: secondaryColorHexValue,
        pageBackgroundColor: pageBackgroundColorHexValue,
        subPageBackgroundColor: subPageBackgroundColorHexValue,
        alertColor: alertColorHexValue,
        headingsTextColor: "",
        bodyTextColor: "",
        createdBy: "PublicTester8",
        createdTime: "",
      };
      setShowSpinner(true);
      globalStore.setThemeData(themeData);
      apiService
        .addCustomTheme(themeData)
        .then((res: any) => {
          if (res === "success") {
            globalStore.setIsCustomTheme(true);
            setShowSpinner(false);
            globalStore.setIsCustomized(true);
            globalStore.setCustomTabName(`${globalStore.themeName}`);
          }
        })
        .catch((error: any) => {
          setShowSpinner(false);
          globalStore.setIsCustomized(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at Edit Material API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } else {
      setIsValid("invalid");
    }
  };

  const handleSave = () => {
    globalStore.setPrimaryColorHexValue(primaryColorHexValue);
    globalStore.setSecondaryColorHexValue(secondaryColorHexValue);
    globalStore.setPageBackgroundColorHexValue(pageBackgroundColorHexValue);
    globalStore.setSubPageBackgroundColorHexValue(
      subPageBackgroundColorHexValue
    );
    globalStore.setAlertColorHexValue(alertColorHexValue);
    globalStore.setHeadingColorHexValue(headingColorHexValue);
    globalStore.setBodyColorHexValue(bodyColorHexValue);
  };

  return (
    <Grid className="customThemeGrid">
      <div className="themeNameContainer">
        <p className="themeName">Theme Name</p>
        <TextField
          error={isValid === "invalid"}
          helperText={isValid === "invalid" ? "Enter Theme Name" : ""}
          className="textBox"
          value={themeName}
          id="outlined-basic"
          variant="outlined"
          onChange={(e) => {
            setThemeName(e.target.value);
          }}
        />
        <Button
          className={buttonClasses.root}
          color="primary"
          onClick={() => {
            themeName === ""
              ? setIsValid("invalid")
              : (globalStore.setThemeName(themeName), setIsValid(""));
          }}
        >
          Add
        </Button>
      </div>
      {CustomColors?.map((colors) => (
        <div className="customTheme">
          <p className="colorType">{colors.key}</p>
          <div className="customColor_Container">
            {colors.values?.map((val) => (
              <ColorListItem
                val={val}
                primaryColorHexValue={primaryColorHexValue}
                setPrimaryColorHexValue={setPrimaryColorHexValue}
                secondaryColorHexValue={secondaryColorHexValue}
                setSecondaryColorHexValue={setSecondaryColorHexValue}
                pageBackgroundColorHexValue={pageBackgroundColorHexValue}
                setPageBackgroundColorHexValue={setPageBackgroundColorHexValue}
                subPageBackgroundColorHexValue={subPageBackgroundColorHexValue}
                setSubPageBackgroundColorHexValue={
                  setSubPageBackgroundColorHexValue
                }
                alertColorHexValue={alertColorHexValue}
                setAlertColorHexValue={setAlertColorHexValue}
                headingColorHexValue={headingColorHexValue}
                setHeadingColorHexValue={setHeadingColorHexValue}
                bodyColorHexValue={bodyColorHexValue}
                setBodyColorHexValue={setBodyColorHexValue}
              />
            ))}
          </div>
        </div>
      ))}
      <Grid
        className="previewText"
        style={
          isPreview
            ? { color: "#8A8A8A", marginLeft: "61px", marginTop: "39px" }
            : { color: "#1447D4", marginLeft: "61px", marginTop: "39px" }
        }
        onClick={() => {
          setIsPreview(true);
        }}
      >
        Preview
      </Grid>
      {isPreview && (
        <Grid className="theme-Container">
          <div className={classes.root1}>
            <Paper className={classes.paper}>
              <Grid
                className="themeContainer-header"
                style={{ backgroundColor: `${primaryColorHexValue}` }}
              >
                <img
                  className="oltivaIcon"
                  src={OltivaIcon}
                  alt="oltiva_icon"
                />
                OLTIVA HEALTHCARE
              </Grid>
              <Grid
                className="themeContainer-appointment"
                style={{
                  backgroundColor: `${subPageBackgroundColorHexValue}`,
                }}
              >
                <p
                  className="heading"
                  style={{ color: `${headingColorHexValue}` }}
                >
                  Appointments
                </p>
                <Button
                  className="button"
                  style={{
                    backgroundColor: `${secondaryColorHexValue}`,
                  }}
                >
                  New Appointment
                </Button>
              </Grid>
              <Grid container spacing={2} style={{ margin: "-8px" }}>
                <Grid
                  item
                  className="themeContainer-sideBar"
                  style={{
                    backgroundColor: `${subPageBackgroundColorHexValue}`,
                  }}
                >
                  {/* <p className="heading">Appointments</p> */}
                  <div>
                    <img
                      className="searchIcon"
                      src={SearchIcon}
                      alt="search_icon"
                    />
                    <p
                      className="addProviderText"
                      style={{ color: `${bodyColorHexValue}` }}
                    >
                      Add Provider
                    </p>
                    <AddIcon className="addIcon" />
                  </div>
                  <div className="providerList">
                    <p style={{ color: `${bodyColorHexValue}` }}>
                      My Providers
                    </p>
                    <p
                      className="active"
                      style={{
                        color: `${bodyColorHexValue}`,
                        backgroundColor: `${alertColorHexValue}`,
                      }}
                    >
                      Dr. Krystal McKinney
                    </p>
                    <p style={{ color: `${bodyColorHexValue}` }}>
                      Dr. Paul Johnson
                    </p>
                    <p style={{ color: `${bodyColorHexValue}` }}>
                      Dr. Melina Malik
                    </p>
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm container> */}
                <div className="schedule">
                  <p
                    className="text"
                    style={{ color: `${headingColorHexValue}` }}
                  >
                    Today's Schedule
                  </p>
                  <img src={CalendarIcon} alt="" />
                  <p
                    className="day"
                    style={{ color: `${headingColorHexValue}` }}
                  >
                    Today
                  </p>
                  <ArrowBackIosNewIcon className="backIcon" />
                  <ArrowForwardIosIcon className="frontIcon" />
                  <p
                    className="date"
                    style={{ color: `${headingColorHexValue}` }}
                  >
                    03 June 2021
                  </p>
                </div>
                <div className="statusTabs">
                  {globalStore.themeStatusTabs?.map((tabs) => (
                    <p
                      className={`tabs ${tabs.className}`}
                      style={{ color: `${secondaryColorHexValue}` }}
                    >
                      {tabs.name}({tabs.count})
                    </p>
                  ))}
                  <div
                    className="searchBox"
                    style={{
                      backgroundColor: `${alertColorHexValue}`,
                    }}
                  >
                    <img
                      className="searchIcon"
                      src={SearchIcon}
                      alt="search_icon"
                    />
                    <p style={{ color: `${bodyColorHexValue}` }}>Search</p>
                  </div>
                  <div className="filter">
                    <img
                      className="filterIcon"
                      src={FilterIcon}
                      alt="filter_icon"
                    />
                    <p style={{ color: `${bodyColorHexValue}` }}>Filter</p>
                  </div>
                  <div className="icons">
                    <img className="listIcon" src={ListIcon} alt="list_icon" />
                    <img className="cardIcon" src={ThemeIcon} alt="card_icon" />
                  </div>
                </div>
                <Grid
                  container
                  className={classes.cardGrid}
                  spacing={2}
                  style={{
                    backgroundColor: `${pageBackgroundColorHexValue}`,
                  }}
                >
                  {globalStore.themeCardValues?.map((value) => (
                    <Grid className="cardPaperGrid" item>
                      <Paper className="cardPaper">
                        <div className="name">{value.name}</div>
                        <div
                          className="statusColor"
                          style={
                            value.status === "In Progress"
                              ? { backgroundColor: "#E9F7E5" }
                              : value.status === "Waiting"
                              ? { backgroundColor: "#F9F9E6" }
                              : value.status === "Delayed"
                              ? { backgroundColor: "#F9E9E8" }
                              : value.status === "Scheduled"
                              ? { backgroundColor: "#EFEFEF" }
                              : { backgroundColor: "#A8A8A8" }
                          }
                        >
                          <p
                            className="status"
                            style={
                              value.status === "In Progress"
                                ? { color: "#18760A" }
                                : value.status === "Waiting"
                                ? { color: "#70590D" }
                                : value.status === "Delayed"
                                ? { color: "#972424" }
                                : value.status === "Scheduled"
                                ? { color: "#979797" }
                                : { color: "#FFFFFF" }
                            }
                          >
                            {value.status}
                          </p>
                        </div>
                        <div className="appointment">
                          <p className="apptText">{value.ApptText}</p>
                          <p className="date">{value.Date}</p>
                        </div>
                        <div className="providerDetails">
                          <p className="providerText">{value.ProviderText}</p>
                          <p className="provider">{value.Provider}</p>
                        </div>
                      </Paper>
                    </Grid>
                  ))}
                  {/* </Grid> */}
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
      )}
      <div className="buttons">
        <Button className="cancelButton">Cancel</Button>
        <Button className="saveButton" onClick={handleApply}>
          Save & Apply
        </Button>
      </div>
      {showSpinner && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 999,
          }}
          open={showSpinner}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Grid>
  );
  // });
};
export default observer(CustomTheme);
