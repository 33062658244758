/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import save from "../../assets/save.png";
import cancel from "../../assets/cancel.png";
import { IEditReminderList } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { AppInsightsConstants } from "../../Constants";

const useStyle = makeStyles({
  dialog: {
    width: 720,
    height: 547,
    maxWidth: 720,
  },
});

interface IreminderButton {
  callBackGetreminderList: () => void;
  popup: boolean;
  closeCallback: () => void;
}

const reminderOption = [
  "0 minute",
  "5 minutes",
  "10 minutes",
  "15 minutes",
  "30 minutes",
  "1 hour",
  "2 hours",
  "3 hours",
  "4 hours",
  "5 hours",
  "6 hours",
  "7 hours",
  "8 hours",
  "9 hours",
  "10 hours",
  "11 hours",
  "0.5 days",
  "18 hours",
  "1 day",
  "2 days",
  "3 days",
  "4 days",
  "5 days",
  "1 week",
  "2 weeks",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditReminder(props: IreminderButton) {
  const globalStore = useGlobalStore();
  const pageName = "Edit Reminder";
  const classes = useStyle();
  const helperAdd = helperMethods();
  const [reminderIn, setreminder] = useState<string>("");
  const [showReminderError, setShowreminderError] = useState("");
  const [showreminderTypeErrorreminder, setShowreminderTypeErrorreminder] =
    useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (globalStore.actionMenuSelectedReminder) {
      setreminder(globalStore.actionMenuSelectedReminder?.minutesBefore);
    }
  }, [globalStore.actionMenuSelectedReminder]);

  const onCancelClickEventHandler = () => {
    try {
      globalStore.setOpenedModalReminder("none");
      setreminder("");
      setShowreminderError("");
      setShowreminderTypeErrorreminder("");
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Edit Reminder close/cancel button click event.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const validate = () => {
    try {
      let validationFailed = false;

      if (reminderIn === "" || reminderIn.trim().length === 0) {
        validationFailed = true;
        setShowreminderError("Please enter reminder");
      } else {
        setShowreminderError("");
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Edit reminder API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  const updateField = () => {
    try {
      setreminder("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at updateField, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const editReminder = () => {
    try {
      let materialData = false;
      materialData = validate();
      if (materialData) {
        return;
      }
      const reminderObj: IEditReminderList = {
        id: globalStore.actionMenuSelectedReminder?.id,
        minutesBefore: reminderIn,
        uploadedBy: "publicTester3",
        uploadedTime: "",
      };
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Edit Reminder Ok button click event. Remainder: ${reminderIn}, Id: ${globalStore.actionMenuSelectedReminder?.id}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setShowSpinner(true);
      apiService
        .updateReminder(reminderObj)
        .then((res) => {
          if (res === "success") {
            helperAdd.setSuccessMessageEvent("Reminder updated successfully!");
          }
          updateField();
          globalStore.setOpenedModalReminder("none");
          props.closeCallback();
          helperAdd.setSuccessMessageEvent("Reminder updated successfully!");
          props.callBackGetreminderList();
          setShowSpinner(false);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at add reminder API call, Component: ${pageName} Error : ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at add reminder API, Component: ${pageName} Error : ${error}`
      );
    }
  };

  /* onBlur handler for reminder field */
  const onBlurreminder = () => {
    try {
      if (reminderIn === "" && reminderIn.trim().length === 0) {
        setShowreminderError("Please enter reminder");
      } else {
        setShowreminderError("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurreminder, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const handleChange = (event: SelectChangeEvent) => {
    setreminder(event.target.value);
  };

  return useObserver(() => {
    return (
      <div>
        <Dialog
          onClose={() => globalStore.setOpenedModalReminder("none")}
          open={props.popup}
          classes={{ paper: classes.dialog }}
          className="reminderContainer"
        >
          <DialogTitle className="reminderTitle">
            <b style={{ marginRight: 351 }}>Edit Appointment Reminder</b>
            <IconButton
              onClick={() => {
                onCancelClickEventHandler();
                props.closeCallback();
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="reminderContent">
            <Grid className={"reminder-text"}>
              Select Reminder
              <span className={"material-title"}>*</span>
            </Grid>
            <FormControl className={"reminderText-box"}>
              <Select
                value={reminderIn}
                onChange={handleChange}
                displayEmpty
                placeholder="Select Reminder"
                inputProps={{ "aria-label": "Without label" }}
                error={!!showreminderTypeErrorreminder}
                onBlur={() => onBlurreminder()}
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span className={"selectFirstChild"}>
                        Select Reminder
                      </span>
                    );
                  }

                  return selected;
                }}
              >
                {reminderOption.map((rem) => (
                  <MenuItem key={rem} value={rem}>
                    {rem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={"material-error"}>{showReminderError || ""}</div>
          </DialogContent>
          <Grid>
            <DialogActions className="btnClick">
              <Button
                onClick={() => {
                  onCancelClickEventHandler();
                  props.closeCallback();
                }}
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button onClick={editReminder}>
                <img
                  src={save}
                  alt="save"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
            {showSpinner && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 999,
                }}
                open={showSpinner}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Grid>
        </Dialog>
      </div>
    );
  });
}
