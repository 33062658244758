/* eslint-disable react-hooks/rules-of-hooks */
import { useGlobalStore } from "../state/GlobalStore";

export function helperMethods() {
  const globalStore = useGlobalStore();
  const setSuccessMessageEvent = (mgs: string) => {
    globalStore.setSuccessMessage(mgs);
    setTimeout(() => {
      globalStore.setSuccessMessage("");
    }, 3000);
  };

  return {
    setSuccessMessageEvent,
  };
}
