/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@material-ui/core/styles";

import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { observer, useObserver } from "mobx-react-lite";
import Button from "@mui/material/Button";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/GlobalStore";
import FontWeightDropdown from "./FontWeightDropdown";
import FonstSizeDropdown from "./FontSizeDropdown";
import FontFamilyDropdown from "./FontFamilyDropdown";
import LetterSpacing from "./LetterSpacing";
import Indentation from "./Indentation";
import LineHeight from "./LineHeight";
import LetterStyle from "./LetterStyle";
import Dropdown from "./Dropdown";
import { apiService } from "../../services/ApiService";
import { ConstantVariables } from "../../Constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  drawer: {
    width: 350,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 350,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const CustomFonts = () => {
  const globalStore = useGlobalStore();
  const pageName = "CustomFonst";
  const appInsights = useAppInsightsContext();
  const [popupId, setPopupId] = useState<any>("");
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);

  const [textVal, settextVal] = useState("abcd");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [selectedFontFamily, setSelectedFontFamily] =
    React.useState<string>("Segoe UI");
  const open = Boolean(anchorEl);

  useEffect(() => {
    trackComponent();
  }, []);

  const openPopup = (fontName, heading) => {
    setPopupId(fontName + heading);
    if (heading === "Heading 1") {
      settextVal(ConstantVariables.heading1Info);
    }
    if (heading === "Heading 2") {
      settextVal(ConstantVariables.heading2Info);
    }
    if (heading === "Heading 3") {
      settextVal(ConstantVariables.heading3Info);
    }
    if (heading === "Body 1") {
      settextVal(ConstantVariables.body1Info);
    }
    if (heading === "Body 2") {
      settextVal(ConstantVariables.body2Info);
    }
    if (heading === "Body 3") {
      settextVal(ConstantVariables.body3Info);
    }
    if (heading === "Body 4") {
      settextVal(ConstantVariables.body4Info);
    }
    if (heading === "Body 5") {
      settextVal(ConstantVariables.body5Info);
    }
    if (heading === "Body 6") {
      settextVal(ConstantVariables.body6Info);
    }
    if (heading === "Body 7") {
      settextVal(ConstantVariables.body7Info);
    }
    if (heading === "Button") {
      settextVal(ConstantVariables.buttonInfo);
    }
    if (heading === "Label") {
      settextVal(ConstantVariables.labelInfo);
    }
  };
  const closePopup = (itemId) => {
    setPopupId("");
  };

  const saveCustomValueApi = (val) => {
    setShowSpinner(true);
    const customFontVal = { ...val };
    customFontVal.fontType = "Default";
    customFontVal.uploadedBy = globalStore.loginUserEmail;
    delete customFontVal.isRecentlyAdded;

    apiService
      .saveCustomValueApi(customFontVal)
      .then((res: any) => {
        if (res === "Success") {
          setShowSpinner(false);
          globalStore.setShowCustomSavePop(true);
          setTimeout(() => {
            globalStore.setShowCustomSavePop(false);
          }, 5000);
        }
      })
      .catch((error: any) => {
        setShowSpinner(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at add fontdetails API call, Component: ${pageName}, Error: ${error}`
        );
      });
  };

  const getDropdownValue = (fontWeightVal: string, fontStyleVal: string) => {
    if (fontWeightVal === "300" && fontStyleVal === "normal") {
      return "Light";
    }
    if (fontWeightVal === "400" && fontStyleVal === "normal") {
      return "Regular";
    }
    if (fontWeightVal === "350" && fontStyleVal === "normal") {
      return "Semilight";
    }
    if (fontWeightVal === "600" && fontStyleVal === "normal") {
      return "Semibold";
    }
    if (fontWeightVal === "700" && fontStyleVal === "normal") {
      return "Bold";
    }
    if (fontWeightVal === "900" && fontStyleVal === "normal") {
      return "Black";
    }
    if (fontWeightVal === "400" && fontStyleVal === "Italic") {
      return "Italic";
    }
    if (fontWeightVal === "600" && fontStyleVal === "Italic") {
      return "Semibold Italic";
    }
    if (fontWeightVal === "700" && fontStyleVal === "Italic") {
      return "Bold Italic";
    }
    if (fontWeightVal === "350" && fontStyleVal === "Italic") {
      return "Semilight Italic";
    }
    return "Light";
  };

  /* Method to handle tooltip information open */
  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  /* Method to handle tooltip information close */
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return useObserver(() => {
    return (
      <Grid className="customFontGrid">
        <div className="cardMain">
          <Dropdown />
          <>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {globalStore.CustomCardValue?.fontDetails?.map((subItem) => (
                <div
                  className="editCard"
                  key={globalStore.CustomCardValue.fontName + subItem.heading}
                >
                  <Card
                    sx={{ minWidth: 275, width: 340 }}
                    className="cardDefaultFont"
                  >
                    <CardContent className="cardContentDefaultFont">
                      <Typography
                        className="Heading"
                        style={{
                          font: `${subItem.fontStyle} ${subItem.fontWeight} ${subItem.fontSize}/${subItem.lineHeight} ${subItem.fontFamily}`,
                          textIndent: subItem.textIndent,
                          letterSpacing: subItem.letterSpacing,
                          textTransform: subItem?.textTransform,
                          textDecoration: subItem.textDecoration,
                        }}
                      >
                        {subItem.heading}
                      </Typography>
                      <Typography className="fontSizeWeight">
                        {subItem.fontFamily},{" "}
                        {getDropdownValue(
                          subItem.fontWeight,
                          subItem.fontStyle
                        )}
                      </Typography>
                      <Typography className="lineHeight">
                        {subItem.fontSize}/{subItem.lineHeight}
                      </Typography>
                      <EditIcon
                        className="editIcon"
                        onClick={() =>
                          openPopup(
                            globalStore.CustomCardValue.fontName,
                            subItem.heading
                          )
                        }
                      />
                    </CardContent>
                  </Card>
                  <div
                    className="fontPopup"
                    id={globalStore.CustomCardValue.fontName + subItem.heading}
                    style={{
                      visibility:
                        popupId ===
                        `${globalStore.CustomCardValue.fontName}${subItem.heading}`
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    <Typography
                      aria-owns={
                        open
                          ? globalStore.CustomCardValue.fontName +
                            subItem.heading
                          : undefined
                      }
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      <InfoOutlinedIcon className={"InfoIcon"} />
                    </Typography>
                    {popupId ===
                      `${globalStore.CustomCardValue.fontName}${subItem.heading}` && (
                      <Popover
                        id={
                          globalStore.CustomCardValue.fontName + subItem.heading
                        }
                        className={classes.popover}
                        classes={{
                          paper: classes.paper,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <Typography>
                          <Grid
                            className={"hoverToolTipContent"}
                            style={{
                              font: "italic normal 400 12px/16px Segoe UI",
                              color: "#ADADAD",
                            }}
                          >
                            {textVal}
                          </Grid>
                        </Typography>
                      </Popover>
                    )}

                    <div className="PopupFontUpperText">
                      {" "}
                      <Typography
                        className="Heading"
                        style={{
                          font: `${subItem.fontStyle} ${subItem.fontWeight} ${subItem.fontSize}/${subItem.lineHeight} ${subItem.fontFamily}`,
                          textIndent: subItem.textIndent,
                          letterSpacing: subItem.letterSpacing,
                          textTransform: subItem?.textTransform,
                          textDecoration: subItem.textDecoration,
                        }}
                      >
                        {subItem.heading}
                      </Typography>
                      <Typography className="fontSizeWeight">
                        {subItem.fontFamily},{" "}
                        {getDropdownValue(
                          subItem.fontWeight,
                          subItem.fontStyle
                        )}
                      </Typography>
                      <Typography className="lineHeight">
                        {subItem.fontSize}/{subItem.lineHeight}
                      </Typography>
                    </div>
                    <div>
                      <FontFamilyDropdown fontFamily={subItem.fontFamily} />
                    </div>

                    <div className="horizontalAlign">
                      <div>
                        <FonstSizeDropdown
                          fontSize={subItem.fontSize}
                          headingName={subItem.heading}
                        />
                      </div>
                      <div>
                        <FontWeightDropdown
                          fontWeight={subItem.fontWeight}
                          fontStyle={subItem.fontStyle}
                          headingName={subItem.heading}
                        />
                      </div>
                    </div>
                    <div className="horizontalAlign">
                      {" "}
                      <div>
                        <LetterSpacing
                          letterSpacing={subItem.letterSpacing}
                          headingName={subItem.heading}
                        />
                      </div>
                      <div>
                        <LineHeight
                          lineHeight={subItem.lineHeight}
                          headingName={subItem.heading}
                        />
                      </div>
                      <div>
                        <Indentation
                          textIndent={subItem.textIndent}
                          headingName={subItem.heading}
                        />
                      </div>
                    </div>
                    <div className="letterStyle">
                      <LetterStyle
                        textTransform={subItem.textTransform}
                        textDecoration={subItem.textDecoration}
                        headingName={subItem.heading}
                      />
                    </div>
                    <div className="popupButtons">
                      <Typography
                        style={{
                          cursor: "pointer",
                          font: "normal normal 600 18px/24px Segoe UI",
                          color: "#8A8A8A",
                        }}
                        onClick={() => {
                          closePopup(
                            globalStore.CustomCardValue.fontName +
                              subItem.heading
                          );
                          globalStore.cancelCustomFontsPopup();
                        }}
                      >
                        Cancel
                      </Typography>
                      <Typography
                        style={{
                          cursor: "pointer",
                          font: "normal normal 600 18px/24px Segoe UI",
                          color: "#4A8409",
                        }}
                        onClick={() => {
                          closePopup(
                            globalStore.CustomCardValue.fontName +
                              subItem.heading
                          );
                          globalStore.saveCustomFontsPopup(
                            globalStore.CustomCardValue
                          );
                        }}
                      >
                        Save
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {showSpinner && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 999,
                }}
                open={showSpinner}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </>
        </div>
        <div className="buttons">
          <Button className="cancelButton">Cancel</Button>
          <Button
            className="saveButton"
            onClick={() => {
              saveCustomValueApi(globalStore.CustomCardValue);
            }}
          >
            Save & Apply
          </Button>
        </div>
      </Grid>
    );
  });
};
export default observer(CustomFonts);
