/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-dynamic-require */
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { ReactComponent as Home } from "../../assets/System_State.svg";
import { ReactComponent as Config } from "../../assets/Configuration.svg";
import { ReactComponent as Summary } from "../../assets/SummaryIcon.svg";
import { ReactComponent as Resources } from "../../assets/ResourcesIcon.svg";
import { ReactComponent as Appointment } from "../../assets/Appointments.svg";
import { ReactComponent as Notification } from "../../assets/Notifications.svg";
import { ReactComponent as Settings } from "../../assets/Settings.svg";
import { ReactComponent as General } from "../../assets/General.svg";
import { ReactComponent as Movie } from "../../assets/Audio_Video.svg";
import { ReactComponent as User } from "../../assets/Relationship.svg";
import { ReactComponent as Language } from "../../assets/Language.svg";
import { ReactComponent as TestAccount } from "../../assets/Test_Accounts.svg";
import { ILeftNav, ISubTab } from "../../state/GlobalStateType";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";

const leftNav: ILeftNav[] = [
  {
    tabName: "system state",
    icon: "System_State.svg",
    iconName: "Home Icon",
    className: "",
    subTabs: [
      {
        tabName: "Summary",
        icon: "SummaryIcon.svg",
        iconName: "Summary Icon",
        className: "",
      },
    ],
  },
  {
    tabName: "configuration",
    icon: "Configuration.svg",
    iconName: "Config Icon",
    className: "parentActive",
    subTabs: [
      {
        tabName: "Resources",
        icon: "ResourcesIcon.svg",
        iconName: "Resources Icon",
        className: "active",
      },
      {
        tabName: "Appointments",
        icon: "Appointments.svg",
        iconName: "Appointments Icon",
        className: "",
      },
      {
        tabName: "Notifications",
        icon: "Notifications.svg",
        iconName: "Notifications Icon",
        className: "",
      },
    ],
  },
  {
    tabName: "settings",
    icon: "Settings.svg",
    iconName: "Settings Icon",
    className: "",
    subTabs: [
      {
        tabName: "General",
        icon: "General.svg",
        iconName: "General Icon",
        className: "",
      },
      {
        tabName: "Audio/ Video",
        icon: "Audio_Video.svg",
        iconName: "Video Icon",
        className: "",
      },
      {
        tabName: "Relationship",
        icon: "Relationship.svg",
        iconName: "Relationship Icon",
        className: "",
      },
      {
        tabName: "Language",
        icon: "Language.svg",
        iconName: "Language Icon",
        className: "",
      },
      {
        tabName: "Test Accounts",
        icon: "Test_Accounts.svg",
        iconName: "TestAccount",
        className: "",
      },
    ],
  },
];

export interface ILeftNavProps {
  ontabChangeEvent: (s: string) => void;
}

export default function LeftNav(props: ILeftNavProps) {
  const pageName = "LeftNav";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const [navBar, setNavBar] = useState<ILeftNav[]>(leftNav);
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  const menuItemClickEvent = (parentTab: string, childTab: string) => {
    try {
      const clonedLeftTabs: ILeftNav[] = JSON.parse(JSON.stringify(leftNav));
      clonedLeftTabs.forEach((value: ILeftNav) => {
        value.className = "";
        value.subTabs.forEach((val: ISubTab) => {
          val.className = "";
        });
      });

      if (parentTab === leftNav[0].tabName) {
        clonedLeftTabs.filter(
          (value: ILeftNav) => value.tabName === parentTab
        )[0].className = "parentActiveSystemState";
      } else {
        clonedLeftTabs.filter(
          (value: ILeftNav) => value.tabName === parentTab
        )[0].className = "parentActive";
      }

      clonedLeftTabs
        .filter((value: ILeftNav) => value.tabName === parentTab)[0]
        .subTabs.filter(
          (val: ISubTab) => val.tabName === childTab
        )[0].className = "active";
      setNavBar(clonedLeftTabs);
      globalStore.setTabName(childTab);
      props.ontabChangeEvent(childTab);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `The ${childTab} menu has been Clicked. Parent Menu is ${parentTab}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at menuItemClickEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    globalStore.tabName === "Summary" &&
      menuItemClickEvent("system state", "Summary");
  }, [globalStore.tabName]);

  const getSvgIcon = (tabName: string): JSX.Element => {
    try {
      switch (tabName.toLowerCase()) {
        case "system state":
          return <Home />;
        case "summary":
          return <Summary />;
        case "configuration":
          return <Config />;
        case "resources":
          return <Resources />;
        case "appointments":
          return <Appointment />;
        case "notifications":
          return <Notification />;
        case "settings":
          return <Settings />;
        case "general":
          return <General />;
        case "audio/ video":
          return <Movie />;
        case "relationship":
          return <User />;
        case "language":
          return <Language />;
        case "test accounts":
          return <TestAccount />;

        default:
          return <Home />;
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getSvgIcon, Component: ${pageName}, Error: ${error}`
      );
      return <Home />;
    }
  };
  return useObserver(() => {
    return (
      <Grid className={"dashboard_sidebar"}>
        {navBar.map((left: ILeftNav) => (
          <Grid
            className={"sidebar_parent_container"}
            key={`${left.tabName}`}
            id={`${left.tabName}Id`}
          >
            <Grid className={`sidebar_parent ${left.className}`}>
              {getSvgIcon(left.tabName)}
              <span>{left.tabName}</span>
            </Grid>
            {left.subTabs.map((sub: ISubTab) => {
              return (
                <Grid
                  className={
                    sub.tabName === "Notifications"
                      ? `sidebar_child_Notify ${sub.className}`
                      : `sidebar_child ${sub.className}`
                  }
                  onClick={() => menuItemClickEvent(left.tabName, sub.tabName)}
                  key={`${sub.tabName}`}
                  id={`${sub.tabName}Id`}
                >
                  {getSvgIcon(sub.tabName)}
                  <span>{sub.tabName}</span>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Grid>
    );
  });
}
