/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { FormControl, Select, MenuItem } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import { ITestProvider } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";
import configData from "../../config.json";

export interface IOrganizerFormProps {
  onChangeHandler: (provider: ITestProvider) => void;
  providerState: ITestProvider;
  showNameRequiredMgs: boolean;
}

interface IAPIProvider {
  providerId: string;
  displayName: string;
  givenName: string;
  jobTitle: string;
  mail: string | null;
  mobilePhone: string | null;
  officeLocation: string | null;
  surname: string;
  userPrincipalName: string;
  employeeId: string | null;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuPropsStyle = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function OrganizerForm(props: IOrganizerFormProps) {
  const pageName = "OrganizerForm";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [providerList, setProviderList] = useState<ITestProvider[]>([]);

  useEffect(() => {
    trackComponent();
    const fetchProviderList = async () => {
      const cacheProviderdata = window.sessionStorage.getItem("providerList");
      if (cacheProviderdata) {
        const cacheProviderObj = JSON.parse(cacheProviderdata);
        setProviderList([...cacheProviderObj]);
      } else {
        apiService
          .fetchProvider(configData.PROVIDER_GROUP_ID)
          .then((data: IAPIProvider[]) => {
            const formatedProviderList: ITestProvider[] = [];
            data.forEach((val: IAPIProvider) => {
              const apiProvider: ITestProvider = {} as ITestProvider;
              apiProvider.providerId = val.providerId;
              apiProvider.firstName = val.givenName;
              apiProvider.middleName = "";
              apiProvider.lastName = val.surname;
              apiProvider.title = val.jobTitle;
              apiProvider.email = val.userPrincipalName;
              apiProvider.phone = val.mobilePhone ?? "";
              formatedProviderList.push(apiProvider);
            });
            setProviderList(formatedProviderList);
            window.sessionStorage.setItem(
              "providerList",
              JSON.stringify(formatedProviderList)
            );
          })
          .catch((error: any) => {
            AppInsightMethods.TrackAppInsightsException(
              `Error at getProviderList API call, Component: ${pageName}, Error: ${error}`
            );
          });
      }
    };
    fetchProviderList();
  }, []);
  /* onChange handler for select provider dropdown */
  const onSelectBoxChange = (providerId: string) => {
    try {
      const newObject: Array<ITestProvider> = JSON.parse(
        JSON.stringify(providerList)
      );
      const selectedProvider = newObject.filter(
        (val: ITestProvider) => val.providerId === providerId
      )[0];

      props.onChangeHandler(selectedProvider);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSelectBoxChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    globalStore.setProvider(props.providerState);
    return (
      <div className="provider">
        <section>
          <div className="providerName">
            <FormControl
              className="providerSelect-box"
              sx={{ width: 650, borderColor: "#c8d0e0" }}
            >
              <Select
                variant="outlined"
                id="providerName"
                MenuProps={MenuPropsStyle}
                error={props.showNameRequiredMgs}
                value={
                  props.providerState.providerId
                    ? props.providerState.providerId
                    : "0"
                }
                onChange={(e) => {
                  onSelectBoxChange(String(e.target.value));
                }}
                inputProps={{ "aria-label": "Without label" }}
                className="dropdown"
              >
                <MenuItem key={"0"} value={"0"} disabled>
                  Select provider
                </MenuItem>
                {providerList && providerList.length > 0 ? (
                  providerList.map((val: ITestProvider) => {
                    return (
                      <MenuItem
                        id={`providerName-MenuItem-${val.email}`}
                        key={val.providerId}
                        value={val.providerId}
                      >
                        {`${val.firstName} ${val.lastName}`}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    id={`providerName-MenuItem-loading`}
                    key={"99"}
                    value={"99"}
                  >
                    {`Loading...`}
                  </MenuItem>
                )}
              </Select>
              {props.showNameRequiredMgs && (
                <div className="material-error">Please select provider</div>
              )}
            </FormControl>
          </div>
        </section>
      </div>
    );
  });
}

export default OrganizerForm;
