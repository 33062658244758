/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Menu,
  MenuItem,
  Switch,
  Backdrop,
} from "@mui/material";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import AddIcon from "@mui/icons-material/Add";
import { IUpdateState, IPostUserRole } from "../../state/StoreTypes";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import { IVideo } from "../../state/GlobalStateType";
import { LightTooltip } from "../Relationship/RelationshipList";
import Pagination from "../Pagination/Pagination";
import { NoResult } from "../Resources/Resources";
import { useSortableData } from "../Dashboard/useSortableData";
import { apiService } from "../../services/ApiService";
import { ReactComponent as DownArrowDisabled } from "../../assets/down-arrow-disabled-icon.svg";
import { ReactComponent as DownArrow } from "../../assets/down-arrow-icon.svg";
import { ReactComponent as UpArrow } from "../../assets/up-arrow-icon.svg";
import { sessionStorage } from "../../state/SessionStorage";
import configData from "../../config.json";
import ViewVideo from "./ViewVideo";
import DeleteVideo from "./DeleteVideo";
import EditVideo from "./EditVideo";
import AddVideo from "./AddVideo";

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 56,
  height: 24,
  padding: 0,
  display: "flex",

  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(32px)",
      color: "#4A8409",
      "&.Mui-disabled": {
        color: "#E7E7E9",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#E7E7E9" : "#E9F7E5",
        border: "1px solid #4A8409",
      },
    },
    color: "#8a8a8a",
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 19,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 21,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(231, 231, 233, 1)",
    border:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "2px solid #ADADAD69",
    boxSizing: "border-box",
  },
}));

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

interface IVideoListProps {
  searchText: string;
}

export default function RelaxationVideosList(props: IVideoListProps) {
  const pageName = "RelaxationVideosList";
  const appInsights = useAppInsightsContext();
  const alignLeftText = "left";
  const itemsPerPageCount = 7;
  const classes = useStyles();
  const globalStore = useGlobalStore();
  const [anchorEls, setAnchorEls] = useState({} as any);
  const [videoList, setVideosList] = useState<IVideo[]>([]);
  const [globalVideoList, setGlobalVideoList] = useState<IVideo[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSpin, setShowSpin] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [popupT, setPopup] = useState(false);
  const [popupDel, setPopupDel] = useState(false);
  const [popupView, setPopupView] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const [endIndex, setEndIndex] = useState(itemsPerPageCount);
  const { items, requestSort, sortConfig } = useSortableData(videoList, {
    key: "title",
    direction: "ascending",
  });

  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    const getRoleUser = async () => {
      const userRoleObj: IPostUserRole = {
        groupId: await configData.ADMIN_GROUP_ID,
        userId: globalStore.loginUserAadId,
      };
      setShowSpin(true);
      apiService
        .getUserRole(userRoleObj)
        .then((res) => {
          setShowSpin(false);
          globalStore.setDataAvailable(true);
          globalStore.setActionMenuSelectedUserRole(res);
        })
        .catch((error: any) => {
          setShowSpin(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get User Role settings API call, Component: ${pageName}, Error: ${error}`
          );
        });
    };
    getRoleUser();
  }, []);

  useEffect(() => {
    trackComponent();
    const pageLoadApiCall = async () => {
      const videos = await sessionStorage.getWithExpiry("relaxationVideoList");
      if (videos) {
        setGlobalVideoList(videos);

        setVideosList(videos);
      } else {
        getVideoListApiCall();
      }
    };

    pageLoadApiCall();
  }, []);

  const getVideoListApiCall = () => {
    try {
      setShowSpinner(true);
      apiService
        .getRelaxatiobVideosList()
        .then((data: IVideo[]) => {
          setShowSpinner(false);
          setVideosList(data);
          setGlobalVideoList(data);
          sessionStorage.setWithExpiry("relaxationVideoList", data, 600);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at getVideoList API call, getVideoListListApiCall, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getVideoList, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    if (props.searchText) {
      const newItem: IVideo[] = [...globalVideoList];
      const newArr = newItem.filter(
        (value: IVideo) =>
          value.title.toLowerCase().includes(props.searchText.toLowerCase()) ||
          value.description
            .toLowerCase()
            .includes(props.searchText.toLowerCase())
      );
      setVideosList(newArr);
    } else {
      setVideosList(globalVideoList);
    }
  }, [props.searchText]);

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return <DownArrowDisabled />;
    }
    if (sortConfig.key === name && sortConfig.direction === "ascending") {
      return <UpArrow />;
    }
    if (sortConfig.key === name && sortConfig.direction === "descending") {
      return <DownArrow />;
    }
    return <DownArrowDisabled />;
  };

  const isActive = (row: string, isActiveState: any) => {
    const formData = new FormData();
    formData.append("Id", row);
    formData.append("isActive", isActiveState);

    const videoListNew: IVideo[] = JSON.parse(JSON.stringify(videoList));
    videoListNew.filter((value: IVideo) => value.id === row)[0].isActive =
      isActiveState;
    setIsFetch(true);
    setVideosList(videoListNew);
    sessionStorage.setWithExpiry("relaxationVideoList", videoListNew, 600);
    apiService
      .updateVideoState(formData)
      .then((res: any) => {
        if (res === "success") {
          setIsFetch(false);
        }
      })
      .catch((error: any) => {
        setIsFetch(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at Edit video API call, Component: ${pageName}, Error: ${error}`
        );
      });
  };

  /* Method for opening more actions menu */
  const handleActionClick = (videoId: string, event: any) => {
    anchorEls[videoId] = event?.target;
    setAnchorEls({ ...anchorEls });
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightsConstants.Information,
      `Action Menu Opened, PatientId: ${videoId}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  /* Method for closing more actions menu */
  const handleActionClose = (videoId: string) => {
    anchorEls[videoId] = null;
    setAnchorEls({ ...anchorEls });
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightsConstants.Information,
      `Action Menu Closed, PatientId: ${videoId}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  useEffect(() => {
    trackComponent();
  }, []);

  const closeCallbackHandler = () => {
    try {
      setPopupView(false);
      setPopupDel(false);
      setPopupEdit(false);
      setPopup(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const editPopUp = () => {
    try {
      setPopupEdit(true);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at editPopUp, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <>
        <Grid className="video-container">
          <Grid className="video">
            <Grid className={"video-left"}>{`Total Videos ${
              videoList.length > 0 ? `(${videoList.length})` : ""
            }`}</Grid>
            <LightTooltip
              title={ConstantVariables.RoleBasedAccessErrorMessage}
              disableHoverListener={
                globalStore.actionMenuSelectedUserRole ===
                globalStore.fullControlGroup
              }
            >
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  className="newVideoBtn"
                  startIcon={<AddIcon />}
                  disabled={
                    globalStore.actionMenuSelectedUserRole !==
                    globalStore.fullControlGroup
                  }
                  style={{
                    opacity:
                      globalStore.actionMenuSelectedUserRole !==
                      globalStore.fullControlGroup
                        ? 0.5
                        : 1,
                  }}
                  onClick={() => {
                    setPopup(true);
                    globalStore.setOpenedModal("create");
                    AppInsightMethods.trackAppInsightsEvent(
                      pageName,
                      AppInsightsConstants.Information,
                      `The "Video" button has been clicked.`,
                      globalStore.loginUserEmail,
                      window.location.href,
                      ""
                    );
                  }}
                >
                  Video
                </Button>
              </span>
            </LightTooltip>
          </Grid>

          {showSpinner && (
            <CircularProgress size={90} className={"dashboard_spinner"} />
          )}
          {showSpin && (
            <CircularProgress size={90} className={"dashboard_spinner"} />
          )}
          {!showSpinner && !showSpin && items && items.length > 0 && (
            <TableContainer className={"videoList_table_container"}>
              <Table
                className={`${classes.table} videoList_table`}
                aria-label="simple table"
              >
                <TableHead className={"videoListHeader"}>
                  <TableRow>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={`headerCell `}
                      tabIndex={0}
                      onClick={() => requestSort("title")}
                    >
                      {"Title"}
                      {getClassNamesFor("title")}
                    </TableCell>

                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 250 }}
                      className={"headerCell"}
                      onClick={() => requestSort("description")}
                    >
                      {"Description"}
                      {getClassNamesFor("description")}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 150 }}
                      className={"headerCell"}
                      onClick={() => requestSort("modifiedBy")}
                    >
                      {"Modified By"}
                      {getClassNamesFor("modifiedBy")}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 210 }}
                      className={"headerCell"}
                      onClick={() => requestSort("modifiedDate")}
                    >
                      {"Modified Date & Time"}
                      {getClassNamesFor("modifiedDate")}
                    </TableCell>

                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={"headerCell"}
                    >
                      {"Link / File Name"}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      tabIndex={0}
                      className={"headerCell"}
                      style={{ minWidth: 100 }}
                      onClick={() => requestSort("isActive")}
                    >
                      {"Status"}
                      {getClassNamesFor("isActive")}
                    </TableCell>
                    <TableCell align={alignLeftText} tabIndex={0}>
                      {""}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={"videoListBody"}>
                  {items?.slice(startIndex, endIndex).map((row: IVideo) => (
                    <>
                      {
                        <TableRow key={`${row.id}`} id={row.id}>
                          <TableCell
                            align={alignLeftText}
                            className="bodyCell titleCell overflowHidden"
                            title={row.title}
                          >
                            <span className="bold">{row.title}</span>
                          </TableCell>
                          <TableCell
                            align={alignLeftText}
                            className="bodyCell overflowHidden"
                            title={row.description}
                            style={{ minWidth: 250 }}
                          >
                            {row.description}
                          </TableCell>
                          <TableCell
                            align={alignLeftText}
                            className="bodyCell overflowHidden"
                            style={{ minWidth: 150 }}
                            title={row.modifiedBy}
                          >
                            {row.modifiedBy?.split("@")[0]}
                          </TableCell>
                          <TableCell align={alignLeftText} className="bodyCell">
                            {moment(new Date(row.modifiedDate)).format("ll")}
                            {" | "}
                            {moment(new Date(row.modifiedDate)).format("LT")}
                          </TableCell>

                          <TableCell
                            align={alignLeftText}
                            className="bodyCell"
                            style={{
                              minWidth: 200,
                              overflowWrap: "break-word",
                            }}
                          >
                            {row.inputUrl !== null && row.inputUrl !== "" ? (
                              <a
                                href={`${row.inputUrl}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ cursor: "pointer" }}
                              >
                                <span>{row.inputUrl}</span>
                              </a>
                            ) : (
                              <a
                                href={`${row.fileUrl}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ cursor: "pointer" }}
                              >
                                <span>{row.fileName}</span>
                              </a>
                            )}
                          </TableCell>
                          <TableCell
                            align={alignLeftText}
                            className="bodyCell"
                            style={{ minWidth: 200 }}
                          >
                            <LightTooltip
                              title={
                                ConstantVariables.RoleBasedAccessErrorMessage
                              }
                              disableHoverListener={
                                globalStore.actionMenuSelectedUserRole ===
                                globalStore.fullControlGroup
                              }
                            >
                              <span>
                                <AntSwitch
                                  disabled={
                                    globalStore.actionMenuSelectedUserRole !==
                                    globalStore.fullControlGroup
                                  }
                                  checked={row.isActive}
                                  name="checked"
                                  onChange={() =>
                                    isActive(row.id, !row.isActive)
                                  }
                                />
                              </span>
                            </LightTooltip>
                          </TableCell>
                          <TableCell align={alignLeftText} className="bodyCell">
                            <>
                              <MoreHorizIcon
                                onClick={(e) => {
                                  globalStore.setActionMenuSelectedVideo(row);
                                  handleActionClick(`${row.id}`, e);
                                }}
                                className={"pointer"}
                              />
                              <Menu
                                id={`${row.id}`}
                                anchorEl={anchorEls[`${row.id}`]}
                                keepMounted
                                open={Boolean(anchorEls[`${row.id}`])}
                                onClose={() => handleActionClose(`${row.id}`)}
                              >
                                <LightTooltip
                                  title={
                                    ConstantVariables.RoleBasedAccessErrorMessage
                                  }
                                  disableHoverListener={
                                    globalStore.actionMenuSelectedUserRole ===
                                    globalStore.fullControlGroup
                                  }
                                >
                                  <span>
                                    <MenuItem
                                      disabled={
                                        globalStore.actionMenuSelectedUserRole !==
                                        globalStore.fullControlGroup
                                      }
                                      style={{
                                        opacity:
                                          globalStore.actionMenuSelectedUserRole !==
                                          globalStore.fullControlGroup
                                            ? 0.5
                                            : 1,
                                      }}
                                      onClick={() => {
                                        setPopupView(true);
                                        handleActionClose(`${row.id}`);
                                        globalStore.setOpenedModal("view");
                                      }}
                                    >
                                      {"View Video"}
                                    </MenuItem>
                                  </span>
                                </LightTooltip>
                                <LightTooltip
                                  title={
                                    ConstantVariables.RoleBasedAccessErrorMessage
                                  }
                                  disableHoverListener={
                                    globalStore.actionMenuSelectedUserRole ===
                                    globalStore.fullControlGroup
                                  }
                                >
                                  <span>
                                    <MenuItem
                                      style={{
                                        opacity:
                                          globalStore.actionMenuSelectedUserRole !==
                                          globalStore.fullControlGroup
                                            ? 0.5
                                            : 1,
                                      }}
                                      disabled={
                                        globalStore.actionMenuSelectedUserRole !==
                                        globalStore.fullControlGroup
                                      }
                                      onClick={() => {
                                        setPopupEdit(true);
                                        handleActionClose(`${row.id}`);
                                        globalStore.setOpenedModal("edit");
                                      }}
                                    >
                                      {"Edit Video"}
                                    </MenuItem>
                                  </span>
                                </LightTooltip>
                                <LightTooltip
                                  title={
                                    ConstantVariables.RoleBasedAccessErrorMessage
                                  }
                                  disableHoverListener={
                                    globalStore.actionMenuSelectedUserRole ===
                                    globalStore.fullControlGroup
                                  }
                                >
                                  <span>
                                    <MenuItem
                                      disabled={
                                        globalStore.actionMenuSelectedUserRole !==
                                        globalStore.fullControlGroup
                                      }
                                      style={{
                                        opacity:
                                          globalStore.actionMenuSelectedUserRole !==
                                          globalStore.fullControlGroup
                                            ? 0.5
                                            : 1,
                                      }}
                                      onClick={() => {
                                        setPopupDel(true);
                                        handleActionClose(`${row.id}`);
                                        globalStore.setOpenedModal("delete");
                                        globalStore.setSelectedVideoName(
                                          row.title
                                        );
                                      }}
                                    >
                                      {"Delete"}
                                    </MenuItem>
                                  </span>
                                </LightTooltip>
                              </Menu>
                            </>
                          </TableCell>
                        </TableRow>
                      }
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {items && items.length > 7 && (
            <Pagination
              itemCount={items.length}
              itemsPerPage={itemsPerPageCount}
              paginationCallback={(startInd: number, endInd: number) => {
                setStartIndex(startInd);
                setEndIndex(endInd);
              }}
            />
          )}
          {!showSpinner && videoList.length === 0 && <NoResult />}
          {isFetch && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 999,
              }}
              open={isFetch}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <ViewVideo
            popupE={() => editPopUp()}
            popup={popupView}
            closeCallback={() => closeCallbackHandler()}
          />
          <DeleteVideo
            callBackGetVideoList={getVideoListApiCall}
            popup={popupDel}
            closeCallback={() => closeCallbackHandler()}
          />
          <EditVideo
            callBackGetVideoList={getVideoListApiCall}
            popup={popupEdit}
            closeCallback={() => closeCallbackHandler()}
          />
          <AddVideo
            callBackGetVideoList={getVideoListApiCall}
            popup={popupT}
            closeCallback={() => closeCallbackHandler()}
          />
        </Grid>
      </>
    );
  });
}
