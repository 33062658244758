/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useObserver } from "mobx-react-lite";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import AddIcon from "@mui/icons-material/Add";
import { IGetNotificationTemplate } from "../../state/StoreTypes";
import { ConstantVariables } from "../../Constants";
import { apiService } from "../../services/ApiService";
import { sessionStorage } from "../../state/SessionStorage";
import { AppInsightMethods } from "../../AppInsightMethods";
import { NoResult } from "../Resources/Resources";
import { LightTooltip } from "../Relationship/RelationshipList";
import { useGlobalStore } from "../../state/GlobalStore";
import AddNotificationTemplate from "./AddNotificationTemplate";
import UpdateNotificationTemplate from "./EditNotification";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

interface IDashboardTabsProps {
  selectedSubTabs: string;
}

export default function PatientList(props: IDashboardTabsProps) {
  const pageName = "Patient List";
  const appInsights = useAppInsightsContext();
  const classes = useStyles();
  const [popupT, setPopup] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const globalStore = useGlobalStore();
  const alignLeftText = "left";
  const [notificationCount, setNotificationCount] = useState<
    IGetNotificationTemplate[]
  >([]);
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);

  const getNotificationList = () => {
    try {
      setShowSpinner(false);
      apiService
        .getNotification()
        .then((data: IGetNotificationTemplate[]) => {
          setNotificationCount(data);
          sessionStorage.setWithExpiry("notifications", data, 600);
        })
        .catch((error: any) => {
          AppInsightMethods.TrackAppInsightsException(
            `Error at fetch Notification Template API call, getConfigurationMsgList, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at fetch Notification Template, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const closeCallbackHandler = () => {
    try {
      setPopup(false);
      setPopupEdit(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  useEffect(() => {
    trackComponent();
    globalStore.setNotificationCounts(notificationCount.length);
  });
  useEffect(() => {
    trackComponent();
    const pageLoadApiCall = async () => {
      const notificationNo = await sessionStorage.getWithExpiry(
        "notifications"
      );
      if (notificationNo) {
        setNotificationCount(notificationNo);
      } else {
        getNotificationList();
      }
    };
    pageLoadApiCall();
  }, []);

  return useObserver(() => {
    return (
      <Grid className="notification-container">
        <Grid className="notification">
          <Grid className={"notification-left"}>{""}</Grid>
          <LightTooltip
            title={ConstantVariables.RoleBasedAccessErrorMessage}
            disableHoverListener={
              globalStore.actionMenuSelectedUserRole ===
              globalStore.fullControlGroup
            }
          >
            <span>
              <Button
                variant="contained"
                disabled={
                  globalStore.actionMenuSelectedUserRole !==
                  globalStore.fullControlGroup
                }
                style={{
                  opacity:
                    globalStore.actionMenuSelectedUserRole !==
                    globalStore.fullControlGroup
                      ? 0.5
                      : 1,
                }}
                className={"newMaterialBtn"}
                startIcon={<AddIcon />}
                onClick={() => {
                  setPopup(true);
                }}
              >
                Notification Template
              </Button>
            </span>
          </LightTooltip>
        </Grid>
        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}

        {!showSpinner && notificationCount && notificationCount.length > 0 && (
          <TableContainer className={"notificationList_table_container"}>
            <Table
              className={`${classes.table} notificationList_table`}
              aria-label="simple table"
            >
              <TableHead className={"notificationListHeader"}>
                <TableRow>
                  <TableCell
                    align={alignLeftText}
                    style={{ width: 500 }}
                    className={`headerCell `}
                    tabIndex={0}
                  >
                    {"Recipient"}
                  </TableCell>
                  <TableCell align={alignLeftText} className={"headerCell"}>
                    {"Notification Type"}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={"notificationListBody"}>
                {notificationCount
                  .filter(
                    (row: IGetNotificationTemplate) =>
                      row.recipient === props.selectedSubTabs
                  )
                  .map((row) => (
                    <TableRow key={`${row.id}`} id={row.id}>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell titleCell overflowHidden"
                        title={row.recipient}
                      >
                        <span className="bold">{row.recipient}</span>
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell overflowHidden notificationDescription"
                        title={row.notificationType}
                        style={{ minWidth: 663 }}
                      >
                        {row.notificationType}
                      </TableCell>
                      <TableCell align={alignLeftText} tabIndex={0}>
                        {""}
                      </TableCell>
                      <TableCell align={alignLeftText} tabIndex={0}>
                        {""}
                      </TableCell>
                      <TableCell align={alignLeftText} tabIndex={0}>
                        {""}
                      </TableCell>
                      <TableCell>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              onClick={() => {
                                globalStore.setActionMenuSelectedNotification(
                                  row
                                );
                                setPopupEdit(true);
                              }}
                              id={`${row.id}`}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Edit"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!showSpinner && notificationCount.length === 0 && <NoResult />}
        <AddNotificationTemplate
          callBackGetreminderList={getNotificationList}
          popup={popupT}
          closeCallback={() => closeCallbackHandler()}
        />
        <UpdateNotificationTemplate
          callBackGetreminderList={getNotificationList}
          popup={popupEdit}
          closeCallback={() => closeCallbackHandler()}
        />
      </Grid>
    );
  });
}
