/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Alert,
} from "@mui/material";
import { useObserver } from "mobx-react-lite";
import { ILanguageSettings } from "../../state/GlobalStateType";
import { apiService } from "../../services/ApiService";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";
import { LightTooltip } from "../Relationship/RelationshipList";
import { NoResult } from "../Resources/Resources";
import { sessionStorage } from "../../state/SessionStorage";
import AddLanguage from "./AddLanguage";
import { useGlobalStore } from "../../state/GlobalStore";
import EditLanguage from "./UpdateLanguage";
import DeleteLanguage from "./DeleteLanguage";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

export default function LanguageList() {
  const pageName = "Language List";
  const appInsights = useAppInsightsContext();
  const classes = useStyles();
  const alignLeftText = "left";
  const globalStore = useGlobalStore();
  const [languageList, setLanguageList] = useState<ILanguageSettings[]>([]);
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);
  const [popUpT, setPopup] = useState(false);
  const [popUpEdit, setPopupEdit] = useState(false);
  const [popUpDel, setPopupDel] = useState(false);

  const getLanguageSettings = () => {
    try {
      setShowSpinner(true);
      apiService
        .getLanguages()
        .then((data: ILanguageSettings[]) => {
          setShowSpinner(false);
          setLanguageList(data);
          sessionStorage.setWithExpiry("language", data, 600);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get Language settings API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getLanguageSettings, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const closeCallbackHandler = () => {
    try {
      setPopup(false);
      setPopupDel(false);
      setPopupEdit(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    trackComponent();
    const pageLoadApiCall = async () => {
      const relations = await sessionStorage.getWithExpiry("language");
      if (relations) {
        setLanguageList(relations);
      } else {
        getLanguageSettings();
      }
    };
    pageLoadApiCall();
  }, []);

  return useObserver(() => {
    return (
      <Grid className="language-container">
        <Alert severity="info" className={"infoAlert"}>
          {`Currently the "Language settings" are not configurable`}
        </Alert>
        <Grid className="language">
          <Grid className={"language-left"}>{`Language Settings`}</Grid>
          <LightTooltip
            title={ConstantVariables.RoleBasedAccessErrorMessage}
            disableHoverListener={
              globalStore.actionMenuSelectedUserRole ===
              globalStore.fullControlGroup
            }
          >
            <span>
              <Button
                variant="contained"
                disabled={
                  globalStore.actionMenuSelectedUserRole !==
                  globalStore.fullControlGroup
                }
                style={{
                  opacity:
                    globalStore.actionMenuSelectedUserRole !==
                    globalStore.fullControlGroup
                      ? 0.5
                      : 1,
                }}
                color="primary"
                className={"newMaterialBtn"}
                startIcon={<AddIcon />}
                onClick={() => {
                  setPopup(true);
                  AppInsightMethods.trackAppInsightsEvent(
                    pageName,
                    AppInsightsConstants.Information,
                    `The  "Language" button has been clicked.`,
                    "",
                    window.location.href,
                    ""
                  );
                }}
              >
                Language
              </Button>
            </span>
          </LightTooltip>
        </Grid>
        <Divider style={{ width: 1458, marginLeft: 60 }} />
        {showSpinner && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}

        {!showSpinner && (
          <TableContainer className={"languageList_table_container"}>
            <Table
              className={`${classes.table} languageList_table`}
              aria-label="simple table"
            >
              <TableBody className={"languageListBody"}>
                {languageList.length !== 0 &&
                  languageList.map((row: ILanguageSettings) => (
                    <TableRow
                      key={`${row.id}`}
                      id={row.id}
                      className={"languageListBody-row"}
                    >
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell titleCell overflowHidden"
                        title={row.languageName}
                      >
                        <span className="bold">{row.languageName}</span>
                      </TableCell>
                      <TableCell align={alignLeftText} className="bodyCell">
                        <></>
                      </TableCell>
                      <TableCell align={alignLeftText} className="bodyCell">
                        <></>
                      </TableCell>
                      <TableCell style={{ paddingLeft: 1050 }}>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              className="editnDel"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              onClick={() => {
                                setPopupEdit(true);
                                globalStore.setActionMenuSelectedLanguage(row);
                              }}
                              id={`${row.id}`}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Edit"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>

                      <TableCell>
                        <LightTooltip
                          title={ConstantVariables.RoleBasedAccessErrorMessage}
                          disableHoverListener={
                            globalStore.actionMenuSelectedUserRole ===
                            globalStore.fullControlGroup
                          }
                        >
                          <span>
                            <Button
                              variant="text"
                              disabled={
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                              }
                              style={{
                                opacity:
                                  globalStore.actionMenuSelectedUserRole !==
                                  globalStore.fullControlGroup
                                    ? 0.5
                                    : 1,
                              }}
                              className="editnDel"
                              id={`${row.id}`}
                              onClick={() => {
                                setPopupDel(true);
                                globalStore.setActionMenuSelectedLanguage(row);
                              }}
                            >
                              <Typography
                                style={{ textTransform: "none" }}
                                id={`${row.id}`}
                              >
                                {"Delete"}
                              </Typography>
                            </Button>
                          </span>
                        </LightTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!showSpinner && languageList.length === 0 && <NoResult />}

        <AddLanguage
          callBackGetLanguageList={getLanguageSettings}
          popup={popUpT}
          closeCallback={() => closeCallbackHandler()}
        />
        <EditLanguage
          callBackGetLanguageList={getLanguageSettings}
          popup={popUpEdit}
          closeCallback={() => closeCallbackHandler()}
        />
        <DeleteLanguage
          callBackGetLanguageList={getLanguageSettings}
          popup={popUpDel}
          closeCallback={() => closeCallbackHandler()}
        />
      </Grid>
    );
  });
}
