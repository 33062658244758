/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useObserver } from "mobx-react-lite";
import { apiService } from "../../services/ApiService";
import { AppInsightMethods } from "../../AppInsightMethods";
import { ISystemSettings, ISystemSettingsNew } from "../../state/StoreTypes";
import EditSystemSettings from "./EditSystemSettings";
import { LightTooltip } from "../Relationship/RelationshipList";
import { useGlobalStore } from "../../state/GlobalStore";
import { sessionStorage } from "../../state/SessionStorage";
import { AppInsightsConstants, ConstantVariables } from "../../Constants";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

interface ISystemSettingsProps {
  searchString: string;
}

export default function SystemSettings(props: ISystemSettingsProps) {
  const pageName = "SystemSettings";
  const alignLeftText = "left";
  const classes = useStyles();
  const globalStore = useGlobalStore();

  const settingsTypeConst: string[] = [
    "Virtual clinic room enter time",
    "Scheduled past time meeting",
  ];
  const [isFetch, setIsFetch] = useState(false);
  const [systemSettings, setSystemSettings] = useState<ISystemSettingsNew[]>(
    []
  );
  const [showEditPopup, setShowEditPopup] = useState(false);

  const getAllSystemSettings = () => {
    try {
      setIsFetch(true);
      apiService
        .getAllSystemSettings()
        .then((value: ISystemSettings[]) => {
          setIsFetch(false);
          if (value.length > 0) {
            const newObj: ISystemSettingsNew[] = value.map((obj) => ({
              ...obj,
              isSelected: false,
            }));
            setSystemSettings(newObj);
            sessionStorage.setWithExpiry("systemSettings", newObj, 600);
          }
        })
        .catch((error: any) => {
          setIsFetch(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get All SystemSettings API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getAllSystemSettings, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    const pageLoadApiCall = async () => {
      const sysSet = await sessionStorage.getWithExpiry("systemSettings");
      if (sysSet) {
        setSystemSettings(sysSet);
      } else {
        getAllSystemSettings();
      }
    };
    pageLoadApiCall();
  }, []);

  useEffect(() => {
    const onSearchStringChangeEvent = async () => {
      const newItems: ISystemSettingsNew[] = await sessionStorage.getWithExpiry(
        "systemSettings"
      );
      if (props.searchString) {
        const newArr = newItems.filter((value: ISystemSettingsNew) =>
          value.settingsType
            .toLowerCase()
            .includes(props.searchString.toLowerCase())
        );
        setSystemSettings(newArr);
      } else {
        setSystemSettings(newItems);
      }
    };
    onSearchStringChangeEvent();
  }, [props.searchString]);

  const onEditClickEvent = (itemId: string) => {
    try {
      const newSystemSettings: ISystemSettingsNew[] = JSON.parse(
        JSON.stringify(systemSettings)
      );
      const newSystemSettingsObj: ISystemSettingsNew = newSystemSettings.filter(
        (value: ISystemSettingsNew) => value.id === itemId
      )[0];
      const newSystemSetObj: ISystemSettings = {
        id: newSystemSettingsObj.id,
        settingsType: newSystemSettingsObj.settingsType,
        time: newSystemSettingsObj.time,
        uploadedTime: newSystemSettingsObj.uploadedTime,
        uploadedBy: newSystemSettingsObj.uploadedBy,
        title: newSystemSettingsObj.title,
        description: newSystemSettingsObj.description,
      };
      newSystemSettings.filter(
        (value: ISystemSettingsNew) => value.id === itemId
      )[0].isSelected = true;
      setSystemSettings(newSystemSettings);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `The ${newSystemSettingsObj.settingsType} Edit has been clicked.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      sessionStorage.setWithExpiry("systemSettings", newSystemSettings, 600);
      globalStore.setSelectedSystemSetting(newSystemSetObj);
      setShowEditPopup(true);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onEditClickEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const closeCallbackHandler = (isCloseClick: boolean) => {
    try {
      if (isCloseClick) {
        const systemSettingsState: ISystemSettingsNew[] = JSON.parse(
          JSON.stringify(systemSettings)
        );
        setShowEditPopup(false);
        globalStore.setSelectedSystemSetting(null);
        const newObj: ISystemSettingsNew[] = systemSettingsState.map((obj) => ({
          ...obj,
          isSelected: false,
        }));
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          AppInsightsConstants.Information,
          `The SystemSettings Edit Popup has been closed.`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
        setSystemSettings(newObj);
        sessionStorage.setWithExpiry("systemSettings", newObj, 600);
      } else {
        setShowEditPopup(false);
        getAllSystemSettings();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at closeCallbackHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Grid>
        {isFetch && (
          <CircularProgress size={90} className={"dashboard_spinner"} />
        )}

        {!isFetch && systemSettings && systemSettings.length > 0 && (
          <TableContainer className={"systemSettings_table_container"}>
            <Table
              className={`${classes.table} systemSettings_table`}
              aria-label="simple table"
            >
              <TableHead className={"systemSettingsHeader"}>
                <TableRow>
                  <TableCell
                    align={alignLeftText}
                    style={{ minWidth: 200 }}
                    className={`headerCell `}
                    tabIndex={0}
                  >
                    {"Setting Type"}
                  </TableCell>
                  <TableCell
                    align={alignLeftText}
                    style={{ minWidth: 250 }}
                    className={"headerCell"}
                  >
                    {"Time"}
                  </TableCell>
                  <TableCell align={alignLeftText} tabIndex={0}>
                    {""}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={"systemSettingsBody"}>
                {systemSettings.map((row: ISystemSettingsNew) => (
                  <TableRow
                    key={`${row.id}`}
                    id={row.id}
                    className={row.isSelected ? "activeRow" : ""}
                  >
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell overflowHidden"
                      title={row.settingsType}
                    >
                      <span className="bold">{row.settingsType}</span>
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      className="bodyCell overflowHidden"
                      title={row.time}
                      style={{ minWidth: 250 }}
                    >
                      {settingsTypeConst.indexOf(row.settingsType) > -1
                        ? "Before "
                        : ""}
                      {row.time} {"Minutes"}
                    </TableCell>
                    <TableCell align={alignLeftText} className="bodyCell">
                      <LightTooltip
                        title={ConstantVariables.RoleBasedAccessErrorMessage}
                        disableHoverListener={
                          globalStore.actionMenuSelectedUserRole ===
                          globalStore.fullControlGroup
                        }
                      >
                        <span>
                          <Button
                            variant="text"
                            className="editnDel"
                            disabled={
                              globalStore.actionMenuSelectedUserRole !==
                              globalStore.fullControlGroup
                            }
                            style={{
                              color: "#1447d4",
                              opacity:
                                globalStore.actionMenuSelectedUserRole !==
                                globalStore.fullControlGroup
                                  ? 0.5
                                  : 1,
                            }}
                            onClick={() => {
                              onEditClickEvent(row.id);
                            }}
                          >
                            <Typography
                              style={{ textTransform: "none" }}
                              id={`${row.id}`}
                            >
                              Edit
                            </Typography>
                          </Button>
                        </span>
                      </LightTooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <EditSystemSettings
          openEditPopup={showEditPopup}
          closeCallback={(b: boolean) => closeCallbackHandler(b)}
        />
      </Grid>
    );
  });
}
