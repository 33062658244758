import * as React from "react";
import { Grid, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useGlobalStore } from "../../state/GlobalStore";
import { ReactComponent as LetterSpacingImg } from "../../assets/letterSpacing.svg";

interface ILetterSpacingProps {
  letterSpacing: string;
  headingName: string;
}
export default function LetterSpacing(props: ILetterSpacingProps) {
  const globalStore = useGlobalStore();

  return (
    <Grid className={"searchBoxLetter"}>
      <TextField
        id="header-SearchBox"
        value={props.letterSpacing.substring(0, props.letterSpacing.length - 2)}
        onChange={(event) => {
          globalStore.setSearchText(
            `${event.target.value}px`,
            props.headingName,
            "letterSpacing"
          );
        }}
      />
      <LetterSpacingImg />
    </Grid>
  );
}
