import React from "react";
import { Grid, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export interface FileHeaderProps {
  file: File;
  onDelete: (file: File) => void;
}

export function FileHeader({ file, onDelete }: FileHeaderProps) {
  return (
    <Grid container alignItems="center">
      <Grid item>{file.name}</Grid>
      <Grid item>
        <IconButton onClick={() => onDelete(file)}>
          <CloseRoundedIcon className="crossBtn" />
        </IconButton>
      </Grid>
    </Grid>
  );
}
