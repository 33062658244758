/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";
import DefaultThemeContainer from "./DefaultThemeContainer";
import CustomThemeContainer from "./CustomThemeContainer";
import { apiService } from "../../services/ApiService";
import { IThemeColors, IThemeDetails } from "../../state/StoreTypes";

export default function DefaultTheme() {
  const globalStore = useGlobalStore();
  const pageName = "Theme";
  const appInsights = useAppInsightsContext();
  const [value, setValue] = useState<any>("");
  const [searchText, setSearchText] = useState("");
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    trackComponent();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleApply = () => {
    globalStore.setIsCustomized(false);
    setShowSpinner(true);
    let themeData: IThemeColors;
    globalStore.customThemeData?.map((data) => {
      value === data?.themeData?.themeName &&
        ((themeData = {
          id: data.id,
          themeName: value,
          primaryColor: data?.themeData?.primaryColor,
          secondaryColor: data?.themeData?.secondaryColor,
          pageBackgroundColor: data?.themeData?.pageBackgroundColor,
          subPageBackgroundColor: data?.themeData?.subPageBackgroundColor,
          alertColor: data?.themeData?.alertColor,
          headingsTextColor: data?.themeData?.headingsTextColor,
          bodyTextColor: data?.themeData?.bodyTextColor,
          createdBy: "Publictester8",
          createdTime: "",
        }),
        apiService
          .addCustomTheme(themeData)
          .then((res: any) => {
            if (res === "success") {
              globalStore.setIsCustomTheme(true);
              setShowSpinner(false);
              globalStore.setIsCustomized(true);
              globalStore.setCustomTabName(`${value}`);
            }
          })
          .catch((error: any) => {
            setShowSpinner(false);
            globalStore.setIsCustomized(false);
            AppInsightMethods.TrackAppInsightsException(
              `Error at Edit Material API call, Component: ${pageName}, Error: ${error}`
            );
          }));
    });
  };

  useEffect(() => {
    try {
      setShowSpinner(true);
      apiService
        .getCustomTheme()
        .then((resultData: IThemeDetails[]) => {
          if (resultData) {
            setShowSpinner(false);
            globalStore.setCustomThemeData(resultData);
            resultData?.filter((data) => {
              data.isSelected === true && setValue(data?.themeData?.themeName);
            });
          }
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at get Custom Theme API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getCustomTheme, Component: ${pageName}, Error: ${error}`
      );
    }
  }, [globalStore.isCustomized]);

  return useObserver(() => {
    return (
      <>
        <Grid className="theme-Container">
          <p>Select theme :</p>
          <RadioGroup
            row
            className="radioGroup"
            aria-label="gender"
            name="theme"
            value={value}
            onChange={handleChange}
          >
            {globalStore.customThemeData?.map(
              (data) =>
                data?.themeData?.themeName === "Default Theme" && (
                  <>
                    <FormControlLabel
                      key={data.id}
                      className="radioLabel"
                      value={data?.themeData?.themeName}
                      control={<Radio />}
                      label={<DefaultThemeContainer />}
                    />
                  </>
                )
            )}
            {globalStore.customThemeData?.map(
              (data) =>
                data?.themeData?.themeName !== "Default Theme" && (
                  <>
                    <FormControlLabel
                      key={data.id}
                      className="radioLabel"
                      value={data?.themeData?.themeName}
                      control={<Radio />}
                      label={
                        <CustomThemeContainer
                          primaryColor={data?.themeData?.primaryColor}
                          secondaryCOlor={data?.themeData?.secondaryColor}
                          pageBgColor={data?.themeData?.pageBackgroundColor}
                          subPageBgColor={
                            data?.themeData?.subPageBackgroundColor
                          }
                          alertColor={data?.themeData?.alertColor}
                          headingColor={data?.themeData?.headingsTextColor}
                          bodyColor={data?.themeData?.bodyTextColor}
                          themeName={data?.themeData?.themeName}
                        />
                      }
                    />
                  </>
                )
            )}
          </RadioGroup>
        </Grid>
        <div className="themeButtons">
          <Button className="cancelButton">Cancel</Button>
          <Button className="applyButton" onClick={handleApply}>
            Apply
          </Button>
        </div>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
}
