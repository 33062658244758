/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-plusplus: "error" */
import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import { SingleFileUploadWithProgress } from "./SingleFileUpload";
import { UploadError } from "./error";
import { AppInsightMethods } from "../../AppInsightMethods";

export interface UploadableFile {
  file: File;
  errors: FileError[];
  id: number;
}
let currentId = 0;
function getNewId() {
  currentId += 1;
  return currentId;
}

export interface IMultipleFileUploadFieldProps {
  handleFileChange: (file: File) => void;
  type: string;
}

export default function MultipleFileUploadField(
  props: IMultipleFileUploadFieldProps
) {
  const pageName = "DragnDrop";
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [title, setTitle] = useState("Drop your file here, or");
  const [stitle, ssetTitle] = useState("browse");
  const [subTitle, setSubTitle] = useState(
    "Supports : mp3,doc,pdf,html,xml,mp4 | Max Size : 2 MB"
  );
  const [subTitleVideo, setSubTitleVideo] = useState(
    "Supports : mp3, mp4 | Max Size : 200 MB"
  );
  const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    try {
      setTitle("");
      setSubTitle("");
      ssetTitle("");
      const mappedAcc = accFiles.map((file) => ({
        file,
        errors: [],
        id: getNewId(),
      }));
      const mappedRej = rejFiles.map((r) => ({ ...r, id: getNewId() }));
      setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at calculateIndexValues, Component: ${pageName}, Error: ${error}`
      );
    }
  }, []);

  useEffect(() => {
    if (files && files.length === 1) {
      props.handleFileChange(files[0].file);
    } else if (files && files.length > 1) {
      props.handleFileChange(files[1].file);
    } else if (files && files.length > 2) {
      props.handleFileChange(files[2].file);
    } else if (files && files.length > 3) {
      props.handleFileChange(files[3].file);
    } else if (files && files.length > 4) {
      props.handleFileChange(files[4].file);
    } else if (files && files.length > 5) {
      props.handleFileChange(files[5].file);
    }
    // eslint-disable-next-line
  }, [files]);

  const onDelete = (file: File) => {
    try {
      setTitle("Drop your file here, or");
      ssetTitle("browse");
      if (props.type === "material") {
        setSubTitle("Supports : mp3,doc,pdf,html,xml,mp4 | Max Size : 2 MB");
      } else {
        setSubTitleVideo("Supports : mp4 | Max Size : 200 MB");
      }

      setFiles((curr) => curr.filter((fw) => fw.file !== file));
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onDelete, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: props.type === "material" ? "" : ["video/*"],
    maxSize: props.type === "material" ? 2 * 1024 * 1024 : 200 * 1024 * 1024, // 2MB:200MB,
    maxFiles: 1,
    multiple: false,
  });

  return (
    <div>
      <Grid item>
        <div {...getRootProps()}>
          <input {...getInputProps()} required />

          <Grid className="dropDrag">
            <p className="dropDragText">
              {title}
              <span style={{ color: "#1447D4", fontWeight: "450" }}>
                &nbsp;{stitle}
              </span>
            </p>
            <em className="dropDragSubTitle">
              {props.type === "material" ? subTitle : subTitleVideo}
            </em>

            {files
              .slice()
              .splice(-1, 1)
              .map((fileWrapper) => (
                <Grid item key={fileWrapper.id}>
                  {fileWrapper.errors.length ? (
                    <UploadError
                      file={fileWrapper.file}
                      errors={fileWrapper.errors}
                      onDelete={onDelete}
                    />
                  ) : (
                    <SingleFileUploadWithProgress
                      onDelete={onDelete}
                      file={fileWrapper.file}
                    />
                  )}
                </Grid>
              ))}
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
