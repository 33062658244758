import * as React from "react";
import { Grid, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { ReactComponent as UpperCase } from "../../assets/upperCase.svg";
import { ReactComponent as SentenceCase } from "../../assets/sentenceCase.svg";
import { ReactComponent as SuperScript } from "../../assets/superScript.svg";
import { ReactComponent as SubScript } from "../../assets/subScript.svg";
import { ReactComponent as Crossed } from "../../assets/crossed.svg";
import { ReactComponent as Underline } from "../../assets/underline.svg";
import { ReactComponent as LowerCase } from "../../assets/lowerCase.svg";

import { useGlobalStore } from "../../state/GlobalStore";

export type TextTransform =
  | "none"
  | "capitalize"
  | "uppercase"
  | "lowercase"
  | "full-width"
  | "full-size-kana";

interface IFontStyleDropdownProps {
  textTransform: TextTransform;
  textDecoration: string;
  headingName: string;
}
export default function LetterStyle(props: IFontStyleDropdownProps) {
  const globalStore = useGlobalStore();
  return (
    <Grid className="letterStyleAlign">
      <UpperCase
        className={
          props.textTransform === "uppercase" ? "cursorPtr filled" : "cursorPtr"
        }
        onClick={() =>
          globalStore.setTextTransform(
            "uppercase",
            props.headingName,
            "textTransform"
          )
        }
      />
      <LowerCase
        className={
          props.textTransform === "lowercase" ? "cursorPtr filled" : "cursorPtr"
        }
        onClick={() =>
          globalStore.setTextTransform(
            "lowercase",
            props.headingName,
            "textTransform"
          )
        }
      />
      <SentenceCase
        className={
          props.textTransform === "capitalize"
            ? "cursorPtr filled"
            : "cursorPtr"
        }
        onClick={() =>
          globalStore.setTextTransform(
            "capitalize",
            props.headingName,
            "textTransform"
          )
        }
      />

      <Underline
        className={
          props.textDecoration === "underline"
            ? "cursorPtr filled"
            : "cursorPtr"
        }
        onClick={() =>
          globalStore.setTextDecoration(
            "underline",
            props.headingName,
            "textDecoration"
          )
        }
      />

      <Crossed
        className={
          props.textDecoration === "line-through"
            ? "cursorPtr filled"
            : "cursorPtr"
        }
        onClick={() =>
          globalStore.setTextDecoration(
            "line-through",
            props.headingName,
            "textDecoration"
          )
        }
      />
    </Grid>
  );
}
