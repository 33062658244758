/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import ReactQuill, { Quill } from "react-quill";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import "../../styles/quil.scss";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import add from "../../assets/add.png";
import cancel from "../../assets/cancel.png";
import { IAddNotificationTemplate } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { AppInsightsConstants } from "../../Constants";

const useStyle = makeStyles({
  dialog: {
    width: 720,
    height: 828,
    maxWidth: 720,
  },
});

AddNotificationTemplate.modules = {
  toolbar: [
    ["bold", "italic", "underline"], // toggled buttons
    [
      { align: "right" },
      { align: "justify" },
      { align: "center" },
      { align: "" },
    ],
    ["link", "image"],
  ],
};

interface IreminderButton {
  callBackGetreminderList: () => void;
  popup: boolean;
  closeCallback: () => void;
}

const optionSelect = ["Patient", "Provider"];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function AddNotificationTemplate(props: IreminderButton) {
  const globalStore = useGlobalStore();
  const pageName = "Add Notification";
  const classes = useStyle();
  const helperAdd = helperMethods();
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [recipientTF, setRecipientTF] = useState<string>("");
  const [notificationTypeTF, setNotificationTypeTF] = useState<string>("");
  const [emailTF, setEmailTF] = useState<string>("");
  const [messageBodyTF, setMessageBodyTF] = useState<string>("");
  const [showEmailSubError, setShowEmailSubError] = useState("");
  const [showEmailMsgError, setShowEmailMsgError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [reactQuillRef, setReactQuillRef] = useState<ReactQuill>();
  const [quillRefe, setQuillRef] = useState<Quill>();

  useEffect(() => {
    trackComponent();
    attachQuillRefs();
  });

  const attachQuillRefs = () => {
    try {
      if (reactQuillRef) {
        if (typeof reactQuillRef.getEditor !== "function") return;
        setQuillRef(reactQuillRef.getEditor());
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at attachQuillRefs, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const setColor = (e) => {
    try {
      const targets = e.target;
      let count = 0;
      count += targets.dataset.count;
      targets.style.backgroundColor = count === 1 ? "#000000" : "#E9F7E5";
      targets.style.border = count === 1 ? "#000000" : "#E9F7E5";
      targets.style.color = count === 1 ? "#000000" : "#18760A";
      targets.dataset.count = count === 1 ? 0 : 1;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at setColor, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const insertText = () => {
    try {
      const range = quillRefe?.getSelection();
      const position = range ? range.index : 0;
      quillRefe?.insertText(position, "[Provider Name] ");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at insertText, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const insertTextAppointmentDate = () => {
    try {
      const range = quillRefe?.getSelection();
      const position = range ? range.index : 0;
      quillRefe?.insertText(position, "[Appointment Date [MMDD]] ");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at insertTextAppointmentDate, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const insertTextAppointmentTime = () => {
    try {
      const range = quillRefe?.getSelection();
      const position = range ? range.index : 0;
      quillRefe?.insertText(position, "[Appointment Time [00:00 AM/PM]] ");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at insertTextAppointmentTime, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const insertTextPLN = () => {
    try {
      const range = quillRefe?.getSelection();
      const position = range ? range.index : 0;
      quillRefe?.insertText(position, "[Patient Last Name] ");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at insertTextPLN, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const insertTextPFN = () => {
    try {
      const range = quillRefe?.getSelection();
      const position = range ? range.index : 0;
      quillRefe?.insertText(position, "[Patient First Name] ");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at insertTextPFN, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const insertLink = () => {
    try {
      const range = quillRefe?.getSelection();
      const position = range ? range.index : 0;
      quillRefe?.insertText(position, "[Insert Link] ");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at insertLink, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const onCancelClickEventHandler = () => {
    try {
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `The Cancel/Close button has been clicked on the Add Notification Template Popup`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      globalStore.setOpenedModalNotification("none");
      setRecipientTF("");
      setNotificationTypeTF("");
      setEmailTF("");
      setMessageBodyTF("");
      setShowEmailSubError("");
      setShowEmailMsgError("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const validate = () => {
    try {
      let validationFailed = false;

      if (emailTF === "" || emailTF.trim().length === 0) {
        validationFailed = true;
        setShowEmailSubError("Please enter Email Subject");
      } else {
        setShowEmailSubError("");
      }
      if (messageBodyTF === "" || messageBodyTF.trim().length === 0) {
        validationFailed = true;
        setShowEmailMsgError("Please enter Email Body");
      } else {
        setShowEmailMsgError("");
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Add notification API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };
  const handleChange = (event: SelectChangeEvent) => {
    try {
      setRecipientTF(event.target.value);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handlechange for dropdown Provider & Patient, Component: ${pageName} Error : ${error}`
      );
    }
  };

  const addMessage = () => {
    try {
      let materialData = false;

      materialData = validate();
      if (materialData) {
        return;
      }
      const notificationObj: IAddNotificationTemplate = {
        recipient: recipientTF,
        notificationType: notificationTypeTF,
        emailMessageSubject: emailTF,
        emailMessageBody: messageBodyTF,
        uploadedBy: "publicTester3",
      };
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `The Add button has been clicked, ${emailTF} has been added to the notification template `,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setShowSpinner(true);
      apiService
        .addNotificationTemplate(notificationObj)
        .then((res) => {
          if (res === "success") {
            helperAdd.setSuccessMessageEvent(
              "Notification Template added successfully!"
            );
          }

          props.closeCallback();
          onCancelClickEventHandler();
          globalStore.setOpenedModalNotification("none");
          helperAdd.setSuccessMessageEvent(
            "Notification Template added successfully!"
          );
          props.callBackGetreminderList();
          setShowSpinner(false);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at add Notification Template API call, Component: ${pageName} Error : ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at add Notification Template API, Component: ${pageName} Error : ${error}`
      );
    }
  };

  /* onBlur handler for Email subject Type field */
  const onBlurnotificationType = () => {
    try {
      if (emailTF === "" && emailTF.trim().length === 0) {
        setShowEmailSubError("Please enter Email Subject");
      } else {
        setShowEmailSubError("");
      }
      if (messageBodyTF === "" && messageBodyTF.trim().length === 0) {
        setShowEmailMsgError("Please enter Email body");
      } else {
        setShowEmailMsgError("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurnotificationType, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <div>
        <Dialog
          onClose={() => globalStore.setOpenedModalNotification("none")}
          open={props.popup}
          classes={{ paper: classes.dialog }}
          className="notificationContainer"
        >
          <DialogTitle className="notificationTitle">
            <b style={{ marginRight: 340 }}>Add Notification Template</b>
            <IconButton
              onClick={() => {
                onCancelClickEventHandler();
                props.closeCallback();
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="notificationContent">
            <Grid className={"notification-text"}>Recipient</Grid>
            <FormControl
              className={"notificationText-box"}
              sx={{ width: "100%" }}
            >
              <Select
                value={recipientTF}
                onChange={handleChange}
                displayEmpty
                placeholder="Select Recipient"
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span className="dropdownRecipient">
                        Select Recipient
                      </span>
                    );
                  }

                  return selected;
                }}
              >
                {optionSelect.map((rem) => (
                  <MenuItem
                    key={rem}
                    value={rem}
                    style={{
                      display: "grid",
                      gap: 10,
                      flexWrap: "wrap",
                    }}
                  >
                    {rem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid className={"notification-text"}>Notification Type</Grid>
            <Grid className={"notificationText-box"}>
              <TextField
                value={notificationTypeTF}
                fullWidth
                variant="outlined"
                placeholder="Enter Notification Type"
                required
                id="messageType"
                onChange={(e) => {
                  setNotificationTypeTF(e.target.value);
                }}
              />
            </Grid>
            <Grid className={"notification-text"}>
              Email Subject
              <span className={"material-title"}>*</span>
            </Grid>
            <Grid className={"notificationText-box"}>
              <TextField
                value={emailTF}
                fullWidth
                variant="outlined"
                placeholder="Enter Email Subject Here"
                required
                id="messageType"
                onChange={(e) => {
                  setEmailTF(e.target.value);
                }}
                error={!!showEmailSubError}
                onBlur={() => onBlurnotificationType()}
              />
            </Grid>
            <div className={"material-error"}>{showEmailSubError || ""}</div>
            <Grid className={"notification-text"}>
              Email Message Body
              <span className={"material-title"}>*</span>
            </Grid>

            <Grid className="editor-wrapper">
              <ReactQuill
                ref={(e1: ReactQuill) => setReactQuillRef(e1)}
                theme="snow"
                value={messageBodyTF}
                onChange={setMessageBodyTF}
                placeholder="Enter Email Message Body Here"
                className="ql-toolbar"
                modules={AddNotificationTemplate.modules}
              />
            </Grid>
          </DialogContent>
          <div className={"emailBody-error"}>{showEmailMsgError || ""}</div>
          <div className="appointmentText">
            Use the below values to add appointment specific information to your
            template
          </div>
          <Grid className="templatesButton">
            <button
              type="button"
              className={
                messageBodyTF.includes("[Provider Name]")
                  ? "buttonEmailGreen"
                  : "buttonEmailWhite"
              }
              onClick={(e) => {
                insertText();
                setColor(e);
              }}
            >
              Provider Name
            </button>
            <button
              type="button"
              className={
                messageBodyTF.includes("[Appointment Date [MMDD]]")
                  ? "buttonEmailGreenSpace"
                  : "buttonEmailWhiteSpace"
              }
              onClick={(e) => {
                insertTextAppointmentDate();
                setColor(e);
              }}
            >
              Appointment Date [MMDD]
            </button>
            <button
              type="button"
              className={
                messageBodyTF.includes("[Appointment Time [00:00 AM/PM]]")
                  ? "buttonEmailGreenSpace"
                  : "buttonEmailWhiteSpace"
              }
              onClick={(e) => {
                insertTextAppointmentTime();
                setColor(e);
              }}
            >
              Appointment Time [00:00 AM/PM]
            </button>
            <button
              type="button"
              className={
                messageBodyTF.includes("[Patient Last Name]")
                  ? "buttonEmailGreen"
                  : "buttonEmailWhite"
              }
              onClick={(e) => {
                insertTextPLN();
                setColor(e);
              }}
            >
              Patient Last Name
            </button>
            <button
              type="button"
              className={
                messageBodyTF.includes("[Patient First Name]")
                  ? "buttonEmailGreenSpace"
                  : "buttonEmailWhiteSpace"
              }
              onClick={(e) => {
                insertTextPFN();
                setColor(e);
              }}
            >
              Patient First Name
            </button>
            <button
              type="button"
              className={
                messageBodyTF.includes("[Insert Link]")
                  ? "buttonEmailGreenSpace"
                  : "buttonEmailWhiteSpace"
              }
              onClick={(e) => {
                insertLink();
                setColor(e);
              }}
            >
              Insert Link
            </button>
          </Grid>
          <Grid>
            <DialogActions className="btnClick">
              <Button
                onClick={() => {
                  onCancelClickEventHandler();
                  props.closeCallback();
                }}
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button onClick={addMessage}>
                <img
                  src={add}
                  alt="save"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
            {showSpinner && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 999,
                }}
                open={showSpinner}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Grid>
        </Dialog>
      </div>
    );
  });
}
