/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";
import MinimalLayout from "./MinimalLayout";
import CompactLayout from "./CompactLayout";
import StandardLayout from "./StandardLayout";
import ExtendedLayout from "./ExtendedLayout";
import { apiService } from "../../services/ApiService";
import { ICustomLayoutType, IThemeColors } from "../../state/StoreTypes";

export default function Layout() {
  const globalStore = useGlobalStore();
  const pageName = "Layout";
  const appInsights = useAppInsightsContext();
  const [searchText, setSearchText] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [activeLayout, setActiveLayout] = useState(
    globalStore.customLayoutData.layoutStyle
  );
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  const handleClick = (layout) => {
    globalStore.setLayoutType(layout);
    globalStore.setCustomTabName(`${layout} layout`);
  };

  return useObserver(() => {
    return (
      <Grid className="layout">
        <div className="header">Header Layout</div>
        <div className="layoutStyle">
          <p>Select Layout Style</p>
          <div className="layoutBox">
            <div className="container">
              <div
                className={
                  globalStore.layoutType === "Minimal"
                    ? "layoutActive"
                    : "layoutContainer"
                }
                onClick={() => handleClick("Minimal")}
              >
                <div className="minimalLayout" />
              </div>
              <p
                className={
                  globalStore.layoutType === "Minimal" ? "activeText" : "text"
                }
              >
                Minimal
              </p>
            </div>
            <div className="container">
              <div
                className={
                  globalStore.layoutType === "Compact"
                    ? "layoutActive"
                    : "layoutContainer"
                }
                onClick={() => handleClick("Compact")}
              >
                <div className="compactLayout" />
              </div>
              <p
                className={
                  globalStore.layoutType === "Compact" ? "activeText" : "text"
                }
              >
                Compact
              </p>
            </div>
            <div className="container">
              <div
                className={
                  globalStore.layoutType === "Standard"
                    ? "layoutActive"
                    : "layoutContainer"
                }
                onClick={() => handleClick("Standard")}
              >
                <div className="standardLayout">
                  <div className="square" />
                  <div className="line" />
                  <div className="dottedLine" />
                </div>
              </div>
              <p
                className={
                  globalStore.layoutType === "Standard" ? "activeText" : "text"
                }
              >
                {" "}
                Standard
              </p>
            </div>
            <div className="container">
              <div
                className={
                  globalStore.layoutType === "Extended"
                    ? "layoutActive"
                    : "layoutContainer"
                }
                onClick={() => handleClick("Extended")}
              >
                <div className="extendedLayout">
                  <div className="square" />
                  <div className="line" />
                  <div className="secondLine" />
                  <div className="dottedLine" />
                </div>
              </div>
              <p
                className={
                  globalStore.layoutType === "Extended" ? "activeText" : "text"
                }
              >
                Extended
              </p>
            </div>
          </div>
        </div>
        <div>
          {globalStore.layoutType === "Extended" ? (
            <ExtendedLayout />
          ) : globalStore.layoutType === "Compact" ? (
            <CompactLayout />
          ) : globalStore.layoutType === "Standard" ? (
            <StandardLayout />
          ) : (
            <MinimalLayout />
          )}
        </div>
      </Grid>
    );
  });
}
