/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import save from "../../assets/save.png";
import cancel from "../../assets/cancel.png";
import { IUpdateAppointmentMsg } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";

const useStyle = makeStyles({
  dialog: {
    width: 523,
    height: 551,
    maxWidth: 720,
  },
});

interface IAppointmentMessageButton {
  callBackGetMsgList: () => void;
  popup: boolean;
  closeCallback: () => void;
}

export default function EditAppointmentMessage(
  props: IAppointmentMessageButton
) {
  const globalStore = useGlobalStore();
  const pageName = "Edit Appointment Message";
  const classes = useStyle();
  const helperAdd = helperMethods();
  const [AppointmentMessageIn, setAppointmentMessage] = useState<string>("");
  const [AppointmentMessageTypeIn, setAppointmentMessageType] =
    useState<string>("");
  const [showMsgErrorMsg, setShowMsgErrorMsg] = useState("");
  const [showMsgTypeErrorMsg, setShowMsgTypeErrorMsg] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const onCancelClickEventHandler = () => {
    try {
      setAppointmentMessage("");
      setAppointmentMessageType("");
      setShowMsgErrorMsg("");
      setShowMsgTypeErrorMsg("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  useEffect(() => {
    if (globalStore.actionMenuSelectedAppointmentMsg) {
      setAppointmentMessage(
        globalStore.actionMenuSelectedAppointmentMsg?.message
      );
      setAppointmentMessageType(
        globalStore.actionMenuSelectedAppointmentMsg?.messageType
      );
    }
  }, [globalStore.actionMenuSelectedAppointmentMsg]);

  const validate = () => {
    try {
      let validationFailed = false;

      if (
        AppointmentMessageIn === "" ||
        AppointmentMessageIn.trim().length === 0
      ) {
        validationFailed = true;
        setShowMsgErrorMsg("Please enter Appointment Message");
      } else {
        setShowMsgErrorMsg("");
      }
      if (
        AppointmentMessageTypeIn === "" ||
        AppointmentMessageTypeIn.trim().length === 0
      ) {
        validationFailed = true;
        setShowMsgTypeErrorMsg("Please enter Appointment Message Type");
      } else {
        setShowMsgTypeErrorMsg("");
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Edit Appointment API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  const updateField = () => {
    try {
      setAppointmentMessage("");
      setAppointmentMessageType("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at updateField, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const editAppointmentMessage = () => {
    try {
      let materialData = false;
      materialData = validate();
      if (materialData) {
        return;
      }
      const appointmentMsgObj: IUpdateAppointmentMsg = {
        id: globalStore.actionMenuSelectedAppointmentMsg?.id,
        message: AppointmentMessageIn,
        messageType: AppointmentMessageTypeIn,
        uploadedBy: "publicTester3",
        uploadedTime: "",
      };
      setShowSpinner(true);
      apiService
        .updateAppointmentMsg(appointmentMsgObj)
        .then((res) => {
          if (res === "success") {
            helperAdd.setSuccessMessageEvent(
              "Appointment Message updated successfully!"
            );
          }
          props.closeCallback();
          updateField();
          helperAdd.setSuccessMessageEvent(
            "Appointment Message updated successfully!"
          );
          props.callBackGetMsgList();
          setShowSpinner(false);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at Edit Appointment Msg API call, Component: ${pageName} Error : ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Edit Appointment Msg API, Component: ${pageName} Error : ${error}`
      );
    }
  };

  /* onBlur handler for AppointmentMessage field */
  const onBlurMsg = () => {
    try {
      if (
        AppointmentMessageIn === "" &&
        AppointmentMessageIn.trim().length === 0
      ) {
        setShowMsgErrorMsg("Please enter Appointment Message");
      } else {
        setShowMsgErrorMsg("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurMsg, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* onBlur handler for AppointmentMessage Type field */
  const onBlurMsgType = () => {
    try {
      if (
        AppointmentMessageTypeIn === "" &&
        AppointmentMessageTypeIn.trim().length === 0
      ) {
        setShowMsgTypeErrorMsg("Please enter Appointment Message Type");
      } else {
        setShowMsgTypeErrorMsg("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurMsgType, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <div>
        <Dialog
          onClose={() => globalStore.setOpenedModalMsg("none")}
          open={props.popup}
          classes={{ paper: classes.dialog }}
          className="appointmentMsgContainer"
        >
          <DialogTitle className="appointmentMsgTitle">
            <b style={{ marginRight: 160 }}>Edit Appointment Message</b>
            <IconButton
              onClick={() => {
                onCancelClickEventHandler();
                props.closeCallback();
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="appointmentMsgContent">
            <Grid className={"appointmentMsg-text"}>
              Message Type
              <span className={"material-title"}>*</span>
            </Grid>
            <Grid className={"appointmentMsgText-box"}>
              <TextField
                value={AppointmentMessageTypeIn}
                fullWidth
                variant="outlined"
                placeholder="Message Type"
                required
                id="AppointmentMessageType"
                onChange={(e) => {
                  setAppointmentMessageType(e.target.value);
                }}
                error={!!showMsgTypeErrorMsg}
                onBlur={() => onBlurMsgType()}
              />
            </Grid>
            <div className={"material-error"}>{showMsgTypeErrorMsg || ""}</div>
            <Grid className={"appointmentMsg-text"}>
              Message Description
              <span className={"material-title"}>*</span>
            </Grid>
            <Grid className={"appointmentMsgText-box2"}>
              <TextField
                value={AppointmentMessageIn}
                fullWidth
                multiline
                rows={8}
                variant="outlined"
                placeholder="Type Message Description"
                required
                id="AppointmentMessage"
                onChange={(e) => {
                  setAppointmentMessage(e.target.value);
                }}
                error={!!showMsgErrorMsg}
                onBlur={() => onBlurMsg()}
              />
            </Grid>
            <div className={"material-error"}>{showMsgErrorMsg || ""}</div>
          </DialogContent>
          <Grid>
            <DialogActions className="btnClick">
              <Button
                onClick={() => {
                  onCancelClickEventHandler();
                  props.closeCallback();
                }}
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button onClick={editAppointmentMessage}>
                <img
                  src={save}
                  alt="save"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
            {showSpinner && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 999,
                }}
                open={showSpinner}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Grid>
        </Dialog>
      </div>
    );
  });
}
