import { AudioVideoSettingsType } from "./GlobalStateType";

export interface AzureCommunicationUserToken {
  user: {
    id?: string;
  };
  accessToken: {
    token: string;
    expiresOn: string;
  };
}
export interface IAcsParticipants {
  televisitId: string;
  participantFirstName: string;
  participantLastName: string;
  participantEmailAddress: string;
  participantRole: string;
  participantDOB: string;
}

export interface InewMaterial {
  title: string;
  description: string;
  url: string;
  fileName: string;
  uploadedBy: string;
  fileExtension: string;
  createdTime: string;
  modifiedDate: string;
  uniqueFileName: string;
  modifiedBy: string;
  thumbnailFileName: string;
  thumbnailUniqueFileName: string;
  thumbnailUrl: string;
  thumbnailExtension: string;
  isActive: string;
}
export interface IAddNewResource {
  title: string;
  description: string;
  uploadedBy: string;
  uploadFile: FormData;
  thumbnailFile: FormData;
}
export interface IDeleteMaterial {
  Id: string;
}
export interface IuploadImage {
  fileName: string;
}
export interface IEditMaterials {
  id: string | undefined;
  url: string | undefined;
  thumbnailUrl: string | undefined;
  title: string | undefined;
  description: string | undefined;
  modifiedBy: string;
  uploadFile: File | undefined | string;
  thumbnailUniqueFileName: string | undefined;
  thumbnailUploadFile: File | undefined | string;
  isActive: boolean;
}

export interface IAddConfigurationMsg {
  id: string;
  message: string;
  messageType: string;
  createdTime: string;
  createdBy: string;
  type: string;
}

export interface ISystemSettings {
  id: string;
  settingsType: string;
  time: string;
  uploadedTime: string;
  uploadedBy: string;
  title: string;
  description: string;
}

export interface ISystemSettingsNew {
  id: string;
  settingsType: string;
  time: string;
  uploadedTime: string;
  uploadedBy: string;
  title: string;
  description: string;
  isSelected: boolean;
}

export interface IUpdateSystemSettings {
  id: string;
  settingsType: string;
  time: string;
}
export interface IGetReminderList {
  id: string;
  minutesBefore: string;
  uploadedBy: string;
  uploadedTime: string;
}
export interface IAddReminderList {
  id: string;
  minutesBefore: string;
  uploadedBy: string;
  uploadedTime: string;
}
export interface IEditReminderList {
  id: string | undefined;
  minutesBefore: string;
  uploadedBy: string;
  uploadedTime: string;
}
export interface IDelReminderList {
  id: string | undefined;
  minutesBefore: string | undefined;
  uploadedBy: string | undefined;
  uploadedTime: string | undefined;
}

export interface IDurationMin {
  key: string;
  value: string;
}

export interface IAudioVideoSettings {
  id: string;
  settingName: string;
  settingOption: boolean;
  settingsBy: string;
  settingsType: AudioVideoSettingsType;
}

export interface IUpdateAudioVideoSettings {
  id: string;
  settingName: string;
  settingOption: boolean;
}

export interface IUpdateState {
  id: string | undefined;
  isActive: boolean | undefined;
}

export interface IAddRelation {
  id: string;
  relation: string;
  uploadedTime: string;
  uploadedBy: string;
}

export interface IEditRelation {
  id: string | undefined;
  relation: string;
}

export interface IMeetingSettings {
  id: string;
  settingsType: string;
  settingsName: string;
  settingsValue: string;
  isDeleted: boolean;
  uploadedTime: string;
  uploadedBy: string;
}
export interface IGetAppointmentMsg {
  id: string;
  message: string;
  messageType: string;
  uploadedTime: string;
  uploadedBy: string;
}
export interface IAddAppointmentMsg {
  message: string;
  messageType: string;
  uploadedTime: string;
  uploadedBy: string;
}
export interface IDelAppointmentMsg {
  id: string | undefined;
  messageType: string | undefined;
}
export interface IUpdateAppointmentMsg {
  id: string | undefined;
  message: string;
  messageType: string;
  uploadedTime: string;
  uploadedBy: string;
}
export interface IGetNotificationTemplate {
  id: string;
  recipient: string;
  notificationType: string;
  emailMessageSubject: string;
  emailMessageBody: string;
  uploadedTime: string;
  uploadedBy: string;
}
export interface IAddNotificationTemplate {
  recipient: string;
  notificationType: string;
  emailMessageSubject: string;
  emailMessageBody: string;
  uploadedBy: string;
}
export interface IUpdateNotificationTemplate {
  id: string | undefined;
  recipient: string;
  notificationType: string;
  emailMessageSubject: string;
  emailMessageBody: string;
  uploadedBy: string;
  uploadedTime: string;
}
export interface IFetchTestUser {
  isChecked: boolean;
  userId: string;
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
  createdTime: string;
}
export interface IAddTestUser {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
}
export interface ITestProvider {
  providerId: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
}
export interface ITestAppointmentList {
  isChecked: boolean;
  id: string;
  televisitId: string;
  patientId: string;
  patientMeetingId: string;
  teamsMeetingId: string;
  teamsMeetingUrl: string;
  scheduleDateTimeTZD: string;
  startDateTime: string;
  endDateTime: string;
  patientFirstName: string;
  patientLastName: string;
  patientMiddleName: string;
  dateOfBirth: string;
  patientPhone: string;
  patientEmail: string;
  status: string;
  providerRequest: ITestProvider[];
  duration: number;
  patientMeetingLink: string;
  chatId: string;
  appointmentTypeFullName: string;
  clinicianMeetingLink: string;
  isMeetingDeleted: boolean;
}
export interface IAddTestAppointment {
  firstName: string;
  providerRequest: ITestProvider;
  appointmentDuration: number;
  scheduledDateTime: string;
  lastName: string;
  middleName: string;
  userId: string;
  email: string;
  phone: string;
  timeZone: string;
}
export interface IDelTestUser {
  userId: string[];
}

export interface IDelTestAppointment {
  televisitId: string[];
}

export interface IAddLanguage {
  languageName: string;
  id: string;
  uploadedTime: string;
  uploadedBy: string;
}

export interface IFetchLanguage {
  languageName: string;
  id: string;
  uploadedTime: string;
  uploadedBy: string;
}

export interface IUpdateLanguage {
  id: string | undefined;
  languageName: string;
  uploadedBy: string;
}

export interface IDelLanguage {
  id: string | undefined;
  uploadedBy: string;
  languageName: string | undefined;
  uploadedTime: string | undefined;
}

export const ConstantVariables = {
  ErrorBoundaryTextMessage: "Something went wrong. Please try again.",
  DashBoardRefreshTime: 30000,
  PopupHideTime: 5000,
  PatientAppLink: "https://fe-virtualvisits-dev-eastus.azurewebsites.net/",
  ClientID: "a5ba5d31-5daf-4cfd-85b0-f3958a9f90f4",
  Authority:
    "https://login.microsoftonline.com/e03de411-1263-4660-8112-fe1342e1dca3",
  Scope: "a5ba5d31-5daf-4cfd-85b0-f3958a9f90f4/.default",
  TenantID: "e03de411-1263-4660-8112-fe1342e1dca3",
  User_Details_Failed_Message_Text: "Failed to retrieve user details",
};

export class AppInsightConstants {
  public static readonly Information = "Information";

  public static readonly Error = "Error";

  public static readonly Exception = "Exception";
}

export interface IPostUserRole {
  groupId: string;
  userId: string;
}

export interface IDeleteVideo {
  Id: string;
}

export interface IFontDetails {
  heading: string;
  fontFamily: string;
  fontWeight: string;
  lineHeight: string;
  fontSize: string;
  fontStyle: string;
  letterSpacing: string;
  textIndent: string;
  textTransform: string;
  textDecoration: string;
  isPopupOpen: boolean;
}

export interface ICustomLayoutType {
  layoutStyle: string;
  appTagLine: string;
  fileName: string;
  id: string;
  uniqueFileName: string;
  uploadedBy: string;
  uploadedTime: string;
  url: string;
}

export interface IThemeColors {
  id: string;
  themeName: string;
  primaryColor: string;
  secondaryColor: string;
  pageBackgroundColor: string;
  subPageBackgroundColor: string;
  alertColor: string;
  headingsTextColor: string;
  bodyTextColor: string;
  createdBy: string;
  createdTime: string;
}

export interface IColors {
  themeName: string;
  primaryColor: string;
  secondaryColor: string;
  pageBackgroundColor: string;
  subPageBackgroundColor: string;
  alertColor: string;
  headingsTextColor: string;
  bodyTextColor: string;
  createdTime: string;
}

export interface IThemeDetails {
  id: string;
  isSelected: boolean;
  themeData: IColors;
  createdBy: string;
}
export interface IGetMeetingLogs {
  meetingId: string | undefined;
  timespan: string;
}

export interface statusLog {
  state: string;
  status: string;
  timeStamp: string;
  message: string;
  description: string;
  actions: null | actions[];
}

export interface actions {
  action: string;
  status: string;
  time: string;
}

export interface IMeetingLog {
  emailStatus: statusLog | null;
  meetingLink: statusLog | null;
  loginDetails: statusLog | null;
  deviceSettings: statusLog | null;
  lobbyPage: statusLog | null;
  callStatus: statusLog | null;
  exitStatus: statusLog | null;
}
