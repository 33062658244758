/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { AppInsightMethods } from "../../AppInsightMethods";

interface IPaginationProps {
  itemCount: number;
  itemsPerPage: number;
  paginationCallback: (startIndex: number, endIndex: number) => void;
}

export default function Pagination(props: IPaginationProps) {
  const pageName = "Pagination";
  const appInsights = useAppInsightsContext();
  const [currentPage, setCurrentPage] = useState(1);
  const trackComponent = useTrackMetric(appInsights, pageName);

  const range = (from: number, to: number, step = 1) => {
    let i = from;
    const rangeArr: number[] = [];

    while (i <= to) {
      rangeArr.push(i);
      i += step;
    }

    return rangeArr;
  };

  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    props.paginationCallback(
      (currentPage - 1) * props.itemsPerPage,
      currentPage * props.itemsPerPage
    );
  }, [currentPage]);

  const remainder = props.itemCount % props.itemsPerPage;
  const endIndexWithDecimal =
    remainder === 0
      ? props.itemCount / props.itemsPerPage
      : props.itemCount / props.itemsPerPage + 1;
  const endIndexNo = Number(endIndexWithDecimal.toString().split(".")[0]);
  const rangeArr: number[] = range(1, endIndexNo);
  const calculateIndexValues = (currentPageParam: number) => {
    try {
      const startIndex = (currentPageParam - 1) * props.itemsPerPage;
      const endIndex =
        currentPageParam * props.itemsPerPage > props.itemCount
          ? props.itemCount
          : currentPageParam * props.itemsPerPage;

      props.paginationCallback(startIndex, endIndex);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at calculateIndexValues, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const startValue = (currentPage - 1) * props.itemsPerPage;

  return (
    <Grid className={"pagination"}>
      <Grid className={"pagination-left"}>
        <span>{`${startValue === 0 ? 1 : startValue} - ${
          currentPage * props.itemsPerPage > props.itemCount
            ? props.itemCount
            : currentPage * props.itemsPerPage
        } of ${" "}${props.itemCount}`}</span>
      </Grid>
      <Grid className={"pagination-right"}>
        <span className={"pagination-pageCount"}>
          <FormControl>
            <Select
              variant="outlined"
              id="pagination_pageNo"
              value={currentPage}
              onChange={(e) => {
                setCurrentPage(Number(e.target.value));
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {rangeArr &&
                rangeArr.length > 0 &&
                rangeArr.map((val: number) => {
                  return (
                    <MenuItem value={val} key={val}>
                      {val}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </span>
        <span className={"pagination-straightLine"} />
        <span
          className={`borderCls arrowCls ${
            currentPage === 1 ? "disabled" : ""
          }`}
        >
          <ArrowBackRoundedIcon
            onClick={() => {
              if (currentPage === 1) return;

              setCurrentPage(currentPage - 1);
              calculateIndexValues(currentPage - 1);
            }}
            color={currentPage === 1 ? "disabled" : "inherit"}
          />
        </span>
        <span
          className={`borderCls arrowCls ${
            currentPage === endIndexNo ? "disabled" : ""
          }`}
        >
          <ArrowForwardRoundedIcon
            color={currentPage === endIndexNo ? "disabled" : "inherit"}
            onClick={() => {
              if (currentPage === endIndexNo) return;

              setCurrentPage(currentPage + 1);
              calculateIndexValues(currentPage + 1);
            }}
          />
        </span>
      </Grid>
    </Grid>
  );
}
