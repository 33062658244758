/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
} from "@material-ui/core";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import moment from "moment";
import { dashboardUtils } from "./SummaryTableUtils";
import { AppInsightMethods } from "../../AppInsightMethods";
import { IAppointmentList, SortType } from "../../state/GlobalStateType";
import {
  ConstantVariables,
  AppInsightsConstants as Constants,
} from "../../Constants";
import arrowUpEnabled from "../../assets/arrow_up_enabled.svg";
import arrowDownDisabled from "../../assets/arrow_down_disabled.svg";
import arrowDownEnabled from "../../assets/arrow_down_enabled.svg";
import arrowUpDisabled from "../../assets/arrow_up_disabled.svg";
import infoIcon from "../../assets/infoIcon.svg";
import Pagination from "../Pagination/Pagination";
import { useGlobalStore } from "../../state/GlobalStore";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SummaryTable() {
  const pageName = "SummaryTable";
  const appInsights = useAppInsightsContext();

  const trackComponent = useTrackMetric(appInsights, pageName);
  const classes = useStyles();
  const globalStore = useGlobalStore();
  const alignLeftText = "left";
  const headerCellText = "headerCell";
  const noneText = "None";
  const descText = "Desc";
  const ascText = "Asc";
  const itemsPerPageCount = 7;

  const [patientNameSortType, setPatientNameSortType] =
    useState<SortType>(ascText);
  const [appointmentTimeSortType, setAppointmentTimeSortType] =
    useState<SortType>(noneText);
  const [appointmentDateSortType, setApointmentDateSortType] =
    useState<SortType>(noneText);
  const [patientIDSortType, setpatientIDSortType] =
    useState<SortType>(noneText);
  const [currentSortColumn, setCurrentSortColumn] = useState("patientName");

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPageCount);

  useEffect(() => {
    trackComponent();
  }, []);

  /* Method for sorting meeting list based on patient name using keyboard event */
  const patientNameKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        patientNameClickHandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at patientNameKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for sorting meeting list based on patient id using keyboard event */
  const patientIdKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        patientIdClickHandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at patientIdKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for sorting meeting list based on meeting time using keyboard event */
  const appointmentTimeKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        appointmentTimeClickHandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at appointmentTimeKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for sorting meeting list based on meeting time */
  const appointmentTimeClickHandler = () => {
    try {
      if (
        appointmentTimeSortType === noneText ||
        appointmentTimeSortType === descText
      ) {
        setAppointmentTimeSortType(ascText);
      } else if (appointmentTimeSortType === ascText) {
        setAppointmentTimeSortType(descText);
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Appointment Time Column clicked for the sorting. SortType : ${appointmentTimeSortType}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setCurrentSortColumn("appointmentTime");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at appointmentTimeClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for sorting meeting list based on meeting Date */
  const appointmentDateClickHandler = () => {
    try {
      if (
        appointmentDateSortType === noneText ||
        appointmentDateSortType === descText
      ) {
        setApointmentDateSortType(ascText);
      } else if (appointmentDateSortType === ascText) {
        setApointmentDateSortType(descText);
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Appointment Date Column clicked for the sorting. SortType : ${appointmentDateSortType}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setCurrentSortColumn("appointmentDate");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at appointmentDateClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for sorting meeting list based on meeting date using keyboard event */
  const appointmentDateKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        appointmentDateClickHandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at appointmentDateKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for sorting meeting list based on patient name */
  const patientNameClickHandler = () => {
    try {
      if (
        patientNameSortType === noneText ||
        patientNameSortType === descText
      ) {
        setPatientNameSortType(ascText);
      } else if (patientNameSortType === ascText) {
        setPatientNameSortType(descText);
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Patient Name Column clicked for the sorting. SortType : ${patientNameSortType}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setCurrentSortColumn("patientName");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at patientNameClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for sorting meeting list based on patient name */
  const patientIdClickHandler = () => {
    try {
      if (patientIDSortType === noneText || patientIDSortType === descText) {
        setpatientIDSortType(ascText);
      } else if (patientIDSortType === ascText) {
        setpatientIDSortType(descText);
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Patient Id Column clicked for the sorting. SortType : ${patientIDSortType}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setCurrentSortColumn("patientID");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at patientIdClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method to get sorting icon based on the sorting order */
  const getSortIcon = (columnName: string): JSX.Element | null => {
    try {
      switch (columnName) {
        case "patientName":
          return getIcon(patientNameSortType, columnName);
        case "appointmentTime":
          return getIcon(appointmentTimeSortType, columnName);
        case "patientID":
          return getIcon(patientIDSortType, columnName);
        case "appointmentDate":
          return getIcon(appointmentDateSortType, columnName);
        default:
          return null;
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at gerSortIcon, Component: ${pageName}, Error: ${error}`
      );
      return null;
    }
  };

  /* Method to get sorting icon based on the sorting order */
  const getIcon = (
    sortType: SortType,
    columnName: string
  ): JSX.Element | null => {
    return sortType === ascText ? (
      <>
        {currentSortColumn === columnName ? (
          <img
            className="sort-icons"
            src={arrowUpEnabled}
            alt="sort-column-ascending-enabled"
          />
        ) : (
          <img
            className="sort-icons"
            src={arrowUpDisabled}
            alt="sort-column-ascending-disabled"
          />
        )}
      </>
    ) : sortType === descText ? (
      <>
        {currentSortColumn === columnName ? (
          <img
            className="sort-icons"
            src={arrowDownEnabled}
            alt="sort-column-descending-enabled"
          />
        ) : (
          <img
            className="sort-icons"
            src={arrowDownDisabled}
            alt="sort-column-descending-disabled"
          />
        )}
      </>
    ) : (
      <>
        <img
          className="sort-icons"
          src={arrowDownDisabled}
          alt="sort-column-disabled"
        />
      </>
    );
  };

  return useObserver(() => {
    let appointments: IAppointmentList[] =
      globalStore.currentCard !== "All"
        ? globalStore.appointmentsList?.filter(
            (val: IAppointmentList) => val.status === globalStore.currentCard
          )
        : globalStore.appointmentsList;

    if (
      (patientNameSortType === ascText || patientNameSortType === descText) &&
      currentSortColumn === "patientName"
    ) {
      if (patientNameSortType === ascText) {
        appointments = appointments.sort((a, b) =>
          a.patientFirstName.toLowerCase() > b.patientFirstName.toLowerCase()
            ? 1
            : -1
        );
      } else {
        appointments = appointments.sort((a, b) =>
          a.patientFirstName.toLowerCase() < b.patientFirstName.toLowerCase()
            ? 1
            : -1
        );
      }
    } else if (
      (patientIDSortType === ascText || patientIDSortType === descText) &&
      currentSortColumn === "patientID"
    ) {
      if (patientIDSortType === ascText) {
        appointments = appointments.sort((a, b) =>
          a.patientId.toLowerCase() > b.patientId.toLowerCase() ? 1 : -1
        );
      } else {
        appointments = appointments.sort((a, b) =>
          a.patientFirstName.toLowerCase() < b.patientFirstName.toLowerCase()
            ? 1
            : -1
        );
      }
    } else if (
      (appointmentDateSortType === ascText ||
        appointmentDateSortType === descText) &&
      currentSortColumn === "appointmentDate"
    ) {
      if (appointmentDateSortType === ascText) {
        appointments = appointments.sort((a, b) =>
          new Date(a.scheduleDateTimeTZD) > new Date(b.scheduleDateTimeTZD)
            ? 1
            : -1
        );
      } else {
        appointments = appointments.sort((a, b) =>
          new Date(a.scheduleDateTimeTZD) < new Date(b.scheduleDateTimeTZD)
            ? 1
            : -1
        );
      }
    } else if (
      (appointmentTimeSortType === ascText ||
        appointmentTimeSortType === descText) &&
      currentSortColumn === "appointmentTime"
    ) {
      if (appointmentTimeSortType === ascText) {
        appointments = appointments.sort((a, b) => {
          return (
            new Date(a.scheduleDateTimeTZD).getTime() -
            new Date(b.scheduleDateTimeTZD).getTime()
          );
        });
      } else {
        appointments = appointments.sort((a, b) => {
          return (
            new Date(b.scheduleDateTimeTZD).getTime() -
            new Date(a.scheduleDateTimeTZD).getTime()
          );
        });
      }
    }

    return (
      <>
        {appointments && appointments.length > 0 ? (
          <Grid className="summaryTableMain">
            <TableContainer className="summaryTable">
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={"appointmentListHeader"}>
                  <TableRow>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 180 }}
                      className={`${headerCellText} sortIcon`}
                      onClick={() => patientNameClickHandler()}
                      onKeyDown={(e) => patientNameKeyDown(e)}
                      tabIndex={0}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_One_Text}
                      {getSortIcon("patientName")}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 150 }}
                      className={`${headerCellText} sortIcon`}
                      onClick={() => patientIdClickHandler()}
                      onKeyDown={(e) => patientIdKeyDown(e)}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_Two_Text}
                      {getSortIcon("patientID")}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 190 }}
                      className={`${headerCellText} sortIcon`}
                      onClick={() => appointmentDateClickHandler()}
                      onKeyDown={(e) => appointmentDateKeyDown(e)}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_Three_Text}
                      {getSortIcon("appointmentDate")}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 190 }}
                      className={`${headerCellText} sortIcon`}
                      onClick={() => appointmentTimeClickHandler()}
                      onKeyDown={(e) => appointmentTimeKeyDown(e)}
                      tabIndex={0}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_Four_Text}
                      {getSortIcon("appointmentTime")}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 180 }}
                      className={headerCellText}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_Five_Text}
                      {/* <img
                      style={{ marginLeft: "5px" }}
                      src={infoIcon}
                      alt="info-icon"
                    /> */}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={`${headerCellText}`}
                      tabIndex={0}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_Six_Text}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      className={headerCellText}
                      tabIndex={0}
                      style={{ minWidth: 128 }}
                    >
                      {""}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={"appointmentListBody"}>
                  {appointments
                    ?.slice(startIndex, endIndex)
                    .map((row: IAppointmentList, index: number) => (
                      <TableRow key={`${row.patientId}-${index}`}>
                        <TableCell align={alignLeftText} className="bodyCell">
                          <span className="bold">{`${row.patientFirstName} ${row.patientLastName}`}</span>
                        </TableCell>
                        <TableCell align={alignLeftText} className="bodyCell">
                          {row.patientId}
                        </TableCell>
                        <TableCell align={alignLeftText} className="bodyCell">
                          {moment(new Date(row.scheduleDateTimeTZD)).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell align={alignLeftText} className="bodyCell">
                          {moment(new Date(row.scheduleDateTimeTZD)).format(
                            "LT"
                          )}
                        </TableCell>
                        <TableCell align={alignLeftText} className="bodyCell">
                          <span
                            className={`statusColor ${dashboardUtils.getBackGroundColor(
                              row.status
                            )}`}
                          >
                            {row.status}
                            {row.status.toLowerCase() !== "scheduled" &&
                            row.status.toLowerCase() !== "completed"
                              ? " (2 Mins) "
                              : ""}
                          </span>
                        </TableCell>
                        <TableCell align={alignLeftText} className="bodyCell">
                          {`${row.providerRequest[0].title}. ${row.providerRequest[0].firstName} ${row.providerRequest[0].middleName} ${row.providerRequest[0].lastName}`}
                        </TableCell>
                        <TableCell
                          align={alignLeftText}
                          className="bodyCell"
                          style={{ color: "#1447D4", cursor: "pointer" }}
                          onClick={() => {
                            globalStore.setSummaryDetailsPage(true);
                            globalStore.setMeetingDetailedView(
                              appointments,
                              index,
                              appointments.length
                            );
                          }}
                        >
                          View Details
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <Grid container className="noResultSummary">
            <Grid className="noResultListView">
              <InfoOutlinedIcon /> {ConstantVariables.No_Records_Text}
            </Grid>
          </Grid>
        )}
        {appointments && appointments.length > 7 && (
          <Pagination
            itemCount={appointments.length}
            itemsPerPage={itemsPerPageCount}
            paginationCallback={(startInd: number, endInd: number) => {
              setStartIndex(startInd);
              setEndIndex(endInd);
            }}
          />
        )}
      </>
    );
  });
}
