/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightMethods } from "../../AppInsightMethods";
import save from "../../assets/save.png";
import cancel from "../../assets/cancel.png";
import { IUpdateLanguage } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { AppInsightsConstants } from "../../Constants";

const useStyle = makeStyles({
  dialog: {
    width: 720,
    height: 547,
    maxWidth: 720,
  },
});

interface ILanguageButton {
  callBackGetLanguageList: () => void;
  popup: boolean;
  closeCallback: () => void;
}

const LanguageOption = [
  "Spanish",
  "Chinese",
  "English",
  "Tagaldog",
  "Vietnamese",
  "Arabic",
  "French",
  "German",
  "Dutch",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditLanguage(props: ILanguageButton) {
  const globalStore = useGlobalStore();

  const pageName = "Edit Language";
  const classes = useStyle();
  const helperAdd = helperMethods();
  const [LanguageIn, setLanguage] = useState<string>("");
  const [showLanguageError, setShowLanguageError] = useState("");
  const [showLanguageTypeErrorLanguage, setShowLanguageTypeErrorLanguage] =
    useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (globalStore.actionMenuSelectedLanguage) {
      setLanguage(globalStore.actionMenuSelectedLanguage?.languageName);
    }
  }, [globalStore.actionMenuSelectedLanguage]);

  const onCancelClickEventHandler = () => {
    try {
      globalStore.setOpenedModalLanguage("none");
      setLanguage("");
      setShowLanguageError("");
      setShowLanguageTypeErrorLanguage("");
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Edit Languages close/cancel button click event.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const validate = () => {
    try {
      let validationFailed = false;

      if (LanguageIn === "" || LanguageIn.trim().length === 0) {
        validationFailed = true;
        setShowLanguageError("Please enter Language");
      } else {
        setShowLanguageError("");
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at Edit Language API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  const updateField = () => {
    try {
      setLanguage("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at updateField, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const editLanguage = () => {
    try {
      let materialData = false;
      materialData = validate();
      if (materialData) {
        return;
      }
      const LanguageObj: IUpdateLanguage = {
        id: globalStore.actionMenuSelectedLanguage?.id,
        languageName: LanguageIn,
        uploadedBy: "",
      };
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Edit Languages Ok button click event. Remainder : ${LanguageIn}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setShowSpinner(true);
      apiService
        .updateLanguage(LanguageObj)
        .then((res) => {
          if (res === "success") {
            helperAdd.setSuccessMessageEvent("Language updated successfully!");
          }
          updateField();
          props.closeCallback();
          globalStore.setOpenedModalLanguage("none");
          helperAdd.setSuccessMessageEvent("Language updated successfully!");
          props.callBackGetLanguageList();
          setShowSpinner(false);
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at update Language API call, Component: ${pageName} Error : ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at update Language API, Component: ${pageName} Error : ${error}`
      );
    }
  };

  /* onBlur handler for Language field */
  const onBlurLanguage = () => {
    try {
      if (LanguageIn === "" && LanguageIn.trim().length === 0) {
        setShowLanguageError("Please enter Language");
      } else {
        setShowLanguageError("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurLanguage, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
  };

  return useObserver(() => {
    return (
      <div>
        <Dialog
          onClose={() => props.closeCallback()}
          open={props.popup}
          classes={{ paper: classes.dialog }}
          className="languageContainer"
        >
          <DialogTitle className="languageTitle">
            <b style={{ marginRight: 480 }}>Edit Language</b>
            <IconButton
              onClick={() => {
                onCancelClickEventHandler();
                props.closeCallback();
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="languageContent">
            <Grid className={"language-text"}>
              Select language
              <span className={"material-title"}>*</span>
            </Grid>
            <FormControl className={"languageText-box"} sx={{ width: 300 }}>
              <Select
                value={LanguageIn}
                onChange={handleChange}
                displayEmpty
                placeholder="Select language"
                inputProps={{ "aria-label": "Without label" }}
                error={!!showLanguageTypeErrorLanguage}
                onBlur={() => onBlurLanguage()}
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span className={"selectFirstChild"}>
                        Select language
                      </span>
                    );
                  }

                  return selected;
                }}
              >
                {LanguageOption.map((rem) => (
                  <MenuItem key={rem} value={rem}>
                    {rem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={"material-error"}>{showLanguageError || ""}</div>
          </DialogContent>
          <Grid>
            <DialogActions className="btnClick">
              <Button
                onClick={() => {
                  onCancelClickEventHandler();
                  props.closeCallback();
                }}
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button onClick={editLanguage}>
                <img
                  src={save}
                  alt="save"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
            {showSpinner && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 999,
                }}
                open={showSpinner}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Grid>
        </Dialog>
      </div>
    );
  });
}
